import React, { FC } from 'react';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import {
  ExternalProcessor,
  IPaymentDetails,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ContentCopy } from '@mui/icons-material';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

type SuperAdminLinksProps = {
  externalTransactions: { [key: string]: IPaymentDetails } | undefined;
};

export const SuperAdminLinks: FC<SuperAdminLinksProps> = ({ externalTransactions }) => {
  const dispatch = useDispatch();

  const handleCopyUrl = (url: string) => {
    navigator.clipboard.writeText(url);
    dispatch(showToastMessageAction({ message: 'URL copied to clipboard', severity: 'success' }));
  };

  const authorizeNetUrl =
    process.env.REACT_APP_API_URL.includes('localhost') || process.env.REACT_APP_API_URL.includes('stage')
      ? 'https://sandbox.authorize.net/ui/themes/sandbox/Transaction/TransactionReceipt.aspx?transid='
      : 'https://account.authorize.net/ui/themes/anet/transaction/transactiondetail.aspx?transID=';

  return (
    <List>
      {Object.entries(externalTransactions!).map(([id, details]) => (
        <ListItem key={id}>
          <Stack
            sx={{
              '&:hover svg': {
                display: 'inline-block',
              },
            }}
            direction="row"
            spacing={2}
          >
            <Link
              target="_blank"
              href={
                details.processor === ExternalProcessor.ModernTreasury
                  ? `https://app.moderntreasury.com/payment_orders/${id}`
                  : `${authorizeNetUrl}${details.authorizeNetTransactionId}`
              }
            >
              {details.paymentStage}
            </Link>
            <ContentCopy
              fontSize={'small'}
              sx={(theme) => ({
                color: theme.palette.grey[500],
                display: 'none',
                '&:hover': {
                  cursor: 'pointer',
                  color: theme.palette.primary.main,
                },
              })}
              onClick={() =>
                handleCopyUrl(
                  details.processor === ExternalProcessor.ModernTreasury
                    ? `https://app.moderntreasury.com/payment_orders/${id}`
                    : `https://dashboard.stripe.com/payments/${id}`,
                )
              }
            />
            {details.dateCompleted && (
              <Typography>
                <>Completed @ {details?.dateCompleted}</>
              </Typography>
            )}
          </Stack>
        </ListItem>
      ))}
    </List>
  );
};
