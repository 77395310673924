import React, { FC, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getPropertyAdvertisingDetailsAction, useListings } from '../../../listings/redux/listingSlice';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { useTheme } from '@mui/material';
import { getContrastColor } from '../../../_shared/utils/colorUtils';

type ListingsHeaderProps = {
  isPropertyPreview?: boolean;
};

export const ListingsHeader: FC<ListingsHeaderProps> = ({ isPropertyPreview }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { propertyId } = useParams();
  const { propertyAdvertisingDetails } = useListings();
  const { selectedProperty } = useProperty();
  const property = {
    name: isPropertyPreview ? selectedProperty.value?.name : propertyAdvertisingDetails.value?.name,
    logo: isPropertyPreview ? selectedProperty.value?.details?.logo : propertyAdvertisingDetails.value?.logo,
    advertisingDescription: isPropertyPreview
      ? selectedProperty.value?.details?.advertisingDescription
      : propertyAdvertisingDetails.value?.advertisingDescription,
    themeColor: isPropertyPreview
      ? selectedProperty.value?.details?.themeColor
      : propertyAdvertisingDetails.value?.themeColor,
  };

  useEffect(() => {
    propertyId && dispatch(getPropertyAdvertisingDetailsAction(propertyId));
  }, [propertyId]);

  const getTextColor = (bgColor?: string): string => {
    if (!bgColor) return '#000';
    return getContrastColor(bgColor);
  };

  return (
    <AppBar
      position="static"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 1,
        pb: 2,
        mt: isPropertyPreview ? 2 : 0,
        backgroundColor: property.themeColor ?? theme.palette.primary.main,
        color: getTextColor(property.themeColor),
      }}
    >
      <Toolbar>
        <Stack sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
          {property.logo ? (
            <Box sx={{ pt: 2 }}>
              <img src={property.logo.uri} alt={property.logo.name} style={{ maxHeight: '5rem' }} />
            </Box>
          ) : (
            <MapsHomeWorkIcon sx={{ fontSize: 30 }} />
          )}
          <Typography variant={'h1'}>{property.name}</Typography>
          <Typography variant={'h6'}>{property.advertisingDescription}</Typography>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
