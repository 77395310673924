import React, { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ListingCard } from '../shared/ListingCard';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { listListingsAction, useListings } from '../../redux/listingSlice';
import { ListingsSearch } from '../shared/search/ListingsSearch';
import { ListingParameter, useListingFilters } from '../../redux/useListingFilters';
import LinearProgress from '@mui/material/LinearProgress';
import { ListingsHeader } from '../../../global/components/header/ListingsHeader';
import { AddApplicantDialog } from '../../../property/components/applications/applicationDialogs/AddApplicantDialog';

type ListingsPageProps = {
  isPropertyPreview?: boolean;
};

export const ListingsPage: FC<ListingsPageProps> = ({ isPropertyPreview }) => {
  const dispatch = useDispatch();
  const { propertyId, id } = useParams();
  const { listings } = useListings();
  const { handleUpdateFilters } = useListingFilters();
  const [filters, setFilters] = useState<ListingParameter[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    propertyId && dispatch(listListingsAction({ propertyId, parameters: filters, isWebView: true }));
    if (!isPropertyPreview) {
      id && dispatch(listListingsAction({ propertyId: id, parameters: filters }));
    }
  }, [propertyId, filters, id]);

  const handleSetFilter = (newFilters: ListingParameter[]) => {
    setFilters(handleUpdateFilters(newFilters, filters));
  };

  return (
    <>
      <ListingsHeader isPropertyPreview={isPropertyPreview} />
      <ListingsSearch
        isPropertyPreview={isPropertyPreview}
        onClearFilters={() => setFilters([])}
        onSetFilter={handleSetFilter}
      />
      <Grid container spacing={2} p={2}>
        {listings.loading ? (
          <LinearProgress />
        ) : (
          listings.value?.map((listing) => (
            <Grid item key={listing.id} xs={4}>
              <ListingCard
                listing={listing}
                key={listing.id}
                isPropertyPreview={isPropertyPreview}
                onOpenApply={() => setOpen(true)}
              />
            </Grid>
          ))
        )}
      </Grid>
      <AddApplicantDialog propertyId={propertyId} open={open} onClose={() => setOpen(false)} isWebView={true} />
    </>
  );
};
