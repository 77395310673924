import React, { FC, useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Add from '@mui/icons-material/Add';
import { ExpenseDialog } from './dialogs/ExpenseDialog';
import { AddExpenseDialog } from './dialogs/AddExpenseDialog';
import { toggleIsGroupedAction } from '../redux/expenseSlice';
import { ExpenseTable } from './ExpenseTable';
import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ShowChildAssetsToggle } from '../../tasks/components/ShowChildAssetsToggle';
import { IncludeClosedToggle } from '../../tasks/components/IncludeClosedToggle';
import { useDispatch } from 'react-redux';
import { GroupedExpensesTable } from './GroupedExpensesTable';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetTab, ExpenseDialogTab, OperationsTab } from '../../tabs/TabTypeEnums';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import { AssetParams } from '../../../../AppRouter';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';

export const Expenses: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<AssetParams>();
  const [openAddJob, setOpenAddJob] = useState(false);
  const [showChildAssets, setShowChildAssets] = useState(false);
  const [includeClosed, setIncludeClosed] = useState(false);
  const asset = getAssetTypeFromPathname();
  const assetType =
    asset === 'unit' ? AssetType.BuildingUnit : asset === 'building' ? AssetType.Building : AssetType.RentalProperty;
  const handleCloseAddJob = () => setOpenAddJob(false);

  const handleSetSelectedExpense = (expenseId: string) => {
    navigate(
      `/assets/${asset}/${id}/${AssetTab.Operations}/${OperationsTab.Expenses}/expense/${expenseId}/${ExpenseDialogTab.Info}`,
    );
  };

  const handleSwitchChildAssetToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowChildAssets(event.target.checked);
    dispatch(toggleIsGroupedAction(event.target.checked));
  };

  const handleToggleIncludeClosed = () => {
    setIncludeClosed(!includeClosed);
  };
  if (!id) return <></>;
  return (
    <StyledInfoBox label={'Expenses'}>
      <Stack spacing={2}>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
          <Box>
            <Button variant={'outlined'} startIcon={<Add />} onClick={() => setOpenAddJob(true)}>
              Add Expense
            </Button>
          </Box>
          <Box>
            {assetType !== AssetType.BuildingUnit && (
              <ShowChildAssetsToggle value={showChildAssets} onChange={handleSwitchChildAssetToggle} />
            )}
            <IncludeClosedToggle
              label={'Include Closed Expenses'}
              includeClosed={includeClosed}
              onToggleIncludeClosed={handleToggleIncludeClosed}
            />
          </Box>
        </Box>
        {showChildAssets ? (
          <GroupedExpensesTable
            assetType={assetType}
            assetId={id}
            includeClosed={includeClosed}
            onSetSelectedJob={handleSetSelectedExpense}
          />
        ) : (
          <ExpenseTable
            onSetSelectedJob={handleSetSelectedExpense}
            assetType={assetType}
            assetId={id}
            includeClosed={includeClosed}
          />
        )}
        <ExpenseDialog />
        <AddExpenseDialog onClose={handleCloseAddJob} open={openAddJob} assetType={assetType} assetId={id} />
      </Stack>
    </StyledInfoBox>
  );
};
