import { ReduxEntry } from './redux/statementTypes';
import { Period, ReduxDate, toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { tryFormatDate } from '../utils/TryFormatDate';
import {
  IAccountRef,
  ICamAssociation,
  JournalEntryStatus,
  PaymentType,
  StatementPeriodType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Typography from '@mui/material/Typography';
import { PaymentStatusChip } from './components/PaymentStatusChip';
import { NumericFormat } from 'react-number-format';
import { AssociationCell } from '../datagrids/AssociationCell';
import React from 'react';
import { ActionCell } from '../datagrids/ActionCell';
import {
  bulkDepositJournalEntriesAction,
  changeDepositAccountAction,
  getPropertyAccountStatementAction,
  getStatementByLeaseIdAction,
  getStatementPeriodsByLeaseIdAction,
  getStatementPeriodsForPropertyAccountAction,
  getTenantStatementByLeaseIdAction,
  journalEntryArchivingAction,
  markAsPendingAction,
  markAsPostedAction,
} from './redux/statementSlice';
import { useDispatch } from 'react-redux';
import { useIcons } from '../icons/useIcons';
import Chip from '@mui/material/Chip';
import { StatementType } from './components/LeaseStatement';
import { DateRange } from '@mui/x-date-pickers-pro';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import Checkbox from '@mui/material/Checkbox';
import { getAssetTypeFromPathname } from '../utils/getAssetTypeFromPathname';
import { LeaseDialogTab } from '../tabs/TabTypeEnums';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetParams } from '../../../AppRouter';
import { randomId } from '@mui/x-data-grid-generator';

export type TableRow = Omit<ReduxEntry, 'journalEntryId'> & { id?: string; path?: string[] };

export const useStatementConfig = (
  fullView?: boolean,
  isTenantPortalView?: boolean,
  showActions?: boolean,
  isPlatformStatement?: boolean,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, outerTab, innerTab } = useParams<AssetParams>();
  const { getActionIcon, ActionType } = useIcons();

  const handleFetchLeaseStatementPeriods = (leaseId?: string, selectedPeriodType?: StatementPeriodType) => {
    if (!leaseId || selectedPeriodType === StatementPeriodType.Custom || !selectedPeriodType) return;
    dispatch(getStatementPeriodsByLeaseIdAction({ leaseId: leaseId, statementPeriodType: selectedPeriodType }));
  };

  const handleFetchAccountStatementPeriods = (
    accountId?: string,
    selectedPeriodType?: StatementPeriodType,
    propertyId?: string,
    isPopup?: boolean,
  ) => {
    if (!accountId || !propertyId || selectedPeriodType === StatementPeriodType.Custom) return;
    dispatch(
      getStatementPeriodsForPropertyAccountAction({
        propertyId: propertyId,
        accountId: accountId,
        periodType: selectedPeriodType,
        isPopup: isPopup,
      }),
    );
  };

  const handleFetchLeaseStatement = (
    customDateRange: DateRange<Date> | undefined,
    periodType: StatementPeriodType,
    statementType: StatementType,
    periodValue?: Period,
    leaseId?: string,
    isTenantPortalView?: boolean,
  ) => {
    const startDate = handleFindStartDate(periodValue, customDateRange, statementType);
    const endDate = handleFindEndDate(customDateRange, statementType);
    const selectedPeriodType = handleFindPeriodType(statementType, periodType);
    const fetchStatement = shouldFetchStatement(selectedPeriodType, startDate ?? undefined, endDate ?? undefined);
    leaseId &&
      !isTenantPortalView &&
      fetchStatement &&
      dispatch(
        getStatementByLeaseIdAction({
          leaseId: leaseId,
          startDate: startDate!,
          statementPeriodType: selectedPeriodType,
          endDate: endDate ?? undefined,
        }),
      );
    leaseId && isTenantPortalView && dispatch(getTenantStatementByLeaseIdAction({ leaseId, periodStart: startDate! }));
  };

  const shouldFetchStatement = (periodType?: StatementPeriodType, startDate?: ReduxDate, endDate?: ReduxDate) => {
    if (!startDate) return false;
    if (endDate && endDate < startDate) return false;
    if (periodType === StatementPeriodType.Custom && !endDate) return false;
    return true;
  };

  const handleFetchAccountStatement = (
    customDateRange: DateRange<Date> | undefined,
    periodType: StatementPeriodType,
    statementType: StatementType,
    periodValue?: Period,
    accountNo?: string,
    propertyId?: string,
    includeArchived?: boolean,
    isPopupStatement?: boolean,
  ) => {
    const startDate = handleFindStartDate(periodValue, customDateRange, statementType);
    const endDate = handleFindEndDate(customDateRange, statementType);
    const selectedPeriodType = handleFindPeriodType(statementType, periodType);
    const fetchStatement = shouldFetchStatement(selectedPeriodType, startDate ?? undefined, endDate ?? undefined);
    propertyId &&
      accountNo &&
      fetchStatement &&
      dispatch(
        getPropertyAccountStatementAction({
          propertyId: propertyId,
          accountId: accountNo,
          periodType: selectedPeriodType,
          startDate: startDate!,
          endDate: endDate ?? undefined,
          includeArchived: includeArchived,
          isPopup: isPopupStatement,
        }),
      );
  };

  const handleFindStartDate = (
    periodValue: Period | undefined,
    customDateRange: DateRange<Date> | undefined,
    statementType: StatementType,
  ): ReduxDate | Extract<undefined, undefined | null> | null => {
    if (statementType === 'custom' && customDateRange) return toReduxDate(customDateRange[0]);
    if (statementType !== 'custom') return toReduxDate(periodValue);
    return undefined;
  };

  const handleFindEndDate = (
    customDateRange: DateRange<Date> | undefined,
    statementType: StatementType,
  ): ReduxDate | null | undefined => {
    if (statementType === 'custom' && customDateRange) return toReduxDate(customDateRange[1]);
    return undefined;
  };

  const handleFindPeriodType = (statementType: StatementType, selectedPeriodType: StatementPeriodType) => {
    return statementType === 'custom' ? StatementPeriodType.Custom : selectedPeriodType;
  };

  function handleRollbackDeposit(
    rowId: string,
    propertyId?: string,
    isPopupStatement?: boolean,
    isIncomeStatement?: boolean,
  ) {
    if (propertyId === undefined) return;
    dispatch(
      markAsPendingAction({
        propertyId: propertyId,
        journalEntryId: rowId,
        isPopup: isPopupStatement,
        isIncomeStatement: isIncomeStatement,
      }),
    );
  }

  function handleMarkAsPosted(
    dateDeposited: Date | null,
    selectedJeId: string | undefined,
    propertyId?: string,
    isPopupStatement?: boolean,
    isIncomeStatement?: boolean,
  ) {
    if (propertyId === undefined || selectedJeId === undefined) return;
    if (dateDeposited === null) {
      dispatch(showToastMessageAction({ message: 'Please select a date to mark as deposited', severity: 'error' }));
      return;
    }
    dispatch(
      markAsPostedAction({
        propertyId: propertyId,
        journalEntryId: selectedJeId,
        datePosted: toReduxDate(dateDeposited),
        isPopup: isPopupStatement,
        isIncomeStatement: isIncomeStatement,
      }),
    );
  }

  function handleBulkDeposit(
    selectedJEIds: string[],
    dateDeposited: ReduxDate | null,
    isPopup?: boolean,
    propertyId?: string,
  ) {
    if (propertyId === undefined || selectedJEIds.length === 0) return;
    if (dateDeposited === null) {
      dispatch(showToastMessageAction({ message: 'Please select a date to mark as deposited', severity: 'error' }));
      return;
    }
    dispatch(
      bulkDepositJournalEntriesAction({
        propertyId,
        jeIds: selectedJEIds,
        dateDeposited,
        isPopup,
      }),
    );
  }

  function handleChangeDeposit(
    jeId?: string,
    newDepositAccountId?: string,
    isPopupStatement?: boolean,
    isBankAccountStatement?: boolean,
    isIncomeStatement?: boolean,
  ) {
    if (jeId === undefined || newDepositAccountId === undefined) return;
    dispatch(
      changeDepositAccountAction({
        jeId,
        newDepositAccountId: newDepositAccountId,
        isPopup: isPopupStatement,
        isBankAccountStatement: isBankAccountStatement,
        isIncomeStatement: isIncomeStatement,
      }),
    );
  }

  function* createActions(
    params: GridRowParams<ReduxEntry>,
    submitting: boolean,
    handleDepositClick: (jeId: string) => void,
    handleChangeDepositClick: (jeId: string) => void,
    onRollbackDeposit?: (jeId: string) => void,
    isIncomeStatement?: boolean,
  ) {
    if (!params.row.journalEntryId) return;
    if (
      params.row.status !== JournalEntryStatus.Pending &&
      params.row.status !== JournalEntryStatus.Archived &&
      (params.row.paymentType === PaymentType.Check || params.row.paymentType === PaymentType.Cash)
    )
      yield (
        <ActionCell
          icon={getActionIcon(ActionType.Rollback)}
          key={'rollBackDeposit'}
          label={'Roll Back Deposit'}
          onClick={() => onRollbackDeposit && onRollbackDeposit(params.row.journalEntryId!)}
          loading={submitting}
          showInMenu
        />
      );
    if (params.row.status === JournalEntryStatus.Pending) {
      yield (
        <ActionCell
          key={'markAsPosted'}
          loading={submitting}
          onClick={() => handleDepositClick(params.row.journalEntryId!)}
          icon={getActionIcon(ActionType.PostEntry)}
          label={'Mark as Deposited'}
          showInMenu
        />
      );
      yield (
        <ActionCell
          key={'changeDepositAccount'}
          loading={submitting}
          onClick={() => handleChangeDepositClick(params.row.journalEntryId!)}
          icon={getActionIcon(ActionType.Deposit)}
          label={'Change Deposit Account'}
          showInMenu
        />
      );
    }
    if (
      (params.row.description !== 'Rent' && !params.row.description?.includes('Lease Deposit')) ||
      (!params.row.leaseId && isIncomeStatement)
    ) {
      yield params.row.status !== JournalEntryStatus.Archived ? (
        <ActionCell
          key={'markAsVoided'}
          loading={submitting}
          onClick={() => {
            if (params.row.journalEntryId)
              dispatch(
                journalEntryArchivingAction({
                  journalEntryId: params.row.journalEntryId,
                  isArchiving: true,
                  isIncome: isIncomeStatement,
                }),
              );
          }}
          icon={getActionIcon(ActionType.Block)}
          label={'Mark as Voided'}
          showInMenu
        />
      ) : (
        <ActionCell
          key={'rollBackVoid'}
          loading={submitting}
          onClick={() => {
            if (params.row.journalEntryId)
              dispatch(
                journalEntryArchivingAction({
                  journalEntryId: params.row.journalEntryId,
                  isArchiving: false,
                  isIncome: isIncomeStatement,
                }),
              );
          }}
          icon={getActionIcon(ActionType.Rollback)}
          label={'Rollback Void'}
          showInMenu
        />
      );
    }
    if (isIncomeStatement && params.row.leaseId) {
      yield (
        <ActionCell
          icon={getActionIcon(ActionType.GoTo)}
          key={'goToLease'}
          label={'Go To Lease'}
          showInMenu
          onClick={() =>
            navigate(
              `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/lease/${params.row.leaseId}/${
                LeaseDialogTab.PaymentHistory
              }`,
            )
          }
        />
      );
    }
  }

  const formatTableColumns = (
    submitting: boolean,
    handleDepositClick: (jeId: string) => void,
    handleChangeDepositClick: (jeId: string) => void,
    onRollbackDeposit?: (jeId: string) => void,
    showDepositChip?: boolean,
    showAssociationChip?: boolean,
    onOpenPopupStatement?: (statementLine?: ReduxEntry) => void,
    onSelectStatementLine?: (jeId?: string, checked?: boolean) => void,
    isPopupStatement?: boolean,
  ): GridColDef<ReduxEntry>[] => {
    const fullViewColumns: GridColDef<ReduxEntry>[] = [
      {
        field: 'paymentType',
        headerName: 'Type',
        type: 'string',
        flex: 0.5,
        editable: false,
        groupable: false,
        valueFormatter: (value?: PaymentType) => {
          switch (value) {
            case PaymentType.Check:
              return 'Check';
            case PaymentType.Cash:
              return 'Cash';
            case PaymentType.CreditCard:
              return 'Credit Card';
            case PaymentType.BankTransfer:
              return 'Bank Transfer';
            case PaymentType.InternalTransfer:
              return 'Internal Transfer';
            default:
              return '';
          }
        },
      },
      {
        field: 'paymentNo',
        headerName: 'Payment #',
        type: 'string',
        flex: 0.5,
        display: 'flex',
        editable: false,
        groupable: false,
      },
    ];

    const associationColumn: GridColDef<ReduxEntry> = {
      field: 'associations',
      headerName: 'Associations',
      type: 'singleSelect',
      flex: 0.5,
      editable: false,
      renderCell: (params: GridRenderCellParams<ReduxEntry, ICamAssociation[] | string>) => {
        if (params.rowNode.type === 'group' && typeof params.value === 'string') {
          if (params.value === '') return 'None';
          //remove the GUID at the beginning of the string, everything before the first empty space
          return params.value.replace(/[^ ]* /, '').trim();
        }
        return <AssociationCell associations={params.row.associations ?? []} />;
      },
      groupingValueGetter: (value, row) => {
        if (!row.associations || !row.associations[0] || !row.associations[0].associatedId) return '';
        //TODO: not sure how well this will work with multiple associations, if they are in diff orders for example
        //however, currently I believe statement lines only ever have one association
        //and this has to return a string/number/bool
        return `${row.associations[0].associatedId} ${row.associations[0].label}`;
      },
    };

    const depositAccountColumn: GridColDef<ReduxEntry> = {
      field: 'depositAccount',
      headerName: 'Bank Account',
      type: 'string',
      flex: 2,
      display: 'flex',
      editable: false,
      groupable: false,
      valueGetter: (value: IAccountRef | undefined) => value?.name,
      renderCell: (params: GridRenderCellParams<ReduxEntry, IAccountRef>) => {
        if (!params.row.depositAccount) return null;
        return (
          <Chip
            onClick={() => onOpenPopupStatement && onOpenPopupStatement(params.row)}
            label={params.row?.depositAccount?.name ?? ''}
          />
        );
      },
    };

    const isPropertyViewColumns: GridColDef<ReduxEntry>[] = [
      {
        field: 'pending',
        headerName: 'Pending',
        type: 'number',
        flex: 0.5,
        display: 'flex',
        editable: false,
        groupable: false,
        renderCell: (params: GridRenderCellParams<ReduxEntry, string>) => {
          if (!params.value) return params.aggregation ? <Typography variant={'h6'}>$0.00</Typography> : <></>;
          return (
            <Typography variant={params.aggregation ? 'h6' : 'body2'}>
              <NumericFormat
                value={params.value}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          );
        },
      },
      {
        field: 'posted',
        headerName: 'Posted',
        type: 'number',
        flex: 0.5,
        display: 'flex',
        editable: false,
        groupable: false,
        renderCell: (params: GridRenderCellParams<ReduxEntry, string>) => {
          if (!params.value) return params.aggregation ? <Typography variant={'h6'}>$0.00</Typography> : <></>;
          return (
            <Typography variant={params.aggregation ? 'h6' : 'body2'}>
              <NumericFormat
                value={params.value}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          );
        },
      },
    ];

    if (showAssociationChip) {
      //add association column to the beginning of the columns
      isPropertyViewColumns.unshift(associationColumn);
    }

    if (showDepositChip) {
      //add deposit account column to the end of the columns
      isPropertyViewColumns.unshift(depositAccountColumn);
    }

    const columns: GridColDef<ReduxEntry>[] = [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 100,
        renderHeader: () => getActionIcon(ActionType.PostEntry),
        renderCell: (params: GridRenderCellParams<ReduxEntry, string>) => {
          return params.row.status === JournalEntryStatus.Pending ? (
            <Checkbox
              onChange={(event, checked) =>
                onSelectStatementLine && onSelectStatementLine(params.row.journalEntryId, checked)
              }
            />
          ) : null;
        },
      },
      {
        field: 'date',
        headerName: 'Date',
        type: 'date',
        flex: 0.5,
        editable: false,
        valueGetter: (value?: string) => toStandardDate(value),
        renderCell: (params: GridRenderCellParams<ReduxEntry, string>) =>
          params.value ? tryFormatDate(params.value) : '',
        groupingValueGetter: (value) => tryFormatDate(value),
      },
    ];

    if (fullView) {
      columns.push(...fullViewColumns);
    }

    columns.push({
      field: 'description',
      headerName: 'Description',
      type: 'string',
      flex: fullView ? 1.5 : 1,
      display: 'flex',
      editable: false,
      groupable: false,
      renderCell: (params: GridRenderCellParams<ReduxEntry, string>) => {
        return params.aggregation ? (
          <Typography variant="h5">Total</Typography>
        ) : (
          <PaymentStatusChip
            row={params.row}
            value={params.value}
            isTenantPortalView={isTenantPortalView}
            disableJournalEntryView={isPlatformStatement || isTenantPortalView || isPopupStatement}
          />
        );
      },
    });

    if (!isTenantPortalView) {
      columns.push(...isPropertyViewColumns);
    } else
      columns.push({
        field: 'amount',
        headerName: 'Amount',
        type: 'number',
        flex: 0.5,
        display: 'flex',
        editable: false,
        groupable: false,
        renderCell: (params: GridRenderCellParams<ReduxEntry, string>) => {
          return (
            <Typography variant={params.aggregation ? 'h6' : 'body2'}>
              <NumericFormat
                value={params.value}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          );
        },
      });

    columns.push({
      field: 'total',
      headerName: 'Balance',
      type: 'number',
      flex: 0.5,
      display: 'flex',
      editable: false,
      groupable: false,
      renderCell: (params: GridRenderCellParams<ReduxEntry, string>) => {
        return (
          <Typography
            variant={params.aggregation ? 'h6' : 'body2'}
            fontStyle={!isTenantPortalView && params.row.status === JournalEntryStatus.Pending ? 'italic' : 'normal'}
          >
            <NumericFormat
              value={params.value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale
            />
          </Typography>
        );
      },
    });

    if (showActions) {
      columns.push({
        field: 'actions',
        type: 'actions',
        headerName: '',
        align: 'center',
        headerAlign: 'center',
        flex: 0.25,
        getActions: (params: GridRowParams) =>
          Array.from(
            createActions(params, submitting, handleDepositClick, handleChangeDepositClick, onRollbackDeposit),
          ),
      });
    }
    return columns;
  };

  const formatTableRows = (rows: ReduxEntry[], isTenantPortalView?: boolean): TableRow[] => {
    if (isTenantPortalView) {
      return rows.map((entry) => ({ ...entry, id: entry.journalEntryId }));
    }
    //property view adds a row for each entry.line if there are multiple lines present
    const formattedRows: TableRow[] = [];
    rows.forEach((entry) => {
      if (!entry.journalEntryId) return;
      formattedRows.push({ ...entry, id: entry.journalEntryId, path: [entry.journalEntryId] });
      if (entry.lines && entry.lines.length > 1) {
        entry.lines.forEach((line) => {
          const pending =
            entry.status === JournalEntryStatus.Pending ||
            (entry.status === JournalEntryStatus.Archived && entry.previousStatus === JournalEntryStatus.Pending)
              ? line.amount
              : undefined;
          const posted =
            entry.status === JournalEntryStatus.Posted ||
            (entry.status === JournalEntryStatus.Archived && entry.previousStatus === JournalEntryStatus.Posted)
              ? line.amount
              : undefined;
          const id = randomId();
          formattedRows.push({
            id,
            description: line.description,
            pending,
            posted,
            status: entry.status,
            path: [entry.journalEntryId!, id],
          });
        });
      }
    });
    return formattedRows;
  };

  return {
    formatTableColumns,
    formatTableRows,
    handleFetchLeaseStatementPeriods,
    handleFetchAccountStatementPeriods,
    handleFetchAccountStatement,
    handleFetchLeaseStatement,
    handleRollbackDeposit,
    handleMarkAsPosted,
    handleBulkDeposit,
    handleChangeDeposit,
    createActions,
  };
};
