import React, { FC, useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  deleteAssociationFromDetailAction,
  deleteDetailAction,
  getDetailGroupByAssetIdAction,
  getDetailGroupByParentIdAction,
  getDetailsAction,
  updateDetailStatusesFromTaskAction,
  useDetails,
} from '../../redux/detailsSlice';
import { useDispatch } from 'react-redux';
import {
  mapAssetTypeFromDetailAssociationType,
  mapDetailStatusToDisplayName,
  ReduxDetail,
} from '../../redux/detailTypes';
import { StripedDataGrid, styledFilterPanelProps } from '../../../datagrids/StripedDataGrid';
import {
  DataGridPremiumProps,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import Link from '@mui/material/Link';
import {
  AssociationType,
  DetailAssociationType,
  ICamAssociation,
  IDetailSpecification,
  ITaskDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Paper from '@mui/material/Paper';
import { ActionCell } from '../../../datagrids/ActionCell';
import { SpecChip } from './SpecChip';
import Stack from '@mui/material/Stack';
import { AssociationCell } from '../../../datagrids/AssociationCell';
import { Filter, SortDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { FilterOperator, stringFilters } from '../../../utils/filteringUtils';
import { StatusEditCell } from './StatusEditCell';
import EditIcon from '@mui/icons-material/Edit';
import Connector from '@monkeyjump-labs/cam-fe-shared/dist/services/signalrConnection';
import {
  setNewTaskFromAssociationValuesAction,
  useTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { setAssociationSearchParentAction } from '../../../search/redux/searchSlice';
import { useIcons } from '../../../icons/useIcons';

export type DetailsTableProps = {
  associationType: DetailAssociationType;
  associatedId: string;
  onViewDetails: (detail: ReduxDetail) => void;
  isSubDetails?: boolean;
  includeClosed: boolean;
  parentDetailId?: string;
  isAssetGroupsDetailPanel?: boolean;
  isParentGroupsDetailPanel?: boolean;
  onOpenAddTask?: (detail: ReduxDetail) => void;
};

export const DetailsTable: FC<DetailsTableProps> = ({
  associationType,
  associatedId,
  onViewDetails,
  isSubDetails,
  includeClosed,
  parentDetailId,
  isAssetGroupsDetailPanel,
  isParentGroupsDetailPanel,
  onOpenAddTask,
}) => {
  const dispatch = useDispatch();
  const { getActionIcon, ActionType } = useIcons();
  const { newTaskHasBeenCreated } = useTasks();
  const { details, groupedByAssetLists, selectedSubDetails, groupedByParentDetailLists } = useDetails();
  const [rows, setRows] = useState<ReduxDetail[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState<keyof ReduxDetail | undefined>();
  const [orderDirection, setOrderDirection] = useState<SortDirection | undefined>();
  const [filters, setFilters] = useState<Filter<ReduxDetail>[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const getDetailPanelContent: DataGridPremiumProps['getDetailPanelContent'] = useCallback(
    ({ row }: GridRowParams<ReduxDetail>) =>
      row.numberOfChildren && row.numberOfChildren > 0 ? (
        <Stack sx={{ py: 2, height: 1, boxSizing: 'border-box', backgroundColor: 'lightgray' }} direction="column">
          <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
            <Stack direction="column" spacing={1}>
              <DetailsTable
                associationType={associationType!}
                associatedId={associatedId!}
                onViewDetails={onViewDetails}
                includeClosed={includeClosed}
                isParentGroupsDetailPanel={true}
                parentDetailId={row.id}
                onOpenAddTask={onOpenAddTask}
              />
            </Stack>
          </Paper>
        </Stack>
      ) : undefined,
    [associationType, associatedId, includeClosed],
  );
  const getDetailPanelHeight = useCallback<NonNullable<DataGridPremiumProps['getDetailPanelHeight']>>(
    () => 'auto' as const,
    [],
  );

  useEffect(() => {
    if (isAssetGroupsDetailPanel) {
      dispatch(
        getDetailGroupByAssetIdAction({
          associationType: associationType,
          associatedId: associatedId,
          page: page,
          pageSize: pageSize,
          sortBy: orderBy,
          sortDirection: orderDirection,
          filters: filters,
          includeClosed: includeClosed,
        }),
      );
    } else if (isParentGroupsDetailPanel && parentDetailId) {
      dispatch(
        getDetailGroupByParentIdAction({
          associationType: associationType,
          associatedId: associatedId,
          parentId: parentDetailId,
          page: page,
          pageSize: pageSize,
          sortBy: orderBy,
          sortDirection: orderDirection,
          filters: filters,
          includeClosed: includeClosed,
        }),
      );
    } else {
      dispatch(
        getDetailsAction({
          associationType: associationType,
          associatedId: associatedId ?? '',
          page: page,
          pageSize: pageSize,
          sortBy: orderBy,
          sortDirection: orderDirection,
          filters: filters,
          includeClosed: includeClosed,
          parentId: parentDetailId,
        }),
      );
    }
  }, [page, pageSize, includeClosed, filters, orderBy, orderDirection, parentDetailId, newTaskHasBeenCreated]);

  useEffect(() => {
    if (isAssetGroupsDetailPanel) {
      typeof groupedByAssetLists[associatedId] !== 'undefined' && setLoading(groupedByAssetLists[associatedId].loading);
      typeof groupedByAssetLists[associatedId] !== 'undefined' &&
        groupedByAssetLists[associatedId].value &&
        setRows(groupedByAssetLists[associatedId].value ?? []);
      typeof groupedByAssetLists[associatedId] !== 'undefined' &&
        setTotalCount(groupedByAssetLists[associatedId].totalCount ?? 0);
    } else if (isParentGroupsDetailPanel && parentDetailId) {
      typeof groupedByParentDetailLists[parentDetailId] !== 'undefined' &&
        setLoading(groupedByParentDetailLists[parentDetailId].loading);
      typeof groupedByParentDetailLists[parentDetailId] !== 'undefined' &&
        groupedByParentDetailLists[parentDetailId].value &&
        setRows(groupedByParentDetailLists[parentDetailId].value ?? []);
      typeof groupedByParentDetailLists[parentDetailId] !== 'undefined' &&
        setTotalCount(groupedByParentDetailLists[parentDetailId].totalCount ?? 0);
    } else if (!isSubDetails) {
      setLoading(details.loading);
      setTotalCount(details.totalCount ?? 0);
      setRows(details.value ?? []);
    } else if (isSubDetails) {
      setLoading(selectedSubDetails.loading);
      setTotalCount(selectedSubDetails.totalCount ?? 0);
      setRows(selectedSubDetails.value ?? []);
    }

    const connector = Connector.getExistingInstance();
    const updateDetailChips = (task: ITaskDto) => {
      dispatch(updateDetailStatusesFromTaskAction({ task: task }));
    };

    connector.onReceiveUpdatedTask(updateDetailChips);

    return () => {
      connector.offReceiveUpdatedTask(updateDetailChips);
    };
  }, [
    details.value,
    groupedByAssetLists,
    selectedSubDetails.value,
    details,
    selectedSubDetails,
    groupedByParentDetailLists,
  ]);

  const handleDeleteDetail = (detailId: string) => {
    dispatch(deleteDetailAction({ detailId: detailId }));
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setOrderBy(undefined);
      setOrderDirection(undefined);
    } else {
      setOrderBy(model[0].field as keyof ReduxDetail);
      setOrderDirection(model[0].sort === 'asc' ? 'Ascending' : 'Descending');
    }
  };
  const handleFilterModelChange = (model: GridFilterModel) => {
    if (model.items.length === 0) {
      setFilters(undefined);
    } else {
      const parameterHasValues = model.items.length > 0 && model.items.every((parameter) => parameter.value);
      if (parameterHasValues) {
        const filters = model.items.map((items) => {
          return {
            name: items.field as keyof ReduxDetail,
            operator: items.operator as FilterOperator,
            value: items.value,
          };
        });
        setFilters(filters);
      }
    }
  };

  const handleRemoveAssociation = (id: string, association: ICamAssociation) => {
    if (!associationType) return;
    dispatch(
      deleteAssociationFromDetailAction({
        detailId: id,
        association,
      }),
    );
  };

  const handleOpenAddAssociation = (detail: ReduxDetail) => {
    if (!detail.id) return;
    dispatch(
      setAssociationSearchParentAction({
        id: detail.id,
        type: AssociationType.Detail,
      }),
    );
  };

  function* createActions(params: GridRowParams<ReduxDetail>) {
    if (!params.row.id) return;
    if (!isParentGroupsDetailPanel) {
      const assetType = mapAssetTypeFromDetailAssociationType(associationType);

      yield (
        <ActionCell
          icon={getActionIcon(ActionType.CreateTask)}
          key={'createTask'}
          label={'Create Task From Detail'}
          onClick={() => {
            params.row.id &&
              dispatch(
                setNewTaskFromAssociationValuesAction({
                  id: params.row.id,
                  valueAssociationType: AssociationType.Detail,
                  valueAssociatedId: associatedId,
                  valueAssetType: assetType,
                  name: params.row.name,
                  description: '',
                }),
              );
          }}
        />
      );
    }
    yield (
      <ActionCell
        icon={getActionIcon(ActionType.Delete)}
        key={'deleteDetail'}
        label={'Delete Detail'}
        onClick={() => handleDeleteDetail(params.row.id!)}
      />
    );
  }

  const columns: GridColDef<ReduxDetail>[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      display: 'flex',
    },
    {
      field: 'name',
      type: 'string',
      headerName: 'Name',
      flex: 1,
      display: 'flex',
      sortable: true,
      filterable: true,
      filterOperators: stringFilters,
      renderCell: (params: GridRenderCellParams<ReduxDetail, string>) => {
        return (
          <Link sx={{ cursor: 'pointer' }} color={'inherit'}>
            <Typography onClick={() => onViewDetails(params.row)}>{params.value}</Typography>
          </Link>
        );
      },
    },
    {
      field: 'description',
      type: 'string',
      headerName: 'Description',
      flex: 2,
      display: 'flex',
      sortable: false,
      filterable: true,
      filterOperators: stringFilters,
    },
    {
      field: 'specifications',
      headerName: 'Specifications',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<ReduxDetail, IDetailSpecification[]>) => {
        return (
          <Stack>
            {params.value &&
              params.value.map((specification) => {
                return <SpecChip key={specification.key} specification={specification} deletable={false} />;
              })}
          </Stack>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => <>{mapDetailStatusToDisplayName(params.value)}</>,
      editable: true,
      display: 'flex',
      renderHeader: () => {
        return (
          <>
            {'Status '}
            <EditIcon fontSize="small" color="disabled" />
          </>
        );
      },
      renderEditCell: (params) => <StatusEditCell {...params} />,
    },
    {
      field: 'associations',
      headerName: 'Associations',
      align: 'left',
      flex: 0.75,
      display: 'flex',
      type: 'string',
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<ReduxDetail, string>) => {
        return (
          <AssociationCell
            {...params}
            associations={params.row.associations ?? []}
            onOpenAddAssociation={() =>
              handleOpenAddAssociation({
                ...params.row,
                associatedId: associatedId,
                associationType: associationType,
              })
            }
            onRemoveAssociation={(association) => params.row.id && handleRemoveAssociation(params.row.id, association)}
            tableType={'details'}
          />
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      getActions: (params: GridRowParams) => Array.from(createActions(params)),
    },
  ];

  return (
    <Paper sx={{ flex: 1, mx: 'auto', width: '100%', p: 1 }}>
      <StripedDataGrid
        disableRowGrouping
        autoHeight
        getRowHeight={() => 'auto'}
        rowHeight={75}
        rows={rows}
        columns={columns}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        slots={{ toolbar: GridToolbar }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              specifications: !isSubDetails,
            },
          },
        }}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        loading={loading}
        sortingMode={'server'}
        onFilterModelChange={handleFilterModelChange}
        filterMode={'server'}
        onSortModelChange={handleSortModelChange}
        pagination
        paginationMode={'server'}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={{ page: page, pageSize: pageSize }}
        onPaginationModelChange={(model: GridPaginationModel) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        rowCount={totalCount}
        slotProps={{ filterPanel: styledFilterPanelProps }}
      ></StripedDataGrid>
    </Paper>
  );
};
