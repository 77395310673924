import React, { FC } from 'react';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ReduxEmail } from '../../redux/communicationTypes';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { AttachmentLink } from '../AttachmentLink';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { ICamAssociation } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import List from '@mui/material/List';

type AttachmentsCellProps = {
  associations: ICamAssociation[];
  threadId?: string;
  onOpenAddAttachmentDialog: (attachmentId?: string, emailId?: string) => void;
} & GridRenderCellParams<ReduxEmail>;

export const AttachmentsCell: FC<AttachmentsCellProps> = ({
  threadId,
  associations,
  onOpenAddAttachmentDialog,
  ...params
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'attachments-menu' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {params.row.id && params.row.attachments && params.row.attachments.length > 0 ? (
        <>
          <Box sx={{ height: '100%', verticalAlign: 'text-top' }}>
            <IconButton aria-label={'open-attachments-menu'} onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 9999 }} placement={'bottom-end'}>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <Paper
                sx={{
                  p: 2,
                  minWidth: '10rem',
                }}
              >
                <List>
                  {params.row.attachments.map((a: ReduxDocument) => (
                    <AttachmentLink
                      key={a.id}
                      attachment={a}
                      emailThreadId={threadId}
                      emailId={params.row.id!}
                      associations={associations}
                      onCloseMenu={handleCloseMenu}
                      onOpenAddAttachmentDialog={onOpenAddAttachmentDialog}
                    />
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
