import React, { FC, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { StripedDataGrid, styledFilterPanelProps } from '../../../datagrids/StripedDataGrid';
import {
  DataGridPremiumProps,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import {
  AssociationChildType,
  AssociationType,
  ICamAssociation,
  ITaskDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { dateFilters, FilterOperator, stringFilters } from '../../../utils/filteringUtils';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import { AssociationCell } from '../../../datagrids/AssociationCell';
import { useDispatch } from 'react-redux';
import {
  getEmailListAction,
  removeAssociationFromEmailAction,
  updateEmailAssociationStatusesFromTaskAction,
  useCommunication,
} from '../../redux/communicationSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { Filter, SortDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { EmailThreadDetailView } from '../EmailThreadDetailView';
import { stripThreadId } from '../../emailUtils';
import { ReduxEmail, ReduxEmailThread } from '../../redux/communicationTypes';
import { EmailParticipantsCell } from './EmailParticipantsCell';
import { IncomingCell } from './IncomingCell';
import Connector from '@monkeyjump-labs/cam-fe-shared/dist/services/signalrConnection';
import { toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { useTasks } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';

type EmailTableProps = {
  associationType?: AssociationType;
  associatedId?: string;
  childType?: AssociationChildType;
  childNumber?: string;
  onOpenAddAssociation: (id: string) => void;
  onOpenEmailDetail: (emailThread: ReduxEmailThread) => void;
  onReply: (emailThread: ReduxEmailThread) => void;
  onOpenAddTask: (id: string) => void;
};

export const EmailTable: FC<EmailTableProps> = ({
  associatedId,
  associationType,
  childType,
  childNumber,
  onOpenAddAssociation,
  onOpenEmailDetail,
  onReply,
  onOpenAddTask,
}) => {
  const dispatch = useDispatch();
  const { propertyEmails, associationEmails, selectedEmailThread } = useCommunication();
  const { newTaskHasBeenCreated } = useTasks();
  const { selectedContext } = useAssets();
  const [rows, setRows] = useState<ReduxEmailThread[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState<keyof ReduxEmailThread | undefined>();
  const [orderDirection, setOrderDirection] = useState<SortDirection | undefined>();
  const [filters, setFilters] = useState<Filter<ReduxEmailThread>[] | undefined>();
  const loading = associationType ? associationEmails.loading : propertyEmails.loading;

  const getDetailPanelHeight = useCallback<NonNullable<DataGridPremiumProps['getDetailPanelHeight']>>(
    () => 'auto' as const,
    [],
  );
  const getDetailPanelContent: DataGridPremiumProps['getDetailPanelContent'] = useCallback(
    ({ row }: GridRowParams<ReduxEmailThread>) => (
      <EmailThreadDetailView row={row} onReply={onReply} inModal={false} onOpenAddTask={onOpenAddTask} />
    ),
    [rows, onOpenAddTask, onReply],
  );

  useEffect(() => {
    selectedContext.propertyId &&
      dispatch(
        getEmailListAction({
          propertyId: selectedContext.propertyId,
          body: {
            associationType: associationType,
            associatedId: associatedId,
            associationChildType: childType,
            childNumber: childNumber,
          },
          page,
          pageSize,
          sortBy: orderBy,
          sortDirection: orderDirection,
          filters: filters,
        }),
      );
  }, [
    associatedId,
    associationType,
    childType,
    childNumber,
    page,
    pageSize,
    orderBy,
    orderDirection,
    filters,
    propertyEmails.submitted,
    associationEmails.submitted,
    selectedEmailThread.submitted,
    newTaskHasBeenCreated,
  ]);

  useEffect(() => {
    if (associationType) {
      setRows(associationEmails.value ?? []);
    } else setRows(propertyEmails.value ?? []);

    const connector = Connector.getExistingInstance();
    const updateEmailChips = (task: ITaskDto) => {
      dispatch(updateEmailAssociationStatusesFromTaskAction({ task: task }));
    };

    connector.onReceiveUpdatedTask(updateEmailChips);

    return () => {
      connector.offReceiveUpdatedTask(updateEmailChips);
    };
  }, [propertyEmails.value, associationEmails.value]);

  const handleClick = (id: string) => {
    const selection = rows.find((r) => r.id === id);
    if (selection) onOpenEmailDetail(selection);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      setOrderBy(undefined);
      setOrderDirection(undefined);
    } else {
      setOrderBy(model[0].field as keyof ReduxEmailThread);
      setOrderDirection(model[0].sort === 'asc' ? 'Ascending' : 'Descending');
    }
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    if (model.items.length === 0) {
      setFilters(undefined);
    } else {
      const parameterHasValues = model.items.length > 0 && model.items.every((parameter) => parameter.value);
      if (parameterHasValues) {
        const filters = model.items.map((items) => {
          return {
            name: items.field as keyof ReduxEmailThread,
            operator: items.operator as FilterOperator,
            value: items.value,
          };
        });
        setFilters(filters);
      }
    }
  };

  const handleRemoveAssociation = (id: string, association: ICamAssociation) => {
    if (!association.associatedId || !association.associationType) return;
    dispatch(
      removeAssociationFromEmailAction({
        id: id,
        association,
      }),
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'incoming',
      headerName: '',
      flex: 0.1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => {
        return <IncomingCell {...params} />;
      },
    },
    {
      field: 'to',
      headerName: 'To/From',
      filterOperators: stringFilters,
      flex: 0.3,
      type: 'string',
      renderCell: (params: GridRenderCellParams<ReduxEmailThread>) => {
        return <EmailParticipantsCell handleClick={handleClick} {...params} isThread={true} />;
      },
    },
    {
      field: 'subject',
      headerName: 'Subject',
      filterOperators: stringFilters,
      flex: 0.5,
      type: 'string',
      renderCell: (params) => {
        const subject = stripThreadId(params.value);
        const unreadEmail = params.row.emails.find((x: ReduxEmail) => x.isRead === false);
        if (unreadEmail) {
          return <strong>{subject}</strong>;
        } else return <>{subject}</>;
      },
    },
    {
      field: 'emails',
      headerName: 'Date',
      filterOperators: dateFilters,
      flex: 0.25,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        const lastEmail = params.row.emails[params.row.emails.length - 1];
        return <>{lastEmail.dateTimeSent ? tryFormatDate(lastEmail.dateTimeSent) : ''}</>;
      },
      valueGetter: (value, row) => {
        const lastEmail = row.emails[row.emails.length - 1];
        return toStandardDate(lastEmail.dateTimeSent);
      },
    },
    {
      field: 'associations',
      headerName: 'Associations',
      headerAlign: 'center',
      align: 'left',
      flex: 0.25,
      type: 'string',
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<ReduxEmailThread>) => {
        return (
          <AssociationCell
            {...params}
            associations={params.row.associations ?? []}
            onOpenAddAssociation={() => onOpenAddAssociation(params.row.id!)}
            onRemoveAssociation={(association) => handleRemoveAssociation(params.row.id!, association)}
            tableType={'email'}
          />
        );
      },
    },
  ];

  return (
    <Box my={1}>
      <StripedDataGrid
        disableRowGrouping
        autoHeight
        rows={rows}
        columns={columns}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        getRowClassName={(params) => {
          return params.row.emails[0].incoming ? 'incoming' : 'outgoing';
        }}
        disableRowSelectionOnClick
        rowCount={associationType ? (associationEmails.totalCount ?? 0) : (propertyEmails.totalCount ?? 0)}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        pagination
        paginationMode={'server'}
        sortingMode={'server'}
        onFilterModelChange={handleFilterModelChange}
        filterMode={'server'}
        onSortModelChange={handleSortModelChange}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={{ page: page, pageSize: pageSize }}
        onPaginationModelChange={(model: GridPaginationModel) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        slotProps={{
          filterPanel: styledFilterPanelProps,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              leaseId: associationType === AssociationType.RentalProperty,
            },
          },
        }}
      />
    </Box>
  );
};
