import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  deleteUserRoleAction,
  getAllowedRolesAction,
  loadUsersAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { StripedDataGrid } from '../../../../../_shared/datagrids/StripedDataGrid';
import {
  ISlimClaim,
  ISlimUserWithClaims,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef, GridPaginationModel, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import CancelIcon from '@mui/icons-material/Cancel';
import { convertAccessLevelUriToReadable } from '../../../../../auth/AuthorizedClaims';
import { Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import Add from '@mui/icons-material/Add';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { AddUserDialog } from './AddUserDialog';
import { phoneUtils } from '../../../../../_shared/utils/phoneUtils';
import { RenderGridCellExpand } from '../../../../../_shared/datagrids/RenderGridCellExpand';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

type LocalUser = {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  accessLevel?: string;
  claims?: ISlimClaim[];
};

export type NewUser = {
  email?: string;
  accessLevel?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
};

const mapPropertyUser = (user: ISlimUserWithClaims): LocalUser => {
  const userClaims: string[] = [];
  user.claims?.map((c) => {
    const claim = convertAccessLevelUriToReadable(c.type ?? '');
    if (!userClaims.includes(claim)) userClaims.push(claim);
  });
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: phoneUtils.formatPhoneNumber(user.phone ?? ''),
    accessLevel: userClaims.join(', ').replace(/,\s*$/, ''),
    claims: user.claims,
  };
};

export const UserAccessTable: FC = () => {
  const { id: propertyId } = useParams();
  const { selectedProperty, users } = useProperty();
  const dispatch = useDispatch();
  const [propertyUsers, setPropertyUsers] = useState(users.value?.map(mapPropertyUser) ?? []);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [includeTenants, setIncludeTenants] = useState(false);
  const [newUser, setNewUser] = useState<NewUser | undefined>(undefined);

  useEffect(() => {
    selectedProperty.value?.id &&
      dispatch(
        loadUsersAction({
          propertyId: selectedProperty.value.id,
          includeTenants,
          page,
          pageSize,
        }),
      );
  }, [page, pageSize, includeTenants]);

  const handleEditNewUser = (key: string, value: string) => {
    setNewUser({ ...newUser, [key]: value });
  };

  const resetNewUser = () => {
    setNewUser(undefined);
  };

  const handleSetNewUser = () => {
    setNewUser({});
  };

  useEffect(() => {
    setPropertyUsers(users.value?.map(mapPropertyUser) ?? []);
  }, [users.value]);

  useEffect(() => {
    dispatch(getAllowedRolesAction());
    if (propertyId) {
      dispatch(loadUsersAction({ propertyId, includeTenants, page, pageSize }));
    }
  }, []);

  const handleDeleteUser = (user: LocalUser, isTenant: boolean) => {
    if (!propertyId || !user.id) return;
    if (isTenant) {
      user.claims?.forEach((claim) => {
        if (claim.type === Claims.Tenant) {
          dispatch(
            deleteUserRoleAction({
              propertyId,
              userId: user.id!,
              includeTenants,
              page,
              pageSize,
              claimType: claim.type,
              claimValue: claim.value,
            }),
          );
        }
      });
    } else dispatch(deleteUserRoleAction({ propertyId, userId: user.id, includeTenants, page, pageSize }));
  };

  function* createActions(params: GridRowParams<LocalUser>) {
    if (!params.row.id) return;
    let isBillingOwner = false;
    let isTenant = false;
    let hasPropertyClaims = false;
    params.row.claims?.map((c) => {
      if (c.type === Claims.BillingOwner) isBillingOwner = true;
      if (c.type === Claims.Tenant) isTenant = true;
      if (
        c.type === Claims.PropertyManager ||
        c.type === Claims.OwnerInvestor ||
        c.type === Claims.ExternalVendor ||
        c.type === Claims.Employee ||
        c.type === Claims.Admin
      )
        hasPropertyClaims = true;
    });
    if (!isBillingOwner && hasPropertyClaims) {
      yield (
        <Tooltip key="removePropertyAccess" title="Remove All Property Access">
          <IconButton onClick={() => handleDeleteUser(params.row, false)}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      );
    }
    if (!isBillingOwner && isTenant) {
      yield (
        <Tooltip key="removeTenantAccess" title="Remove Tenant Access">
          <IconButton onClick={() => handleDeleteUser(params.row, true)}>
            <PersonRemoveIcon />
          </IconButton>
        </Tooltip>
      );
    }
  }

  const columns: GridColDef<LocalUser>[] = [
    {
      headerName: 'First Name',
      field: 'firstName',
      flex: 1,
      editable: false,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      flex: 1,
      editable: false,
    },
    {
      headerName: 'Email',
      field: 'email',
      flex: 1,
      editable: false,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      flex: 1,
      editable: false,
    },
    {
      headerName: 'Access Level',
      field: 'accessLevel',
      flex: 1,
      editable: false,
      renderCell: (params: GridRenderCellParams<LocalUser, string>) => {
        return <RenderGridCellExpand {...params} />;
      },
    },
    {
      headerName: 'Actions',
      type: 'actions',
      flex: 1,
      field: 'actions',
      editable: false,
      getActions: (params: GridRowParams<LocalUser>) => Array.from(createActions(params)),
    },
  ];

  return (
    <StyledInfoBox label={'User Access'}>
      <AddUserDialog
        newUser={newUser}
        onEditNewUser={handleEditNewUser}
        resetNewUser={resetNewUser}
        includeTenants={includeTenants}
        page={page}
        pageSize={pageSize}
      />
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center', pb: '1rem' }}>
        <Button variant={'outlined'} onClick={handleSetNewUser} startIcon={<Add />}>
          Add User
        </Button>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch size="small" checked={includeTenants} onChange={() => setIncludeTenants(!includeTenants)} />
            }
            label="Include Tenants"
          />
        </FormGroup>
      </Box>
      <Box
        sx={{
          minHeight: 450,
          width: 1,
        }}
      >
        <StripedDataGrid
          disableRowGrouping
          sx={{ minHeight: 450 }}
          density="compact"
          rows={propertyUsers}
          loading={users.loading}
          columns={columns}
          pagination
          rowCount={users.totalCount}
          paginationMode={'server'}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(model: GridPaginationModel) => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          getRowClassName={(params) => {
            return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
          }}
        />
      </Box>
    </StyledInfoBox>
  );
};
