import React, { FC } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import { AddScheduledTask } from './AddScheduledTask';
import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type ScheduledTaskDialogProps = {
  open: boolean;
  onClose: () => void;
  assetId: string;
  assetType: AssetType;
  isSopAdd: boolean;
  page: number;
  pageSize: number;
};

export const AddScheduledTaskDialog: FC<ScheduledTaskDialogProps> = ({
  open,
  onClose,
  assetId,
  assetType,
  isSopAdd,
  page,
  pageSize,
}) => {
  return (
    <DialogLayout
      title={isSopAdd ? 'Schedule Task from SOP Task' : 'Schedule Task'}
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth={'sm'}
    >
      <AddScheduledTask
        onClose={onClose}
        assetId={assetId}
        assetType={assetType}
        isSopAdd={isSopAdd}
        page={page}
        pageSize={pageSize}
      />
    </DialogLayout>
  );
};
