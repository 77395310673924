import { ISearchAllAssetsResult } from './redux/searchSlice';
import { SearchChildItem, SearchResultItem } from './components/Search';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  ApplicationDialogTab,
  AssetTab,
  DetailsTab,
  ExpenseDialogTab,
  InfoTab,
  LeaseDialogTab,
  LeasingTab,
  OperationsTab,
  OverviewTab,
  ScheduledTaskTab,
  SopTaskTab,
  TaskDialogTab,
} from '../tabs/TabTypeEnums';
import { useTheme } from '@mui/material';
import { leaseStatusMap } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { getLeaseTenantNames } from '../utils/getLeaseTenantNames';
import { TaskStatusMap } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { expenseStatusMap } from '../expenses/redux/expenseData';
import { useIcons } from '../icons/useIcons';

export interface SearchOption {
  item: SearchResultItem;
  assetType: AssociationType;
  assetUrl: string;
  label: string;
  icon: JSX.Element;
  secondaryText?: string | undefined;
  status?: string | undefined;
  childItem?: SearchChildItem;
  additionalInfo?: string | undefined;
}

export const useSearchHook = () => {
  const theme = useTheme();
  const { getAssociationIcon } = useIcons();
  const fillOptions = (value: ISearchAllAssetsResult | undefined, global: boolean) => {
    const assetOptions: SearchOption[] = [];
    if (value) {
      value.properties.forEach((property) => {
        assetOptions.push({
          item: property,
          assetType: AssociationType.RentalProperty,
          assetUrl: `/assets/property/${property.id}/${AssetTab.Overview}/${OverviewTab.Metrics}`,
          label: `${property.name}`,
          icon: getAssociationIcon(AssociationType.RentalProperty),
        });
      });
      value.buildings.forEach((building) => {
        assetOptions.push({
          item: building,
          assetType: AssociationType.Building,
          assetUrl: `/assets/building/${building.id}/${AssetTab.Overview}/${OverviewTab.Notifications}`,
          label: `${building.name}`,
          icon: getAssociationIcon(AssociationType.Building),
          secondaryText: global ? `${building.propertyName}` : undefined,
        });
      });
      value.units.forEach((unit) => {
        assetOptions.push({
          item: unit,
          assetType: AssociationType.BuildingUnit,
          assetUrl: `/assets/unit/${unit.id}/${AssetTab.Overview}/${OverviewTab.Notifications}`,
          label: `${unit.name}`,
          icon: getAssociationIcon(AssociationType.BuildingUnit),
          secondaryText: global ? `${unit.propertyName} / ${unit.buildingName}` : `${unit.buildingName}`,
        });
      });
      value.rooms.forEach((room) => {
        assetOptions.push({
          item: room,
          assetType: AssociationType.Room,
          assetUrl: `/assets/room/${room.id}`,
          label: `${room.name}`,
          icon: getAssociationIcon(AssociationType.Room),
        });
      });
      value.applications.forEach((application) => {
        assetOptions.push({
          item: application,
          assetType: AssociationType.Application,
          assetUrl: `/assets/property/${application.propertyId}/${AssetTab.Leasing}/${LeasingTab.Applications}/application/${application.id}/${ApplicationDialogTab.Info}`,
          label: `${application.firstName} ${application.lastName}`,
          icon: getAssociationIcon(AssociationType.Application),
          status: application.applicationStatus,
          secondaryText: global ? application.propertyName : undefined,
        });
      });
      value.leases.forEach((lease) => {
        const tenantNames = getLeaseTenantNames(lease);
        assetOptions.push({
          item: lease,
          assetType: AssociationType.Lease,
          assetUrl: `/assets/property/${lease.propertyId}/${AssetTab.Leasing}/${LeasingTab.Leasing}/lease/${lease.id}/${LeaseDialogTab.General}`,
          label: global
            ? `${lease.unitName} / ${lease.buildingName} / ${lease.propertyName}`
            : `${lease.unitName} / ${lease.buildingName}`,
          icon: getAssociationIcon(AssociationType.Lease),
          status: lease.detailedStatus && `${leaseStatusMap.get(lease.detailedStatus)}`,
          secondaryText: tenantNames.primaryTenantName ? `Primary Tenant: ${tenantNames.primaryTenantName}` : undefined,
          additionalInfo: tenantNames.otherTenantNames ? `Other Tenants: ${tenantNames.otherTenantNames}` : undefined,
        });
      });
      value.tasks.forEach((task) => {
        assetOptions.push({
          item: task,
          assetType: AssociationType.Task,
          assetUrl: `/assets/property/${task.propertyId}/${AssetTab.Operations}/${OperationsTab.Tasks}/task/${task.id}/${TaskDialogTab.Info}`,
          label: task.name ?? '',
          icon: getAssociationIcon(AssociationType.Task),
          secondaryText: global
            ? task.unitName
              ? `${task.propertyName} / ${task.buildingName} / ${task.unitName}`
              : task.buildingName
                ? `${task.propertyName} / ${task.buildingName}`
                : task.propertyName
            : task.associatedName,
          status: task.status && TaskStatusMap.get(task.status),
          additionalInfo: task.category ? task.category.name : undefined,
        });
      });
      value.expenses.forEach((expense) => {
        assetOptions.push({
          item: expense,
          assetType: AssociationType.Expense,
          assetUrl: `/assets/property/${expense.propertyId}/${AssetTab.Operations}/${OperationsTab.Expenses}/expense/${expense.id}/${ExpenseDialogTab.Info}`,
          label: expense.name ?? '',
          icon: getAssociationIcon(AssociationType.Expense),
          status: expense.expenseStatus ? expenseStatusMap.get(expense.expenseStatus) : undefined,
          secondaryText: global
            ? expense.unitName
              ? `${expense.propertyName} / ${expense.buildingName} / ${expense.unitName}`
              : expense.buildingName
                ? `${expense.propertyName} / ${expense.buildingName}`
                : expense.propertyName
            : expense.associatedName,
        });
      });
      value.details.forEach((detail) => {
        assetOptions.push({
          item: detail,
          assetType: AssociationType.Detail,
          assetUrl: `/assets/property/${detail.propertyId}/${AssetTab.Operations}/${OperationsTab.Details}/detail/${detail.id}/${DetailsTab.Info}`,
          label: detail.name ?? '',
          icon: getAssociationIcon(AssociationType.Detail),
          secondaryText: detail.assetPath,
        });
      });
      value.scheduledTasks.forEach((st) => {
        assetOptions.push({
          item: st,
          assetType: AssociationType.ScheduledTask,
          assetUrl: `/assets/property/${st.propertyId}/${AssetTab.Operations}/${OperationsTab.ScheduledTasks}/scheduledTask/${st.id}/${ScheduledTaskTab.Info}`,
          label: st.task?.name ?? '',
          icon: getAssociationIcon(AssociationType.ScheduledTask),
          secondaryText: st.unitName
            ? `${st.propertyName} / ${st.buildingName} / ${st.unitName}`
            : st.buildingName
              ? `${st.propertyName} / ${st.buildingName}`
              : st.propertyName,
        });
      });
      value.sopTasks.forEach((config) => {
        assetOptions.push({
          item: config,
          assetType: AssociationType.TaskConfiguration,
          assetUrl: `/assets/property/${config.propertyId}/${AssetTab.Info}/${InfoTab.SopDefinitions}/sopTask/${config.id}/${SopTaskTab.Task}`,
          label: config.name ?? '',
          icon: getAssociationIcon(AssociationType.TaskConfiguration),
          secondaryText: config.propertyName,
        });
      });

      const expenseTabMap = new Map([
        [AssociationType.ExpenseInvoice, ExpenseDialogTab.Invoices],
        [AssociationType.ExpensePayment, ExpenseDialogTab.Payments],
        [AssociationType.ExpensePurchaseOrder, ExpenseDialogTab.PurchaseOrders],
        [AssociationType.ExpenseQuote, ExpenseDialogTab.Quotes],
        [AssociationType.ExpenseWorkOrder, ExpenseDialogTab.WorkOrders],
      ]);

      value.expenseSubItems.forEach((subItem) => {
        assetOptions.push({
          item: subItem,
          assetType: subItem.type ?? AssociationType.Expense,
          assetUrl: `/assets/property/${subItem.propertyId}/${AssetTab.Operations}/${OperationsTab.Expenses}/expense/${
            subItem.expenseId
          }/${
            subItem.type && expenseTabMap.get(subItem.type) ? expenseTabMap.get(subItem.type) : ExpenseDialogTab.Info
          }`,
          label: subItem.label ?? '',
          icon: getAssociationIcon(subItem.type),
          secondaryText: subItem.propertyName,
        });
      });
      value.vendors.forEach((vendor) => {
        assetOptions.push({
          item: vendor,
          assetType: AssociationType.Vendor,
          assetUrl: `/assets/property/${vendor.propertyId}/${AssetTab.Info}/${InfoTab.VendorManagement}/vendor/${vendor.id}/info`,
          label: vendor.name ?? '',
          icon: getAssociationIcon(AssociationType.Vendor),
          secondaryText: vendor.primaryContact?.firstName ?? '',
        });
      });
    }
    return assetOptions;
  };

  const searchStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      backgroundColor: theme.palette.background.default,
      '&.Mui-focused fieldset': {
        borderColor: 'slategray',
      },
      '& fieldset': {
        borderColor: 'slategray',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'slategray',
    },
  };

  const searchButtonGroupStyles = {
    display: 'flex',
    flexGrow: '1',
    pt: '.5rem',
    borderColor: theme.palette.grey['600'],
  };

  const bigButtonInGroupStyles = {
    flexGrow: 1,
    borderColor: theme.palette.grey['600'],
    backgroundColor: theme.palette.background.default,
    fontSize: '.8rem',
    minHeight: '2.5rem',
  };

  const littleButtonInGroupStyles = {
    borderColor: theme.palette.grey['600'],
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
  };

  const globalButtonOptions = [
    'Search All',
    'Leases',
    'Applications',
    'Tasks',
    'Scheduled Tasks',
    'SOP Tasks',
    'Properties',
    'Buildings',
    'Units',
    'Rooms',
    'Expenses',
    'Details',
    'Invoices',
    'Payments',
    'Work Orders',
    'Quotes',
    'Purchase Orders',
  ];
  const nonAssetButtonOptions = [
    'Search All',
    'Leases',
    'Applications',
    'Tasks',
    'Scheduled Tasks',
    'SOP Tasks',
    'Expenses',
    'Details',
    'Invoices',
    'Payments',
    'Work Orders',
    'Quotes',
    'Purchase Orders',
    'Vendors',
  ];
  const purchaseOrderButtonOptions = ['Search All', 'Tasks', 'Scheduled Tasks'];

  const assetButtonOptions = ['Search All', 'Properties', 'Buildings', 'Units'];

  const detailButtonOptions = ['Search All', 'Tasks', 'Expenses', 'Scheduled Tasks', 'SOP Tasks'];

  const nonAssetAssociationTypeOptions = [
    AssociationType.Expense,
    AssociationType.Task,
    AssociationType.Lease,
    AssociationType.Application,
    AssociationType.TaskConfiguration,
    AssociationType.ScheduledTask,
    AssociationType.Detail,
    AssociationType.ExpensePurchaseOrder,
    AssociationType.ExpenseWorkOrder,
    AssociationType.ExpenseQuote,
    AssociationType.ExpenseInvoice,
    AssociationType.ExpensePayment,
    AssociationType.Vendor,
  ];

  const detailAssociationTypeOptions = [
    AssociationType.Expense,
    AssociationType.Task,
    AssociationType.TaskConfiguration,
    AssociationType.ScheduledTask,
  ];

  const purchaseOrderAssociationTypeOptions = [AssociationType.Task, AssociationType.ScheduledTask];

  const searchButtonMap = new Map([
    [AssociationType.RentalProperty, 'Properties'],
    [AssociationType.Building, 'Buildings'],
    [AssociationType.BuildingUnit, 'Units'],
    [AssociationType.Lease, 'Leases'],
    [AssociationType.Task, 'Tasks'],
    [AssociationType.Room, 'Rooms'],
    [AssociationType.Application, 'Applications'],
    [AssociationType.Expense, 'Expenses'],
    [AssociationType.Detail, 'Details'],
    [AssociationType.ScheduledTask, 'Scheduled Tasks'],
    [AssociationType.TaskConfiguration, 'SOP Tasks'],
    [AssociationType.ExpenseInvoice, 'Invoices'],
    [AssociationType.ExpensePayment, 'Payments'],
    [AssociationType.ExpenseQuote, 'Quotes'],
    [AssociationType.ExpensePurchaseOrder, 'Purchase Orders'],
    [AssociationType.ExpenseWorkOrder, 'Work Orders'],
    [AssociationType.Vendor, 'Vendors'],
  ]);

  return {
    fillOptions,
    searchStyles,
    searchButtonGroupStyles,
    bigButtonInGroupStyles,
    littleButtonInGroupStyles,
    globalStringOptions: globalButtonOptions,
    nonAssetStringOptions: nonAssetButtonOptions,
    detailStringOptions: detailButtonOptions,
    assetStringOptions: assetButtonOptions,
    nonAssetAssociationTypeOptions,
    detailAssociationTypeOptions,
    purchaseOrderAssociationTypeOptions,
    searchButtonMap,
    purchaseOrderButtonOptions,
  };
};
