import { EmailAssociationInfo, ReduxEmail, ReduxEmailThread, ReplyRecipientType } from './redux/communicationTypes';

export const stripThreadId = (subject: string) => {
  return subject.replace(/\[Comm ID: ([a-zA-Z0-9\-_]{11})\]/, '');
};

export const getLatestRecipients = (email?: ReduxEmail, isCc?: boolean): string[] => {
  if (!email) return [];
  const incoming = email.incoming;
  if (incoming) {
    const from = email.from ? [email.from] : [];
    const to = isCc ? email.cc : email.to;
    const all = from.concat(to ?? []);
    return isCc ? (to ?? []) : all;
  } else {
    const to = isCc ? email.cc : email.to;
    return to ?? [];
  }
};

// Helper function to extract the emails.
const extractEmails = (
  emailThread: ReduxEmailThread,
  field: 'to' | 'cc',
  originalAddresses: string[],
): { addedActiveEmails: string[]; originalActiveEmails: string[] } => {
  const allAddedEmails: string[] = [];
  const allRemovedEmails: string[] = [];

  emailThread.emails.forEach((e) => {
    const added = e[field]?.filter((x) => !originalAddresses.includes(x));
    const removed = e[field]?.filter((x) => !getLatestRecipients(emailThread.emails[0], field === 'cc').includes(x));
    added?.forEach((x) => {
      if (!allAddedEmails.includes(x)) allAddedEmails.push(x);
    });
    removed?.forEach((x) => {
      if (!allRemovedEmails.includes(x)) allRemovedEmails.push(x);
    });
  });
  return {
    addedActiveEmails: allAddedEmails.filter((x) => !allRemovedEmails.includes(x)),
    originalActiveEmails: originalAddresses.filter((x) => !allRemovedEmails.includes(x)),
  };
};

// Helper function to process the email addresses and assign reply types.
const processEmailAddresses = (
  emailAddresses: string[],
  addedActiveEmails: string[],
  originalActiveEmails: string[],
) => {
  return emailAddresses.map((address) => ({
    emailAddresses: [address],
    label: address,
    replyType: addedActiveEmails.includes(address)
      ? ReplyRecipientType.AddedActive
      : originalActiveEmails.includes(address)
        ? ReplyRecipientType.OriginalActive
        : ReplyRecipientType.Removed,
  }));
};

// Main function for assigning recipient types.
export const assignReplyTypes = (emailThread: ReduxEmailThread, type: 'recipient' | 'cc'): EmailAssociationInfo[] => {
  const originalEmailAddresses =
    type === 'recipient' ? (emailThread.to ?? []) : (emailThread.emails[emailThread.emails.length - 1].cc ?? []);
  const { addedActiveEmails, originalActiveEmails } = extractEmails(
    emailThread,
    type === 'recipient' ? 'to' : 'cc',
    originalEmailAddresses,
  );
  const allEmailAddresses: EmailAssociationInfo[] = [];
  const processedEmails: Set<string> = new Set();

  emailThread.emails.forEach((x) => {
    const emailAddressesToProcess =
      x[type === 'recipient' ? 'to' : 'cc']?.filter((address) => !processedEmails.has(address)) ?? [];
    const emailAddresses = processEmailAddresses(emailAddressesToProcess, addedActiveEmails, originalActiveEmails);
    allEmailAddresses.push(...emailAddresses);
    emailAddressesToProcess.forEach((address) => processedEmails.add(address));

    if (type === 'recipient' && x.from && !processedEmails.has(x.from)) {
      allEmailAddresses.push({
        emailAddresses: [x.from],
        label: x.from,
        replyType: ReplyRecipientType.OriginalActive,
      });
      processedEmails.add(x.from);
    }
  });
  if (type === 'cc' && emailThread.emails[0].from)
    allEmailAddresses.filter((e) => e.label === emailThread.emails[0].from);

  return allEmailAddresses;
};
