import {
  AssociationChildType,
  AssociationType,
  IEmailDto,
  IEmailThreadDto,
  ISms,
  ISmsDto,
  ISmsThreadDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  mapReduxDocument,
  ReduxDate,
  ReduxDocument,
  toReduxDate,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export type EmailAssociationInfo = {
  emailAddresses: string[];
  label: string; //either the email address or the name of the associated lease/app/etc
  associatedId?: string;
  associationType?: AssociationType;
  childType?: AssociationChildType;
  childNumber?: string;
  replyType?: ReplyRecipientType;
};

export enum EmailType {
  Bulk,
  SelectedLeaseOrApplicant,
  Reply,
  Uncontrolled,
}

export enum ReplyRecipientType {
  OriginalActive,
  AddedActive,
  Removed,
}

export type ReduxEmail = Omit<IEmailDto, 'dateTimeSent' | 'attachments'> & {
  dateTimeSent?: ReduxDate;
  attachments: ReduxDocument[];
};

export type ReduxEmailThread = Omit<IEmailThreadDto, 'emails' | 'lastUpdatedOn'> & {
  emails: ReduxEmail[];
  lastUpdatedOn?: ReduxDate;
};

export type ReduxSmsThread = Omit<ISmsThreadDto, 'lastUpdated'> & {
  lastUpdated?: ReduxDate;
};

export type ReduxSms = Omit<ISmsDto, 'dateTimeSent'> & {
  dateTimeSent?: ReduxDate;
};

export function mapReduxEmail(email: IEmailDto): ReduxEmail {
  return {
    ...email,
    dateTimeSent: toReduxDate(email.dateTimeSent),
    attachments: email.attachments?.map((a) => mapReduxDocument(a)) ?? [],
  };
}

export function mapReduxEmailThread(thread: IEmailThreadDto): ReduxEmailThread {
  return {
    ...thread,
    emails: thread.emails?.map((e) => mapReduxEmail(e)) ?? [],
    lastUpdatedOn: toReduxDate(thread.lastUpdatedOn),
  };
}

export function mapReduxSmsThread(thread: ISmsThreadDto): ReduxSmsThread {
  return {
    ...thread,
    lastUpdated: toReduxDate(thread.lastUpdated),
  };
}

export function mapReduxSms(sms: ISms): ReduxSms {
  return {
    ...sms,
    dateTimeSent: toReduxDate(sms.dateTimeSent),
  };
}
