import React, { FC } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { useCategories } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/categorySlice';
import { ITaskCategory } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import TextField from '@mui/material/TextField';

type TaskCategorySelectProps = {
  onChange: (v: ITaskCategory | null) => void;
  value?: ITaskCategory;
  size?: 'small' | 'medium' | undefined;
};

export const TaskCategoryAutocomplete: FC<TaskCategorySelectProps> = ({ onChange, value, size }) => {
  const { allCategories } = useCategories();

  const handleChange = (e: React.SyntheticEvent, v: ITaskCategory | null) => {
    onChange(v);
  };

  return (
    <FormControl fullWidth size={size}>
      <Autocomplete
        id="task-category"
        aria-label="task-category"
        value={value ?? null}
        options={allCategories.value ?? []}
        getOptionLabel={(option: ITaskCategory) => option.name ?? ''}
        renderInput={(params) => (
          <TextField sx={{ marginTop: 0, marginBottom: 0 }} {...params} variant="outlined" label="Task Category" />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={handleChange}
      />
    </FormControl>
  );
};
