import React, { FC } from 'react';
import { ExpenseElementType, ReduxWorkOrder, workOrderStatusMap } from '../../redux/expenseData';
import {
  AccountType,
  ISlimAccountRef,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ExpenseStatusSelect } from '../ExpenseStatusSelect';
import { AccountAutocomplete } from '../../../autocompletes/AccountAutocomplete';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

type WorkOrderFormProps = {
  value: ReduxWorkOrder;
  onChangeWorkOrder: (field: keyof ReduxWorkOrder, value: string) => void;
  propertyId: string;
  expenseAccount?: ISlimAccountRef;
  onChangeExpenseAccount: (account: ISlimAccountRef) => void;
  editingMode?: boolean;
};

export const WorkOrderForm: FC<WorkOrderFormProps> = ({
  value,
  onChangeWorkOrder,
  onChangeExpenseAccount,
  editingMode,
  expenseAccount,
  propertyId,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={editingMode ? 6 : 12}>
        <TextField
          fullWidth
          required
          label="Name"
          value={value.name}
          variant={'outlined'}
          onChange={(e) => onChangeWorkOrder('name', e.target.value)}
        />
      </Grid>
      {editingMode && (
        <Grid item xs={editingMode ? 6 : 12}>
          <ExpenseStatusSelect
            value={value.workOrderStatus}
            onChange={(e) => onChangeWorkOrder('workOrderStatus', e.target.value)}
            statusMap={workOrderStatusMap}
            type={ExpenseElementType.WorkOrder}
          />
        </Grid>
      )}
      <Grid item xs={editingMode ? 6 : 12}>
        <AccountAutocomplete
          value={expenseAccount}
          propertyId={propertyId}
          onChange={onChangeExpenseAccount}
          accountType={AccountType.Expenses}
          renderInput={(params) => <TextField margin="dense" variant="standard" label="Expense Account" {...params} />}
        />
      </Grid>
      {editingMode && (
        <Grid item xs={6}>
          <DatePicker
            label={'Date Completed'}
            onChange={(date: Date | null) => {
              if (date) onChangeWorkOrder('dateCompleted', toReduxDate(date));
              else onChangeWorkOrder('dateCompleted', '');
            }}
            slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
            value={value.dateCompleted ? toStandardDate(value.dateCompleted) : null}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Description"
          value={value.description}
          variant={'outlined'}
          multiline
          rows={3}
          onChange={(e) => onChangeWorkOrder('description', e.target.value)}
        />
      </Grid>
    </Grid>
  );
};
