import React, { FC, FormEvent, useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import { useDispatch } from 'react-redux';
import DialogContentText from '@mui/material/DialogContentText';
import {
  creditCardPaymentSuccessAction,
  initializeCreditCardPaymentAction,
  initiateTenantPaymentAction,
  useTenant,
} from '../../redux/tenantSlice';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { AccountOptionsSelect } from './AccountOptionsSelect';
import { NumberValueTextField } from '../../../_shared/texfields/NumberValueTextField';
import { NumericFormat } from 'react-number-format';
import { ReduxMtAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { useNavigate } from 'react-router-dom';
import { DefaultSelection } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AuthorizeNetIFrame } from '../../../_shared/paymentProcessing/authorizeNet/AuthorizeNetIFrame';
import { appRoutes } from '../../../../AppRouter';
import { paymentSubmittingAction, useStatement } from '../../../_shared/statements/redux/statementSlice';

type SchedulePaymentDialogProps = {
  open: boolean;
  onClose: () => void;
  leaseId?: string;
  unitName?: string;
  hasCreditCardPaymentsEnabled: boolean;
};

type MethodOfPayment = 'Bank Account' | 'Credit Card' | '';

export const MakePaymentDialog: FC<SchedulePaymentDialogProps> = ({
  open,
  onClose,
  leaseId,
  unitName,
  hasCreditCardPaymentsEnabled,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedStatement } = useStatement();
  const { bankAccounts, creditCardPayment } = useTenant();
  const userHasBankAccounts = bankAccounts.value && bankAccounts.value.length > 0;
  const [balance, setBalance] = useState<number>(0);
  const [selectedBankAccount, setSelectedBankAccount] = useState<ReduxMtAccount>();
  const [paymentDescription, setPaymentDescription] = useState('');
  const [amountToPay, setAmountToPay] = useState('');
  const [methodOfPayment, setMethodOfPayment] = useState<MethodOfPayment>('');
  const [showAnetContent, setShowAnetContent] = useState<boolean>(false);

  useEffect(() => {
    if (selectedStatement.value) {
      const statement = selectedStatement.value;
      const totalBalance = statement.entries?.reduce(
        (t, r) => t + (r.amount ?? 0),
        statement.previousBalance?.amount ?? 0,
      );
      setBalance(Number((totalBalance ?? 0).toFixed(2)));
      setAmountToPay(totalBalance && totalBalance > 0 ? totalBalance.toFixed(2) : '0');
    }
  }, [selectedStatement.value]);

  useEffect(() => {
    if (creditCardPayment.loaded) setShowAnetContent(true);
  }, [creditCardPayment.loaded]);

  useEffect(() => {
    const defaultBankAccount = bankAccounts?.value?.find(
      (account) => account.defaultSelection === DefaultSelection.General,
    );

    setSelectedBankAccount(defaultBankAccount);
    setMethodOfPayment('Bank Account');
  }, [bankAccounts.value]);

  useEffect(() => {
    if (selectedStatement.submitted) {
      handleClose();
    }
  }, [selectedStatement.submitted]);

  const handleSetBankAccount = (accountName?: string) => {
    if (!accountName) return setSelectedBankAccount(undefined);
    const account = bankAccounts?.value?.find((account) => account.name === accountName);
    setSelectedBankAccount(account);
  };

  const handleClose = () => {
    setPaymentDescription('');
    setAmountToPay('');
    setSelectedBankAccount(undefined);
    setMethodOfPayment('');
    onClose();
  };

  const handleInitiatePayment = (e: FormEvent) => {
    e.preventDefault();
    dispatch(paymentSubmittingAction());
    dispatch(
      initiateTenantPaymentAction({
        leaseId: leaseId,
        mtAccountId: selectedBankAccount?.mtAccountId,
        lastFour: selectedBankAccount?.safeBankAccount,
        amountInCents: parseFloat(amountToPay) * 100,
        description: paymentDescription,
      }),
    );
  };

  const handleCreditCardPayment = () => {
    leaseId &&
      dispatch(
        initializeCreditCardPaymentAction({
          leaseId: leaseId,
          requestedAmount: parseFloat(amountToPay),
          description: paymentDescription,
        }),
      );
  };

  const onAnetPaymentSuccess = (result: any) => {
    handleClose();
    navigate(appRoutes.tenantPortalRoutes.paymentCompleted);
    result = { ...result, unitName: unitName };
    dispatch(creditCardPaymentSuccessAction(result));
  };

  const onAnetPaymentCancel = () => {
    setShowAnetContent(false);
  };

  const dialogContent = (
    <div>
      <form onSubmit={handleInitiatePayment}>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant={'h6'} sx={{ mt: '.5rem' }} fontStyle={'italic'}>
              Current Balance:{' '}
              <NumericFormat
                prefix={'$'}
                value={balance}
                displayType={'text'}
                thousandSeparator
                fixedDecimalScale
                decimalScale={2}
              />
            </Typography>
            <Divider />
            <NumberValueTextField
              required
              valueUnits={'dollars'}
              label="Amount"
              value={amountToPay}
              type="number"
              onChange={(e) => setAmountToPay(e.target.value)}
              variant={'outlined'}
            />
            <FormControl fullWidth>
              <InputLabel id="select-method-of-payment">Select Method of Payment</InputLabel>
              <Select
                labelId="select-method-of-payment"
                id="methodOfPayment"
                value={methodOfPayment}
                label="Select Method of Payment"
                onChange={(e) => setMethodOfPayment(e.target.value as MethodOfPayment)}
              >
                <MenuItem disabled={!userHasBankAccounts} value={'Bank Account'}>
                  Bank Account {!userHasBankAccounts ? '(Please Add a Bank Account)' : ''}
                </MenuItem>
                <MenuItem disabled={!hasCreditCardPaymentsEnabled} value={'Credit Card'}>
                  Credit Card {!hasCreditCardPaymentsEnabled ? '(Not Enabled By Your Property)' : ''}
                </MenuItem>
              </Select>
            </FormControl>
            {methodOfPayment === 'Bank Account' && (
              <AccountOptionsSelect
                accounts={bankAccounts.value}
                onSetSelectedAccount={handleSetBankAccount}
                value={selectedBankAccount}
              />
            )}
            <TextField
              value={paymentDescription}
              onChange={(e) => setPaymentDescription(e.target.value)}
              variant={'outlined'}
              multiline
              rows={2}
              label={'Payment Notes (optional)'}
            />
            {methodOfPayment === 'Credit Card' && (
              <DialogContentText> **Credit card payments will incur additional fees.</DialogContentText>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          {methodOfPayment === 'Credit Card' ? (
            <LoadingButton variant={'contained'} onClick={handleCreditCardPayment} loading={creditCardPayment.loading}>
              Pay With Credit Card
            </LoadingButton>
          ) : (
            <LoadingButton
              autoFocus
              variant={'contained'}
              type={'submit'}
              loading={selectedStatement.submitting}
              disabled={!selectedBankAccount}
            >
              Pay With Bank Account
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </div>
  );
  const aNetContent = (
    <DialogContent>
      {creditCardPayment.loaded && (
        <AuthorizeNetIFrame
          token={creditCardPayment.value!.token!}
          fees={creditCardPayment.value!.fees!}
          amount={creditCardPayment.value!.requestedAmount!}
          onPaymentSuccess={onAnetPaymentSuccess}
          onCancel={onAnetPaymentCancel}
          selectedLeaseId={leaseId}
        />
      )}
    </DialogContent>
  );

  return (
    <DialogLayout
      open={open}
      onClose={handleClose}
      title={'Make Payment'}
      aria-labelledby="schedule-payment-title"
      PaperProps={{
        style: {
          minWidth: 750,
        },
      }}
    >
      {showAnetContent ? aNetContent : dialogContent}
    </DialogLayout>
  );
};
