import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { FileUploadButton } from '../../../../_shared/buttons/FileUploadButton';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  resetPropertySubmissionAction,
  updatePropertyDescriptionAction,
  updatePropertyLogoAction,
  updatePropertyThemeColorAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { useDispatch } from 'react-redux';
import { ImageCropperDialog } from '../../../../_shared/documents/components/ImageCropperDialog';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { SelectColor } from './SelectColor';

export const AdditionalDetails = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { selectedProperty } = useProperty();
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [description, setDescription] = React.useState<string>('');
  const [logo, setLogo] = React.useState<ReduxDocument | undefined>(undefined);
  const [openImageCropper, setOpenImageCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedColor, setSelectedColor] = useState<string>();

  const handleCloseImageCropper = () => {
    setSelectedFile(undefined);
    setOpenImageCropper(false);
  };

  useEffect(() => {
    if (selectedProperty.value) {
      setDescription(selectedProperty.value.details?.advertisingDescription || '');
      setLogo(selectedProperty.value.details?.logo);
      setSelectedColor(selectedProperty.value.details?.themeColor || '');
    }
  }, [selectedProperty.value]);

  useEffect(() => {
    selectedProperty.submitted && setDirty(false);
    dispatch(resetPropertySubmissionAction());
  }, [selectedProperty.submitted]);

  const handleUpdateDescription = (value: string) => {
    setDescription(value);
    setDirty(true);
  };

  const handleSubmitDescription = () => {
    selectedProperty.value?.id &&
      dispatch(updatePropertyDescriptionAction({ id: selectedProperty.value?.id, description }));
  };

  const handleUploadLogo = (file: File) => {
    selectedProperty.value?.id && dispatch(updatePropertyLogoAction({ id: selectedProperty.value?.id, logo: file }));
  };

  const handleSelectColor = (color: string) => {
    //if color does not start with a hashtag, add it
    if (!color.startsWith('#')) {
      color = '#' + color;
    }
    setSelectedColor(color);
    selectedProperty.value?.id &&
      dispatch(updatePropertyThemeColorAction({ id: selectedProperty.value?.id, color: color }));
  };

  if (!selectedProperty.value) return <></>;
  return (
    <Stack spacing={2} width={'100%'} p={2} pt={4}>
      <Typography variant={'h4'} color={theme.palette.primary.main}>
        Property Web View Configuration
      </Typography>
      <Stack direction={'column'} spacing={3}>
        <TextField
          fullWidth
          label={'Property Tagline / Description'}
          value={description}
          onChange={(e) => handleUpdateDescription(e.target.value)}
          multiline
          rows={3}
          helperText={'This text will appear below the property name in the internal advertising portal.'}
        />
        <Box>
          <TextField
            onChange={(e) => {
              setSelectedColor(e.target.value);
              setDirty(true);
            }}
            value={selectedColor ?? ''}
            label={'Background Color'}
            helperText={'This color will be used as the background color for the web view header; hex values only.'}
          />
        </Box>
        <Typography variant={'body1'} fontStyle={'italic'} color={theme.palette.primary.main} sx={{ mb: 2 }}>
          Optional: select background color from the following palette
        </Typography>
        <SelectColor
          setSelectedColor={(color: string) => {
            setSelectedColor(color);
            setDirty(true);
          }}
          selectedColor={selectedColor}
        />
      </Stack>
      <Box display={'flex'} flexGrow={1} justifyContent={'flex-end'}>
        <LoadingButton
          variant={'contained'}
          disabled={!dirty}
          loading={selectedProperty.submitting}
          onClick={() => {
            if (selectedColor !== selectedProperty.value?.details?.themeColor) handleSelectColor(selectedColor || '');
            if (description !== selectedProperty.value?.details?.advertisingDescription) handleSubmitDescription();
          }}
        >
          Save
        </LoadingButton>
      </Box>
      <Divider />
      <Box pt={2}>
        {logo && (
          <Box sx={{ p: 1 }}>
            <img src={logo.uri} alt={logo.name} style={{ width: '25%' }} />
          </Box>
        )}
        <FileUploadButton
          onUpload={(file: File) => {
            setSelectedFile(file);
            setOpenImageCropper(true);
          }}
          loading={selectedProperty.submitting}
          accept={'image/*'}
        >
          {logo ? 'Update Logo' : 'Add Logo'}
        </FileUploadButton>
      </Box>
      <ImageCropperDialog
        open={openImageCropper}
        onClose={handleCloseImageCropper}
        onUpload={handleUploadLogo}
        selectedFile={selectedFile}
      />
    </Stack>
  );
};
