import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  addAssociationToEmailAction,
  addAssociationToEmailFailAction,
  addAssociationToEmailSuccessAction,
  addAttachmentToAssociatedAssetAction,
  addAttachmentToAssociatedAssetFailAction,
  addAttachmentToAssociatedAssetSuccessAction,
  addWatcherToEmailThreadAction,
  attachmentAddedToAssociationAction,
  getAssociationEmailListSuccessAction,
  getEmailByIdAction,
  getEmailByIdFailAction,
  getEmailByIdSuccessAction,
  getEmailListAction,
  getEmailListFailAction,
  getPropertyEmailListSuccessAction,
  listSmsForThreadAction,
  listSmsForThreadFailAction,
  listSmsForThreadSuccessAction,
  listSmsThreadsAction,
  listSmsThreadsFailAction,
  listSmsThreadsSuccessAction,
  markEmailThreadReadAction,
  markEmailThreadReadFailAction,
  markEmailThreadReadSuccessAction,
  markSmsReadAction,
  markSmsReadSuccessAction,
  removeAssociationFromEmailAction,
  removeAssociationFromEmailFailAction,
  removeAssociationFromEmailSuccessAction,
  removeWatcherFromEmailThreadAction,
  sendEmailFromPropertyAction,
  sendEmailFromPropertyFailAction,
  sendEmailFromPropertySuccessAction,
  sendSmsToTenantAction,
  sendSmsToTenantFailAction,
  sendSmsToTenantSuccessAction,
  updateWatchersForEmailThreadFailAction,
  updateWatchersForEmailThreadSuccessAction,
} from './communicationSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import {
  AddAssociationToEmailThreadHandlerRequest,
  AddUserWatchHandlerRequest,
  AssociationChildType,
  AssociationType,
  CamAssociation,
  CreateSmsHandlerRequest,
  EmailThreadDto,
  FileParameter,
  GetEmailByIdHandlerResponse,
  GetSmsThreadsForAssetResponse,
  GetSmsThreadsHandlerResponse,
  ICamAssociation,
  ICreateSmsHandlerResponse,
  IEmailDto,
  IGetUnreadMessageCountHandlerResponse,
  IListEmailsHandlerRequest,
  IListEmailsHandlerResponse,
  IListSmsForThreadHandlerResponse,
  ListEmailsHandlerRequest,
  ListEmailsHandlerResponse,
  ListSmsForThreadHandlerResponse,
  ListSortDirection,
  MarkMessagesAsReadHandlerRequest,
  QueryExpression,
  RemoveUserWatchHandlerRequest,
  SmsThreadDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { Filter, SortDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { convertToQueryExpression } from '../../utils/filteringUtils';
import { mapReduxEmailThread, mapReduxSms } from './communicationTypes';
import {
  addAttachmentToSelectedExpenseAction,
  addAttachmentToSelectedExpenseFailAction,
  addAttachmentToSelectedExpenseSuccessAction,
} from '../../expenses/redux/expenseSlice';
import {
  resetAssociationSubmissionAction,
  setAssociationSubmittingSuccessAction,
} from '../../search/redux/searchSlice';

function* sendEmailFromProperty(
  action: PayloadAction<{
    propertyId: string;
    associationType?: AssociationType;
    associatedId?: string;
    associationChildType?: AssociationChildType;
    childNumber?: string;
    subject: string;
    emailBody: string;
    receiverEmails: string[];
    cc: string[];
    files: File[];
  }>,
) {
  try {
    const files: FileParameter[] = action.payload.files.map((f) => ({ fileName: f.name, data: f }));
    yield apiCall(
      ApiClientSingleton.getInstance().email_SendEmailFromProperty,
      action.payload.propertyId,
      action.payload.receiverEmails,
      action.payload.cc,
      action.payload.associationType,
      action.payload.associatedId,
      action.payload.associationChildType,
      action.payload.childNumber,
      action.payload.subject,
      action.payload.emailBody,
      files,
    );
    yield put(sendEmailFromPropertySuccessAction(action.payload.associationType));
    yield put(showToastMessageAction({ message: 'Email sent!', severity: 'success' }));
  } catch (error: any) {
    yield put(sendEmailFromPropertyFailAction(action.payload.associationType));
    yield put(showErrorAction({ error, fallbackMessage: 'Problem sending email' }));
  }
}

function* getEmailList(
  action: PayloadAction<{
    propertyId: string;
    body: IListEmailsHandlerRequest;
    page: number;
    pageSize: number;
    sortDirection: SortDirection | undefined;
    sortBy: keyof IEmailDto | undefined;
    filters: Filter<IEmailDto>[] | undefined;
  }>,
) {
  try {
    const formattedFilters: QueryExpression | undefined =
      action.payload.filters && convertToQueryExpression(action.payload.filters);
    const body = {
      associatedId: action.payload.body.associatedId,
      associationType: action.payload.body.associationType,
      associationChildType: action.payload.body.associationChildType,
      childNumber: action.payload.body.childNumber,
      query: {
        ...formattedFilters,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        orderBy: action.payload.sortBy ? [action.payload.sortBy] : undefined,
        orderDirection: action.payload.sortDirection as ListSortDirection,
      },
    };
    const response: ListEmailsHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().email_ListEmails,
      action.payload.propertyId,
      new ListEmailsHandlerRequest(body),
    );
    if (response) {
      const jsonResponse: IListEmailsHandlerResponse = response.toJSON();
      const emailThreads = jsonResponse.emailThreads?.map((t) => mapReduxEmailThread(t));
      if (action.payload.body.associationType) {
        yield put(
          getAssociationEmailListSuccessAction({
            emailThreads: emailThreads ?? [],
            count: jsonResponse.count ?? 0,
          }),
        );
      } else
        yield put(
          getPropertyEmailListSuccessAction({
            emailThreads: emailThreads ?? [],
            count: jsonResponse.count ?? 0,
          }),
        );
    } else {
      yield put(showToastMessageAction({ message: 'Something went wrong getting emails', severity: 'error' }));
    }
  } catch (error: any) {
    yield put(getEmailListFailAction(action.payload.body.associationType));
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting emails' }));
  }
}

function* getEmailById(action: PayloadAction<string>) {
  try {
    const response: GetEmailByIdHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().email_GetEmailThread,
      action.payload,
    );
    if (response) {
      yield put(getEmailByIdSuccessAction(response.toJSON().emailThread));
    } else {
      yield put(
        showToastMessageAction({
          message: 'Something went wrong getting the selected email thread',
          severity: 'error',
        }),
      );
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem fetching selected email thread' }));
    yield put(getEmailByIdFailAction());
  }
}

function* addAssociationToEmail(
  action: PayloadAction<{
    id: string;
    associatedId: string;
    associationType: AssociationType;
    associationChildType?: AssociationChildType;
    associationChildNumber?: string;
  }>,
) {
  try {
    if (action.payload.associationType === AssociationType.Expense) {
      yield put(addAttachmentToSelectedExpenseAction());
    }
    yield apiCall(
      ApiClientSingleton.getInstance().email_AddAssociation,
      action.payload.id,
      new AddAssociationToEmailThreadHandlerRequest({
        associatedId: action.payload.associatedId,
        associationType: action.payload.associationType,
        associationChildType: action.payload.associationChildType,
        associationChildNumber: action.payload.associationChildNumber,
      }),
    );
    yield put(addAssociationToEmailSuccessAction());
    yield put(setAssociationSubmittingSuccessAction());
    if (action.payload.associationType === AssociationType.Expense) {
      yield put(addAttachmentToSelectedExpenseSuccessAction());
    }
    yield put(showToastMessageAction({ message: 'Association added to email', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding association to email thread' }));
    yield put(resetAssociationSubmissionAction());
    yield put(addAssociationToEmailFailAction());
    if (action.payload.associationType === AssociationType.Expense) {
      yield put(addAttachmentToSelectedExpenseFailAction());
    }
  }
}

function* removeAssociationFromEmail(
  action: PayloadAction<{
    id: string;
    association: ICamAssociation;
  }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().email_RemoveAssociation,
      action.payload.id,
      new CamAssociation(action.payload.association),
    );
    yield put(removeAssociationFromEmailSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem removing association from email thread' }));
    yield put(removeAssociationFromEmailFailAction());
  }
}

function* markEmailThreadRead(action: PayloadAction<string>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().email_MarkEmailThreadRead, action.payload);
    yield put(markEmailThreadReadSuccessAction(action.payload));
  } catch (error: any) {
    yield put(markEmailThreadReadFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem marking email thread read' }));
  }
}

function* addAttachmentToAssociatedAsset(
  action: PayloadAction<{
    threadId: string;
    emailId: string;
    attachmentId: string;
    association: ICamAssociation;
  }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().email_AddAttachmentToAssociatedAsset,
      action.payload.threadId,
      action.payload.emailId,
      action.payload.attachmentId,
      new CamAssociation(action.payload.association),
    );
    yield put(addAttachmentToAssociatedAssetSuccessAction());
    yield put(
      showToastMessageAction({ message: 'Attachment successfully added to associated asset', severity: 'success' }),
    );
    yield put(attachmentAddedToAssociationAction(action.payload.association.associationType));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding attachment to associated asset' }));
    yield put(addAttachmentToAssociatedAssetFailAction());
  }
}

function* listSmsThreads(
  action: PayloadAction<{
    propertyId: string;
    associationType?: AssociationType;
    associationId?: string;
  }>,
) {
  try {
    let threads: SmsThreadDto[] = [];
    if (action.payload.associationType && action.payload.associationId) {
      if (action.payload.associationType === AssociationType.Application) {
        const result: GetSmsThreadsForAssetResponse = yield apiCall(
          ApiClientSingleton.getInstance().sms_ListSmsThreadsForApplication,
          action.payload.propertyId,
          action.payload.associationId,
        );
        threads = result.toJSON().threads;
      } else if (action.payload.associationType === AssociationType.Lease) {
        const result: GetSmsThreadsForAssetResponse = yield apiCall(
          ApiClientSingleton.getInstance().sms_ListSmsThreadsForLease,
          action.payload.propertyId,
          action.payload.associationId,
        );

        threads = result.toJSON().threads;
      }
    } else {
      const result: GetSmsThreadsHandlerResponse = yield apiCall(
        ApiClientSingleton.getInstance().sms_ListSmsThreadsForProperty,
        action.payload.propertyId,
      );

      threads = result.toJSON().threads;
    }

    yield put(listSmsThreadsSuccessAction({ threads }));
  } catch (error: any) {
    yield put(listSmsThreadsFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem listing sms threads' }));
  }
}

function* listSmsForThread(
  action: PayloadAction<{
    propertyId: string;
    phoneNumber: string;
    page: number;
    pageSize: number;
    appendToExistingSms: boolean;
  }>,
) {
  try {
    const result: ListSmsForThreadHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().sms_ListSmsForPhone,
      action.payload.propertyId,
      action.payload.phoneNumber,
      action.payload.page,
      action.payload.pageSize,
    );

    if (result) {
      const data: IListSmsForThreadHandlerResponse = result.toJSON();
      const sms = data.sms?.map((message) => mapReduxSms(message));
      if (sms !== undefined && data.totalCount !== undefined) {
        yield put(
          listSmsForThreadSuccessAction({
            messages: sms,
            total: data.totalCount,
            appendToExistingSms: action.payload.appendToExistingSms,
          }),
        );
        yield put(
          markSmsReadAction({
            propertyId: action.payload.propertyId,
            phoneNumber: action.payload.phoneNumber,
            smsIds: sms.filter((x) => typeof x.id === 'string' && x.isRead === false).map((m) => m.id!),
          }),
        );
      }
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem listing sms messages' }));
    yield put(listSmsForThreadFailAction());
  }
}

function* sendSmsToTenant(action: PayloadAction<{ propertyId: string; phoneNumber: string; message: string }>) {
  try {
    const response: ICreateSmsHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().sms_SendSms,
      action.payload.propertyId,
      CreateSmsHandlerRequest.fromJS({ ...action.payload, attachments: [] }),
    );
    if (response.sms) yield put(sendSmsToTenantSuccessAction({ newSms: mapReduxSms(response.sms) }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem sending sms message' }));
    yield put(sendSmsToTenantFailAction());
  }
}

function* markSmsAsRead(action: PayloadAction<{ propertyId: string; phoneNumber: string; smsIds: string[] }>) {
  try {
    if (action.payload.smsIds.length === 0) return;

    const response: IGetUnreadMessageCountHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().sms_MarkMessagesAsRead,
      action.payload.propertyId,
      action.payload.phoneNumber.replaceAll('-', ''),
      MarkMessagesAsReadHandlerRequest.fromJS({ smsIds: action.payload.smsIds }),
    );

    if (response?.unreadSmsCount !== undefined)
      yield put(
        markSmsReadSuccessAction({
          phoneNumber: action.payload.phoneNumber,
          unreadCount: response.unreadSmsCount,
        }),
      );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem marking sms as read' }));
  }
}

function* addWatcherToEmailThread(action: PayloadAction<{ threadId: string; userId: string }>) {
  try {
    const body = new AddUserWatchHandlerRequest({ userId: action.payload.userId });
    const response: EmailThreadDto = yield apiCall(
      ApiClientSingleton.getInstance().email_AddWatch,
      action.payload.threadId,
      body,
    );

    yield put(showToastMessageAction({ message: 'Watcher added to email thread', severity: 'success' }));
    yield put(updateWatchersForEmailThreadSuccessAction({ updatedThread: response.toJSON() }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding watcher to email thread' }));
    yield put(updateWatchersForEmailThreadFailAction());
  }
}

function* removeWatcherFromEmailThread(action: PayloadAction<{ threadId: string; userId: string }>) {
  try {
    const body = new RemoveUserWatchHandlerRequest({ userId: action.payload.userId });
    const response: EmailThreadDto = yield apiCall(
      ApiClientSingleton.getInstance().email_RemoveWatch,
      action.payload.threadId,
      body,
    );

    yield put(showToastMessageAction({ message: 'Watcher removed from email thread', severity: 'success' }));
    yield put(updateWatchersForEmailThreadSuccessAction({ updatedThread: response.toJSON() }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem removing watcher from email thread' }));
    yield put(updateWatchersForEmailThreadFailAction());
  }
}

export function* communicationSagas() {
  yield all([
    takeEvery(sendEmailFromPropertyAction.type, sendEmailFromProperty),
    takeLatest(getEmailListAction.type, getEmailList),
    takeLatest(getEmailByIdAction.type, getEmailById),
    takeLatest(addAssociationToEmailAction.type, addAssociationToEmail),
    takeLatest(removeAssociationFromEmailAction.type, removeAssociationFromEmail),
    takeLatest(markEmailThreadReadAction.type, markEmailThreadRead),
    takeLatest(addAttachmentToAssociatedAssetAction.type, addAttachmentToAssociatedAsset),
    takeLatest(listSmsThreadsAction.type, listSmsThreads),
    takeLatest(listSmsForThreadAction.type, listSmsForThread),
    takeLatest(sendSmsToTenantAction.type, sendSmsToTenant),
    takeLatest(markSmsReadAction.type, markSmsAsRead),
    takeLatest(addWatcherToEmailThreadAction.type, addWatcherToEmailThread),
    takeLatest(removeWatcherFromEmailThreadAction.type, removeWatcherFromEmailThread),
  ]);
}
