import {
  ICamNotificationConfigurationDto,
  IListCamNotificationByAssetTypeHandlerSingleResponse,
  IListCamNotificationHandlerSingleResponse,
  NotificationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { format, isDate } from 'date-fns';
import { mapReduxSopTask, ReduxSopTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';

export type ReduxUserNotification = Omit<IListCamNotificationHandlerSingleResponse, 'createdAt'> & {
  createdAt?: string;
};

export type ReduxNotification = Omit<IListCamNotificationByAssetTypeHandlerSingleResponse, 'createdAt'> & {
  createdAt?: string;
};

export type ReduxNotificationConfiguration = Omit<ICamNotificationConfigurationDto, 'taskConfiguration'> & {
  taskConfiguration?: ReduxSopTask;
};

export type NotifiedUserOptionType = {
  label: string;
  value: string;
};

export function mapReduxUserNotifications(
  notification: IListCamNotificationHandlerSingleResponse,
): ReduxUserNotification {
  return {
    ...notification,
    createdAt: isDate(notification.createdAt)
      ? notification.createdAt!.toISOString()
      : (notification.createdAt as unknown as string),
  };
}

export function mapReduxNotification(
  notification: IListCamNotificationByAssetTypeHandlerSingleResponse,
): ReduxNotification {
  return {
    ...notification,
    createdAt: isDate(notification.createdAt)
      ? notification.createdAt!.toISOString()
      : (notification.createdAt as unknown as string),
  };
}

export function mapReduxNotificationConfiguration(
  notification: ICamNotificationConfigurationDto,
): ReduxNotificationConfiguration {
  return {
    ...notification,
    taskConfiguration: notification.taskConfiguration ? mapReduxSopTask(notification.taskConfiguration) : undefined,
  };
}

export function getSupportedRoleUserOptions(
  supportedRoles: { [key: string]: string } | undefined,
): NotifiedUserOptionType[] {
  const userOptionArray: NotifiedUserOptionType[] = [];
  if (supportedRoles) {
    for (const property in supportedRoles) {
      const optionType: NotifiedUserOptionType = {
        label: property,
        value: supportedRoles![property]!,
      };
      userOptionArray.push(optionType);
    }
  }
  return userOptionArray;
}

export function getNotificationConfigNotifiedUsers(
  supportedRoles: NotifiedUserOptionType[],
  notification: ReduxNotificationConfiguration,
): NotifiedUserOptionType[] {
  const userArray: NotifiedUserOptionType[] = [];
  supportedRoles.forEach((role) => {
    if (notification.notifiedUsers?.includes(role.value)) {
      userArray.push(role);
    }
  });
  return userArray;
}

export function notificationCreatedTimeAgo(date: Date, currentDate?: Date): string {
  const now = currentDate || new Date();
  const milliseconds = +now - +date;
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  // Adjusting hour calculation to round up if the remainder of minutes is non-zero
  const hours = Math.floor(minutes / 60) + (minutes % 60 === 0 ? 0 : 1);
  // Similarly adjusting day calculation
  const days = Math.floor(hours / 24) + (hours % 24 === 0 ? 0 : 1);

  if (seconds < 300) {
    return 'Just now';
  } else if (hours < 24) {
    return format(date, 'h:mm a');
  } else if (days < 7) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (days <= 30) {
    return `${Math.floor(days / 7)} week${Math.floor(days / 7) > 1 ? 's' : ''} ago`;
  } else {
    return format(date, 'MMM d, yyyy');
  }
}

export const notificationTypeMap = new Map<NotificationType, string>([
  [NotificationType.Expense, 'Expense'],
  [NotificationType.EmailThread, 'Email'],
  [NotificationType.Task, 'Task'],
  [NotificationType.Lease, 'Lease'],
  [NotificationType.SmsThread, 'SMS'],
]);
