import React, { FC, useEffect, useState } from 'react';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers-pro';
import { addDays, addHours } from 'date-fns';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { ReduxTimeConfiguration } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

type ScheduleTimeConfigurationProps = {
  timeConfiguration?: ReduxTimeConfiguration;
  onSetTimeConfiguration: (timeConfiguration: ReduxTimeConfiguration) => void;
  editingMode?: boolean;
  onRemoveScheduling?: () => void;
  isNotScheduledTask?: boolean;
};

export const ScheduleTimeConfiguration: FC<ScheduleTimeConfigurationProps> = ({
  timeConfiguration,
  onSetTimeConfiguration,
  editingMode,
  onRemoveScheduling,
  isNotScheduledTask,
}) => {
  const { selectedContext } = useAssets();
  const [allDay, setAllDay] = useState(timeConfiguration?.isAllDay);
  const [startDate, setStartDate] = useState<Date | null>(
    timeConfiguration?.startDateTime ? toStandardDate(timeConfiguration?.startDateTime) : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    timeConfiguration?.endDateTime ? toStandardDate(timeConfiguration?.endDateTime) : null,
  );
  useEffect(() => {
    setStartDate(timeConfiguration?.startDateTime ? toStandardDate(timeConfiguration?.startDateTime) : null);
  }, [timeConfiguration?.startDateTime]);
  useEffect(() => {
    setEndDate(timeConfiguration?.endDateTime ? toStandardDate(timeConfiguration?.endDateTime) : null);
  }, [timeConfiguration?.endDateTime]);
  useEffect(() => {
    setAllDay(timeConfiguration?.isAllDay);
  }, [timeConfiguration?.isAllDay]);

  const handleAllDaySwitch = () => {
    const newAllDay = !allDay;
    setAllDay(newAllDay);
    if (newAllDay) {
      handleIsAllDay(true);
    } else handleIsNotAllDay(false);
  };

  const handleIsAllDay = (allDay: boolean, date?: Date) => {
    //set scheduledStart to beginning of day
    //set scheduledEnd to end of day
    const dateStringWithoutTimeStamp = date ? toReduxDate(date) : toReduxDate(timeConfiguration?.startDateTime);
    const dateWithBlankTimeStamp = toStandardDate(dateStringWithoutTimeStamp ?? '');
    const sixAmOfDate = addHours(dateWithBlankTimeStamp, 6);
    const sixPmOfDate = addHours(dateWithBlankTimeStamp, 18);
    onSetTimeConfiguration &&
      onSetTimeConfiguration({
        ...timeConfiguration,
        startDateTime: sixAmOfDate.toISOString(),
        endDateTime: sixPmOfDate.toISOString(),
        isAllDay: allDay,
      });
  };

  const handleIsNotAllDay = (allDay: boolean, date?: Date) => {
    if (!date) {
      //set both to redux dates (no timestamp, to be filled in with time selection)
      onSetTimeConfiguration &&
        onSetTimeConfiguration({
          ...timeConfiguration,
          startDateTime: toReduxDate(timeConfiguration?.startDateTime),
          endDateTime: toReduxDate(timeConfiguration?.endDateTime),
          isAllDay: allDay,
        });
    } else {
      onSetTimeConfiguration &&
        onSetTimeConfiguration({
          ...timeConfiguration,
          startDateTime: toReduxDate(date),
          endDateTime: toReduxDate(date),
          isAllDay: allDay,
        });
    }
  };

  const handleAddScheduling = () => {
    const initializeDate = addDays(toStandardDate(selectedContext.currentDate ?? ''), 1);
    const blankTimeConfiguration: ReduxTimeConfiguration = {
      startDateTime: addHours(initializeDate, 6).toISOString(), //beginning of day
      endDateTime: addHours(initializeDate, 18).toISOString(), //EOD
      isAllDay: true,
    };
    onSetTimeConfiguration(blankTimeConfiguration);
  };

  if (!timeConfiguration) {
    return (
      <Grid container display={'flex'} justifyContent={'center'}>
        <Button variant={'outlined'} onClick={handleAddScheduling}>
          Add Scheduling
        </Button>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {allDay ? (
        <Grid item xs={12} md={editingMode ? 6 : 12}>
          <DatePicker
            label={'Scheduled Date'}
            onChange={(date) => {
              if (allDay) {
                handleIsAllDay(allDay, date ? toStandardDate(date) : undefined);
              } else handleIsNotAllDay(false, date ? toStandardDate(date) : undefined);
            }}
            value={startDate}
            slotProps={{ textField: { required: true, fullWidth: true } }}
            minDate={isNotScheduledTask ? undefined : addDays(toStandardDate(selectedContext.currentDate ?? ''), 1)}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={editingMode ? 6 : 12}>
            <DateTimePicker
              label={'Scheduled Start'}
              onChange={(date: Date | null) => {
                onSetTimeConfiguration({
                  ...timeConfiguration,
                  startDateTime: date ? date.toISOString() : undefined,
                });
              }}
              value={startDate}
              slotProps={{ textField: { required: true, fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={12} md={editingMode ? 6 : 12}>
            <DateTimePicker
              label={'Scheduled End'}
              onChange={(date: Date | null) => {
                onSetTimeConfiguration({
                  ...timeConfiguration,
                  endDateTime: date ? date.toISOString() : undefined,
                });
              }}
              value={endDate}
              slotProps={{ textField: { required: true, fullWidth: true } }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Box sx={{ minHeight: '4rem' }}>
          <FormControl>
            <FormControlLabel control={<Checkbox checked={allDay} onChange={handleAllDaySwitch} />} label={'All Day'} />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Box>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={timeConfiguration?.showInCalendar}
                  onChange={(e) =>
                    onSetTimeConfiguration({
                      ...timeConfiguration,
                      showInCalendar: e.target.checked,
                    })
                  }
                />
              }
              label={'Show In Calendar'}
            />
          </FormControl>
        </Box>
      </Grid>
      {onRemoveScheduling && (
        <Grid item>
          <Button variant={'outlined'} onClick={onRemoveScheduling}>
            Remove Scheduling
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
