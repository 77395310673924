import {
  Filter,
  initInteractable,
  initInteractableCollection,
  initPageableCollection,
  Interactable,
  InteractableCollection,
  PageableCollection,
  SortDirection,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {
  AssociationChildType,
  AssociationType,
  ICamAssociation,
  IListEmailsHandlerRequest,
  ITaskDto,
  SmsThreadDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  EmailAssociationInfo,
  EmailType,
  mapReduxSmsThread,
  ReduxEmail,
  ReduxEmailThread,
  ReduxSms,
  ReduxSmsThread,
} from './communicationTypes';
import { getUpdatedTaskAssociationLabel, getUpdatedTaskAssociationStatus } from '../../utils/signalRUtils';

export interface CommunicationState {
  propertyEmails: PageableCollection<ReduxEmailThread>;
  associationEmails: PageableCollection<ReduxEmailThread>;
  selectedEmailThread: Interactable<ReduxEmailThread>;
  selectedAssociation:
    | {
        associationType?: AssociationType;
        associatedId?: string;
        associationChildType?: AssociationChildType;
        childNumber?: string;
      }
    | undefined;
  smsMessages: PageableCollection<ReduxSms>;
  smsThreads: InteractableCollection<ReduxSmsThread>;
  selectedSmsThread: string | undefined;
  currentSmsMessage: Interactable<string>;
  emailRecipientType: EmailType | undefined;
  detailedRecipientInfo: InteractableCollection<EmailAssociationInfo>;
  detailedCcInfo: InteractableCollection<EmailAssociationInfo>;
  attachmentAddedToAssociation: AssociationType | undefined;
}

const initialState: CommunicationState = {
  propertyEmails: initPageableCollection<ReduxEmailThread>(),
  associationEmails: initPageableCollection<ReduxEmailThread>(),
  selectedEmailThread: initInteractable<ReduxEmailThread>(),
  selectedAssociation: undefined,
  smsMessages: initPageableCollection<ReduxSms>(),
  smsThreads: initInteractableCollection<ReduxSmsThread>(),
  selectedSmsThread: undefined,
  currentSmsMessage: initInteractable<string>(),
  emailRecipientType: undefined,
  detailedRecipientInfo: initInteractableCollection<EmailAssociationInfo>(),
  detailedCcInfo: initInteractableCollection<EmailAssociationInfo>(),
  attachmentAddedToAssociation: undefined,
};
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const communicationSlice = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    newEmailThreadArrivedAction: (state, action: PayloadAction<ReduxEmailThread>) => {
      //property threads if it already exists
      const existingThread = state.propertyEmails.value?.find((t) => t.id === action.payload.id);
      if (existingThread) {
        const threads = state.propertyEmails.value ?? [];
        const index = threads.findIndex((t) => t.id === action.payload.id);
        threads[index] = action.payload;
        state.propertyEmails.value = threads;
        return;
      }
      //association threads if it already exists
      const existingAssociationThread = state.associationEmails.value?.find((t) => t.id === action.payload.id);
      if (existingAssociationThread) {
        const associationThreads = state.associationEmails.value ?? [];
        const index = associationThreads.findIndex((t) => t.id === action.payload.id);
        associationThreads[index] = action.payload;
        state.associationEmails.value = associationThreads;
        return;
      }
      //update property threads with new email
      const threads = state.propertyEmails.value ?? [];
      const length = threads.unshift(action.payload);
      const pageSize = state.propertyEmails.pageSize ?? 10;
      if (length > pageSize) threads.pop();
      state.propertyEmails.value = threads;
      let totalCount = state.propertyEmails.totalCount ?? 0;
      state.propertyEmails.totalCount = ++totalCount;
      //update association threads with new email
      const aThreads = state.associationEmails.value ?? [];
      const aLength = aThreads.unshift(action.payload);
      const aPageSize = state.associationEmails.pageSize ?? 10;
      if (aLength > aPageSize) aThreads.pop();
      state.associationEmails.value = aThreads;
      let aTotalCount = state.associationEmails.totalCount ?? 0;
      state.associationEmails.totalCount = ++aTotalCount;
    },
    newEmailArrivedAction: (state, action: PayloadAction<{ email: ReduxEmail; threadId: string }>) => {
      //property threads
      if (state.propertyEmails.value != null) {
        const thread = state.propertyEmails.value.find((t) => t.threadId == action.payload.threadId);
        if (thread != null) thread.emails.unshift(action.payload.email);
      }
      //association threads
      if (state.associationEmails.value == null) return;
      const aThread = state.associationEmails.value.find((t) => t.threadId == action.payload.threadId);
      if (aThread != null) aThread.emails.unshift(action.payload.email);
    },
    sendEmailFromPropertyAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        associationType?: AssociationType;
        associatedId?: string;
        associationChildType?: AssociationChildType;
        childNumber?: string;
        subject: string;
        emailBody: string;
        receiverEmails: string[];
        cc: string[];
        files: File[];
      }>,
    ) => {
      if (action.payload.associationType) {
        state.associationEmails.submitting = true;
        state.associationEmails.submitted = false;
      } else {
        state.propertyEmails.submitting = true;
        state.propertyEmails.submitted = false;
      }
    },
    sendEmailFromPropertySuccessAction: (state, action: PayloadAction<AssociationType | undefined>) => {
      if (action.payload) {
        state.associationEmails.submitted = true;
        state.associationEmails.submitting = false;
      } else {
        state.propertyEmails.submitted = true;
        state.propertyEmails.submitting = false;
      }
    },
    sendEmailFromPropertyFailAction: (state, action: PayloadAction<AssociationType | undefined>) => {
      if (action.payload) {
        state.associationEmails.submitted = false;
        state.associationEmails.submitting = false;
      } else {
        state.propertyEmails.submitted = false;
        state.propertyEmails.submitting = false;
      }
    },
    getEmailListAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        body: IListEmailsHandlerRequest;
        page: number;
        pageSize: number;
        sortDirection: SortDirection | undefined;
        sortBy: keyof ReduxEmailThread | undefined;
        filters: Filter<ReduxEmailThread>[] | undefined;
      }>,
    ) => {
      if (action.payload.body.associationType) {
        state.associationEmails.loading = true;
        state.associationEmails.loaded = false;
      } else {
        state.propertyEmails.loading = true;
        state.propertyEmails.loaded = false;
      }
      state.propertyEmails.pageSize = action.payload.pageSize;
    },
    getPropertyEmailListSuccessAction: (
      state,
      action: PayloadAction<{ emailThreads: ReduxEmailThread[]; count: number }>,
    ) => {
      state.propertyEmails.loading = false;
      state.propertyEmails.loaded = true;
      state.propertyEmails.value = action.payload.emailThreads;
      state.propertyEmails.totalCount = action.payload.count;
    },
    getAssociationEmailListSuccessAction: (
      state,
      action: PayloadAction<{ emailThreads: ReduxEmailThread[]; count: number }>,
    ) => {
      state.associationEmails.loading = false;
      state.associationEmails.loaded = true;
      state.associationEmails.value = action.payload.emailThreads;
      state.associationEmails.totalCount = action.payload.count;
    },
    getEmailListFailAction: (state, action: PayloadAction<AssociationType | undefined>) => {
      if (action.payload) {
        state.associationEmails.loading = false;
        state.associationEmails.loaded = false;
      } else {
        state.propertyEmails.loading = false;
        state.propertyEmails.loaded = false;
      }
    },
    setSelectedEmailThreadAction: (state, action: PayloadAction<ReduxEmailThread>) => {
      state.selectedEmailThread.value = action.payload;
    },
    unsetSelectedEmailThreadAction: (state) => {
      state.selectedEmailThread.value = undefined;
    },
    setDetailedEmailInfoAction: (
      state,
      action: PayloadAction<{ recipients: EmailAssociationInfo[]; cc?: EmailAssociationInfo[] }>,
    ) => {
      state.detailedRecipientInfo.value = action.payload.recipients;
      state.detailedCcInfo.value = action.payload.cc;
    },
    setOpenEmailDialogAction: (state, action: PayloadAction<EmailType>) => {
      state.emailRecipientType = action.payload;
    },
    setClosedEmailDialogAction: (state) => {
      state.emailRecipientType = undefined;
      state.selectedAssociation = undefined;
      state.detailedRecipientInfo.value = undefined;
      state.detailedCcInfo.value = undefined;
    },
    setSelectedAssociationAction: (
      state,
      action: PayloadAction<{
        associationType?: AssociationType;
        associatedId?: string;
        associationChildType?: AssociationChildType;
        childNumber?: string;
      }>,
    ) => {
      state.selectedAssociation = action.payload;
    },
    getEmailByIdAction: (state, action: PayloadAction<string>) => {
      state.selectedEmailThread.loading = true;
      state.selectedEmailThread.loaded = false;
    },
    getEmailByIdSuccessAction: (state, action: PayloadAction<ReduxEmailThread>) => {
      state.selectedEmailThread.loading = false;
      state.selectedEmailThread.loaded = true;
      state.selectedEmailThread.value = action.payload;
    },
    getEmailByIdFailAction: (state) => {
      state.selectedEmailThread.loading = false;
      state.selectedEmailThread.loaded = false;
    },
    addAssociationToEmailAction: (
      state,
      action: PayloadAction<{
        id: string;
        associatedId: string;
        associationType: AssociationType;
        associationChildType?: AssociationChildType;
        associationChildNumber?: string;
      }>,
    ) => {
      state.selectedEmailThread.submitting = true;
      state.selectedEmailThread.submitted = false;
    },
    addAssociationToEmailSuccessAction: (state) => {
      state.selectedEmailThread.submitting = false;
      state.selectedEmailThread.submitted = true;
    },
    addAssociationToEmailFailAction: (state) => {
      state.selectedEmailThread.submitting = false;
      state.selectedEmailThread.submitted = false;
    },
    removeAssociationFromEmailAction: (
      state,
      action: PayloadAction<{
        id: string;
        association: ICamAssociation;
      }>,
    ) => {
      state.selectedEmailThread.submitting = true;
      state.selectedEmailThread.submitted = false;
    },
    removeAssociationFromEmailSuccessAction: (state) => {
      state.selectedEmailThread.submitting = false;
      state.selectedEmailThread.submitted = true;
    },
    removeAssociationFromEmailFailAction: (state) => {
      state.selectedEmailThread.submitting = false;
      state.selectedEmailThread.submitted = false;
    },
    markEmailThreadReadAction: (state, action: PayloadAction<string>) => {},
    markEmailThreadReadSuccessAction: (state, action: PayloadAction<string>) => {
      const propertyUpdatedEmails = state.propertyEmails?.value?.map((x) => {
        if (x.id === action.payload) {
          return {
            ...x,
            emails: x.emails.map((e) => ({
              ...e,
              isRead: true,
            })),
          };
        }
        return x;
      });
      state.propertyEmails.value = propertyUpdatedEmails;
      const associationUpdatedEmails = state.associationEmails?.value?.map((x) => {
        if (x.id === action.payload) {
          return {
            ...x,
            emails: x.emails.map((e) => ({
              ...e,
              isRead: true,
            })),
          };
        }
        return x;
      });
      state.associationEmails.value = associationUpdatedEmails;
    },
    markEmailThreadReadFailAction: (state) => {},
    addAttachmentToAssociatedAssetAction: (
      state,
      action: PayloadAction<{
        threadId: string;
        emailId: string;
        attachmentId: string;
        association: ICamAssociation;
      }>,
    ) => {},
    addAttachmentToAssociatedAssetSuccessAction: (state) => {},
    addAttachmentToAssociatedAssetFailAction: (state) => {},
    listSmsThreadsAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        associationType?: AssociationType;
        associationId?: string;
      }>,
    ) => {
      state.smsThreads.loading = true;
      state.smsThreads.loaded = false;

      state.smsMessages.value = undefined;
      state.smsMessages.loaded = false;
      state.smsMessages.loading = false;
    },
    listSmsThreadsSuccessAction: (state, action: PayloadAction<{ threads: SmsThreadDto[] }>) => {
      state.smsThreads.loading = false;
      state.smsThreads.loaded = true;
      state.smsThreads.value = action.payload.threads?.map((thread) => mapReduxSmsThread(thread));
    },
    listSmsThreadsFailAction: (state) => {
      state.smsThreads.loading = false;
      state.smsThreads.loaded = false;
    },
    setSelectedSmsThread: (state, action: PayloadAction<string>) => {
      state.selectedSmsThread = action.payload;
    },
    listSmsForThreadAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        phoneNumber: string;
        page: number;
        pageSize: number;
        appendToExistingSms: boolean;
      }>,
    ) => {
      state.smsMessages.loading = true;
      state.smsMessages.loaded = false;
    },
    listSmsForThreadSuccessAction: (
      state,
      action: PayloadAction<{ messages: ReduxSms[]; total: number; appendToExistingSms: boolean }>,
    ) => {
      state.smsMessages.loading = false;
      state.smsMessages.loaded = true;

      if (action.payload.appendToExistingSms) {
        state.smsMessages.value = [...action.payload.messages, ...(state.smsMessages.value ?? [])];
      } else {
        state.smsMessages.value = action.payload.messages;
      }
      state.smsMessages.totalCount = action.payload.total;
    },
    newSmsArrivedAction: (state, action: PayloadAction<{ sms: ReduxSms }>) => {
      const phoneNumber = action.payload.sms.phoneNumber;
      // update unread count for phone number thread
      if (state.smsThreads.value == null) return;
      const thread = state.smsThreads.value.find((t) => t.phoneNumber == phoneNumber);
      if (thread == null) return;
      thread.anyUnread = true;
      thread.unreadSmsCount!++;
      thread.smsCount!++;

      // if matching phone number is currently selected, update message
      if (state.selectedSmsThread == phoneNumber) {
        const messages = state.smsMessages.value ?? [];
        messages.push(action.payload.sms);
        state.smsMessages.totalCount!++;
        state.smsMessages.value = messages;
      }
    },
    listSmsForThreadFailAction: (state) => {
      state.smsMessages.loading = false;
      state.smsMessages.loaded = false;
    },
    sendSmsToTenantAction: (
      state,
      action: PayloadAction<{ propertyId: string; phoneNumber: string; message: string }>,
    ) => {
      state.currentSmsMessage.loading = true;
      state.currentSmsMessage.value = action.payload.message;
    },
    sendSmsToTenantSuccessAction: (state, action: PayloadAction<{ newSms: ReduxSms }>) => {
      state.smsMessages.value?.push(action.payload.newSms);
      state.currentSmsMessage.loading = false;
      state.currentSmsMessage.loaded = true;
      state.currentSmsMessage.value = '';
    },
    sendSmsToTenantFailAction: (state) => {
      state.currentSmsMessage.loading = false;
      state.currentSmsMessage.loaded = false;
    },
    markSmsReadAction: (
      state,
      action: PayloadAction<{ propertyId: string; phoneNumber: string; smsIds: string[] }>,
    ) => {},
    markSmsReadSuccessAction: (state, action: PayloadAction<{ phoneNumber: string; unreadCount: number }>) => {
      if (state.smsThreads.value) {
        const updatedThreadIndex = state.smsThreads.value!.findIndex(
          (x) => x.phoneNumber === action.payload.phoneNumber,
        );

        state.smsThreads.value[updatedThreadIndex].unreadSmsCount = action.payload.unreadCount;
        state.smsThreads.value[updatedThreadIndex].anyUnread = action.payload.unreadCount > 0;
      }
    },
    resetEmailSubmissionAction: (state) => {
      state.associationEmails.submitting = false;
      state.associationEmails.submitted = false;
      state.propertyEmails.submitting = false;
      state.propertyEmails.submitted = false;
      state.propertyEmails.loading = false;
      state.propertyEmails.loading = false;
      state.associationEmails.loading = false;
      state.associationEmails.loading = false;
      state.selectedEmailThread.submitting = false;
      state.selectedEmailThread.submitted = false;
    },
    updateEmailAssociationStatusesFromTaskAction: (state, action: PayloadAction<{ task: ITaskDto }>) => {
      if (!state.propertyEmails.value) return;

      for (const email of state.propertyEmails.value) {
        const associations = [...(email.associations ?? [])];

        for (const association of associations ?? []) {
          if (
            association.associationType !== AssociationType.Task ||
            association.associatedId !== action.payload.task.id
          )
            continue;

          association.label = getUpdatedTaskAssociationLabel(action.payload.task);
          association.status = getUpdatedTaskAssociationStatus(action.payload.task);
        }

        const updatedEmail = { ...email, associations: associations };

        state.propertyEmails.value = state.propertyEmails.value.map((x) => {
          if (x.id === email.id) return updatedEmail;
          return x;
        });
      }
      if (!state.associationEmails.value) return;
      for (const email of state.associationEmails.value) {
        const associations = [...(email.associations ?? [])];

        for (const association of associations ?? []) {
          if (
            association.associationType !== AssociationType.Task ||
            association.associatedId !== action.payload.task.id
          )
            continue;

          association.label = getUpdatedTaskAssociationLabel(action.payload.task);
          association.status = getUpdatedTaskAssociationStatus(action.payload.task);
        }

        const updatedEmail = { ...email, associations: associations };

        state.associationEmails.value = state.associationEmails.value.map((x) => {
          if (x.id === email.id) return updatedEmail;
          return x;
        });
      }
    },
    attachmentAddedToAssociationAction: (state, action: PayloadAction<AssociationType | undefined>) => {
      state.attachmentAddedToAssociation = action.payload;
    },
    addWatcherToEmailThreadAction: (state, action: PayloadAction<{ threadId: string; userId: string }>) => {
      state.selectedEmailThread.submitting = true;
      state.selectedEmailThread.submitted = false;
    },
    removeWatcherFromEmailThreadAction: (state, action: PayloadAction<{ threadId: string; userId: string }>) => {
      state.selectedEmailThread.submitting = true;
      state.selectedEmailThread.submitted = false;
    },
    updateWatchersForEmailThreadSuccessAction: (state, action: PayloadAction<{ updatedThread: ReduxEmailThread }>) => {
      state.selectedEmailThread.submitting = false;
      state.selectedEmailThread.submitted = true;

      state.selectedEmailThread.value = {
        ...state.selectedEmailThread.value,
        emails: state.selectedEmailThread.value?.emails ?? [],
        watchers: action.payload.updatedThread.watchers ?? [],
      };
    },
    updateWatchersForEmailThreadFailAction: (state) => {
      state.selectedEmailThread.submitting = false;
      state.selectedEmailThread.submitted = false;
    },
    unselectEmailThreadAction: (state) => {
      state.selectedEmailThread.value = undefined;
    },
  },
});

export const {
  newEmailThreadArrivedAction,
  newEmailArrivedAction,
  sendEmailFromPropertyAction,
  sendEmailFromPropertyFailAction,
  sendEmailFromPropertySuccessAction,
  getEmailListAction,
  getEmailListFailAction,
  getPropertyEmailListSuccessAction,
  getAssociationEmailListSuccessAction,
  setSelectedEmailThreadAction,
  unsetSelectedEmailThreadAction,
  setDetailedEmailInfoAction,
  setClosedEmailDialogAction,
  setOpenEmailDialogAction,
  setSelectedAssociationAction,
  getEmailByIdAction,
  getEmailByIdFailAction,
  getEmailByIdSuccessAction,
  addAssociationToEmailAction,
  addAssociationToEmailFailAction,
  addAssociationToEmailSuccessAction,
  removeAssociationFromEmailAction,
  removeAssociationFromEmailFailAction,
  removeAssociationFromEmailSuccessAction,
  markEmailThreadReadAction,
  markEmailThreadReadFailAction,
  markEmailThreadReadSuccessAction,
  addAttachmentToAssociatedAssetAction,
  addAttachmentToAssociatedAssetFailAction,
  addAttachmentToAssociatedAssetSuccessAction,
  listSmsThreadsAction,
  listSmsThreadsSuccessAction,
  listSmsThreadsFailAction,
  setSelectedSmsThread,
  listSmsForThreadAction,
  listSmsForThreadSuccessAction,
  listSmsForThreadFailAction,
  newSmsArrivedAction,
  sendSmsToTenantAction,
  sendSmsToTenantSuccessAction,
  sendSmsToTenantFailAction,
  markSmsReadAction,
  markSmsReadSuccessAction,
  resetEmailSubmissionAction,
  updateEmailAssociationStatusesFromTaskAction,
  attachmentAddedToAssociationAction,
  addWatcherToEmailThreadAction,
  removeWatcherFromEmailThreadAction,
  updateWatchersForEmailThreadSuccessAction,
  updateWatchersForEmailThreadFailAction,
  unselectEmailThreadAction,
} = communicationSlice.actions;

export const useCommunication = () => useSelector((state: RootState) => state.communication);
