import React, { FC, FormEvent, useEffect, useState } from 'react';
import { DialogLayout } from '../../../dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import {
  resetEmailSubmissionAction,
  sendEmailFromPropertyAction,
  setClosedEmailDialogAction,
  useCommunication,
} from '../../redux/communicationSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { EmailAssociationInfo, EmailType, ReplyRecipientType } from '../../redux/communicationTypes';
import { AddAttachments } from '../AddAttachments';
import { EmailAddressAutocomplete } from './EmailAddressAutocomplete';
import Typography from '@mui/material/Typography';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

export const SendEmailDialog: FC = () => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const {
    associationEmails,
    propertyEmails,
    detailedRecipientInfo,
    detailedCcInfo,
    emailRecipientType,
    selectedEmailThread,
    selectedAssociation,
  } = useCommunication();
  const [subject, setSubject] = useState(selectedEmailThread.value?.subject ?? '');
  const [body, setBody] = useState('');
  const [recipients, setRecipients] = useState<EmailAssociationInfo[]>([]);
  const [recipientOptions, setRecipientOptions] = useState<EmailAssociationInfo[]>([]);
  const [ccs, setCcs] = useState<EmailAssociationInfo[]>([]);
  const [ccOptions, setCcOptions] = useState<EmailAssociationInfo[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);

  const handleAddAttachment = (file: File) => {
    setAttachments([...attachments, file]);
  };

  const handleDeleteAttachment = (file: File) => {
    setAttachments(attachments.filter((x) => x !== file));
  };

  useEffect(() => {
    setSubject(selectedEmailThread.value?.subject ?? '');
  }, [selectedEmailThread.value]);

  useEffect(() => {
    switch (emailRecipientType) {
      case EmailType.Bulk:
      case EmailType.SelectedLeaseOrApplicant:
        setRecipients(detailedRecipientInfo?.value ?? []);
        setRecipientOptions(detailedRecipientInfo?.value ?? []);
        setCcs(detailedCcInfo.value ?? []);
        setCcOptions(detailedCcInfo?.value ?? []);
        break;
      case EmailType.Reply:
        setRecipients(detailedRecipientInfo.value?.filter((x) => x.replyType !== ReplyRecipientType.Removed) ?? []);
        setRecipientOptions(detailedRecipientInfo?.value ?? []);
        setCcs(detailedCcInfo.value?.filter((x) => x.replyType !== ReplyRecipientType.Removed) ?? []);
        setCcOptions(detailedCcInfo?.value ?? []);
        break;
      default:
        setRecipients([]);
        setRecipientOptions([]);
        setCcs([]);
        setCcOptions([]);
    }
  }, [emailRecipientType, detailedRecipientInfo?.value]);

  const updateRecipientValues = (value: string[], cc?: boolean) => {
    let emailArray = cc ? [...ccs] : [...recipients];
    const emailsSet = cc ? new Set(ccs.map((c) => c.label)) : new Set(recipients.map((r) => r.label));
    // set each value count
    const emailCounts = value.reduce(
      (acc, email) => {
        acc[email] = (acc[email] || 0) + 1;
        return acc;
      },
      {} as Record<string, number>,
    );
    value.forEach((email) => {
      if (!emailsSet.has(email)) {
        const optionEmail = cc
          ? ccOptions.find((x) => x.label === email)
          : recipientOptions.find((x) => x.label === email);
        if (optionEmail) {
          emailArray.push(optionEmail);
        } else {
          emailArray.push({
            emailAddresses: [email],
            label: email,
            associationType: selectedAssociation?.associationType,
            associatedId: selectedAssociation?.associatedId,
            childType: selectedAssociation?.associationChildType,
            childNumber: selectedAssociation?.childNumber,
          });
        }
        emailsSet.add(email); // Add the new email to the set
      }
    });
    // Remove extra recipients
    emailArray = emailArray.filter((obj) => {
      if (emailCounts[obj.label] && emailCounts[obj.label] > 0) {
        emailCounts[obj.label] -= 1;
        return true;
      }
      return false;
    });
    if (cc) {
      setCcs(emailArray);
    } else setRecipients(emailArray);
  };

  const handleClose = () => {
    dispatch(setClosedEmailDialogAction());
    setSubject('');
    setBody('');
    setRecipientOptions([]);
    setRecipients([]);
    setCcOptions([]);
    setCcs([]);
    setAttachments([]);
  };

  useEffect(() => {
    if (propertyEmails.submitted || associationEmails.submitted) handleClose();
    dispatch(resetEmailSubmissionAction());
  }, [propertyEmails.submitted, associationEmails.submitted]);

  const handleSubmitEmails = (e: FormEvent) => {
    e.preventDefault();
    const ccEmails = ccs.flatMap((e) => e.emailAddresses);
    if (emailRecipientType === EmailType.Bulk) {
      recipients.forEach((r) => {
        selectedContext.propertyId &&
          dispatch(
            sendEmailFromPropertyAction({
              propertyId: selectedContext.propertyId,
              associationType: r.associationType,
              associatedId: r.associatedId,
              childNumber: r.childNumber,
              associationChildType: r.childType,
              subject,
              emailBody: body,
              receiverEmails: r.emailAddresses,
              cc: ccEmails,
              files: attachments,
            }),
          );
      });
    } else {
      const receiverEmails = recipients.flatMap((r) => r.emailAddresses);
      if (receiverEmails.length < 1) {
        dispatch(
          showToastMessageAction({
            message: 'At least one recipient must be specified',
            severity: 'warning',
          }),
        );
      } else
        selectedContext.propertyId &&
          dispatch(
            sendEmailFromPropertyAction({
              propertyId: selectedContext.propertyId,
              associationType: selectedAssociation?.associationType,
              associatedId: selectedAssociation?.associatedId,
              childNumber: selectedAssociation?.childNumber,
              associationChildType: selectedAssociation?.associationChildType,
              subject,
              emailBody: body,
              receiverEmails: receiverEmails,
              cc: ccEmails,
              files: attachments,
            }),
          );
    }
  };

  return (
    <DialogLayout
      title={emailRecipientType === EmailType.Bulk ? 'Bulk Message' : 'New Message'}
      onClose={handleClose}
      open={emailRecipientType !== undefined}
    >
      <form onSubmit={handleSubmitEmails}>
        <DialogContent>
          <Stack pt={1} spacing={1}>
            {emailRecipientType === EmailType.Bulk && recipientOptions.length > 0 ? (
              <Typography>
                When sending a bulk message to selected recipients, each selection will receive a separate email.
              </Typography>
            ) : emailRecipientType === EmailType.Bulk && recipientOptions.length <= 0 ? (
              <Typography>
                Please select recipients from the table before composing a bulk email. Adding unselected recipients
                within this dialog is not enabled.
              </Typography>
            ) : (
              <></>
            )}
            <EmailAddressAutocomplete
              renderInput={(params) => <TextField {...params} variant="standard" label="Recipients" />}
              options={recipientOptions}
              recipients={recipients}
              onUpdateRecipients={updateRecipientValues}
              emailRecipientType={emailRecipientType}
              isCc={false}
            />
            <EmailAddressAutocomplete
              recipients={ccs}
              onUpdateRecipients={updateRecipientValues}
              renderInput={(params) => <TextField {...params} variant="standard" label="CC" />}
              options={ccOptions}
              isCc={true}
            />
            <TextField
              variant={'outlined'}
              value={subject ?? ''}
              label={'Subject:'}
              onChange={(e) => setSubject(e.target.value)}
              inputProps={{
                readOnly: emailRecipientType == EmailType.Reply ? true : false,
              }}
            />
            <TextField
              variant={'outlined'}
              multiline
              rows={5}
              label={'Message:'}
              value={body ?? ''}
              onChange={(e) => setBody(e.target.value)}
            />
            <AddAttachments
              attachments={attachments}
              onAddAttachment={handleAddAttachment}
              onDeleteAttachment={handleDeleteAttachment}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton
            type={'submit'}
            variant={'contained'}
            loading={propertyEmails.submitting || associationEmails.submitting}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
