import React, { FC } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { MediaObject } from './MediaObject';
import { ReduxSmsThread } from '../redux/communicationTypes';

export type SmsMessagesProps = {
  setSelectedSmsThread: (phoneNumber: string) => void;
  smsThreads?: ReduxSmsThread[];
  currentSelectedThreadId?: string;
};

export const SmsMessages: FC<SmsMessagesProps> = ({ setSelectedSmsThread, smsThreads, currentSelectedThreadId }) => {
  const initials = (thread: ReduxSmsThread) => {
    if (thread.firstName && thread.firstName.length > 0 && thread.lastName && thread.lastName.length)
      return `${thread.firstName ? thread.firstName[0] : ''}${thread.lastName ? thread.lastName[0] : ''}`;

    return '';
  };

  return (
    <>
      {smsThreads && (
        <div
          style={{
            minWidth: '20%',
            maxWidth: '30%',
            maxHeight: 500,
            overflowY: 'scroll',
            overflowX: 'hidden',
            border: '.1rem solid gray',
          }}
        >
          <List sx={{ width: '100%', backgroundColor: 'background.paper' }}>
            {smsThreads.map((r) => {
              if (!r.phoneNumber) return <></>;
              return (
                <div key={r.phoneNumber}>
                  <MediaObject
                    onMediaObjectSelection={setSelectedSmsThread}
                    id={r.phoneNumber!}
                    initials={initials(r)}
                    primaryText={r.firstName + ' ' + r.lastName}
                    secondaryText1={r.phoneNumber}
                    secondaryText2={r.unreadSmsCount && r.unreadSmsCount > 0 ? r.unreadSmsCount.toString() : undefined}
                    anyUnread={r.anyUnread}
                    isSelected={r.phoneNumber === currentSelectedThreadId}
                  />
                  <Divider variant="inset" component="li" />
                </div>
              );
            })}
          </List>
        </div>
      )}
    </>
  );
};
