import React, { FC } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import Card from '@mui/material/Card';
import { appTheme } from '../../../AppTheme';
import { CSSObject } from '@mui/material/styles';

type DraggableComponentProps = {
  id: string;
  index: number;
  children: React.ReactNode;
  customItemStyle?: (style?: any, isDragging?: boolean) => CSSObject;
};

export const DraggableComponent: FC<DraggableComponentProps> = ({ id, index, children, customItemStyle }) => {
  const getItemStyle = (
    draggableStyle: any,
    isDragging: boolean,
    customStyleFunc?: (draggableStyle: any, isDragging: boolean) => void,
  ) => {
    const baseStyle = {
      userSelect: 'none',
      background: isDragging ? appTheme.palette.primary.light : 'background.paper',
      ...draggableStyle,
      my: '1rem',
    };

    // Merge custom styles if provided
    const customStyles = customStyleFunc ? customStyleFunc(draggableStyle, isDragging) : {};
    return {
      ...baseStyle,
      ...customStyles,
    };
  };

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided, snapshotDraggable) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={getItemStyle(provided.draggableProps.style, snapshotDraggable.isDragging, customItemStyle)}
        >
          {children}
        </Card>
      )}
    </Draggable>
  );
};
