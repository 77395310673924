import React, { FC } from 'react';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import ListItem from '@mui/material/ListItem';
import { ImagePreviewComponent } from './ImagePreviewComponent';
import Typography from '@mui/material/Typography';
import { getFileExtension } from '../../utils/getFileExtension';
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import IconButton from '@mui/material/IconButton';
import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material';
import { DocumentInformation } from './DocumentInformation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import Link from '@mui/material/Link';
import { ICamAssociation } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { downloadFileUtils } from '../../utils/DownloadFileUtils';
import { useIcons } from '../../icons/useIcons';

export type DocumentLinkProps = {
  propertyId?: string;
  association: ICamAssociation;
  document: ReduxDocument;
  showAsIcons?: boolean;
  onDeleteDocument?: (id: string) => void;
};

export const DocumentLink: FC<DocumentLinkProps> = ({
  propertyId,
  association,
  document,
  onDeleteDocument,
  showAsIcons,
}) => {
  const { getActionIcon, ActionType } = useIcons();
  const highlightedDocId = new URLSearchParams(location.search).get('selected');
  const docType = getFileExtension(document.name);
  const theme = useTheme();

  const styles = {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  };

  const handleDownloadClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      !propertyId ||
      association.associationType === undefined ||
      association.associatedId === undefined ||
      document.id === undefined
    )
      return;
    const fileResponse = await ApiClientSingleton.getInstance().documents_DownloadFile(
      propertyId,
      association.associationType,
      association.associatedId,
      document.id,
      association.associationChildType,
      association.associationChildNumber,
    );
    downloadFileUtils(fileResponse.data, fileResponse.fileName ?? document.name ?? 'no name');
  };

  return (
    <>
      {showAsIcons ? (
        <ImagePreviewComponent imageUrl={document.uri} docType={docType}>
          <Tooltip title={document.name ?? ''} placement={'top-start'}>
            <Link href={document.uri} download={document.name} onClick={handleDownloadClick}>
              <IconButton>
                <InsertDriveFileSharpIcon />
              </IconButton>
            </Link>
          </Tooltip>
        </ImagePreviewComponent>
      ) : typeof association.associatedId === undefined || !association.associatedId ? (
        <ListItem
          key={document.uri}
          sx={{
            backgroundColor: highlightedDocId === document.id ? theme.palette.warning.main : 'inherit',
            borderRadius: 1,
          }}
        >
          <Link href={document.uri} download={document.name}>
            <Typography style={styles}>{document.name}</Typography>
          </Link>
        </ListItem>
      ) : (
        <ListItem
          key={document.id}
          sx={{
            width: '100%',
            backgroundColor: highlightedDocId === document.id ? theme.palette.warning.main : 'inherit',
            borderRadius: 1,
          }}
        >
          <Accordion sx={{ width: '100%', border: '.1rem solid gray' }} elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${document.name}-content`}
              id={`${document.name}-header`}
            >
              <DownloadIcon sx={styles} />
              <ImagePreviewComponent imageUrl={document.uri} docType={docType}>
                {typeof propertyId === 'undefined' && <Typography>{document.name}</Typography>}
                {propertyId && typeof association.associationChildType !== 'undefined' ? (
                  <Link
                    href={`${process.env.REACT_APP_API_URL}/properties/${propertyId}/${association.associationType}/${association.associatedId}/${association.associationChildType}${association.associationChildNumber}/documents/${document.id}?childAssociationType=${association.associationChildType}&childAssociationId=${association.associationChildNumber}`}
                    onClick={handleDownloadClick}
                  >
                    {document.name}
                  </Link>
                ) : (
                  <Link
                    href={`${process.env.REACT_APP_API_URL}/properties/${propertyId}/${association.associationType}/${association.associatedId}/documents/${document.id}`}
                    onClick={handleDownloadClick}
                  >
                    {document.name}
                  </Link>
                )}
              </ImagePreviewComponent>
              <Box display={'flex'} flexGrow={1}></Box>
              {onDeleteDocument && (
                <Tooltip title={'Delete Document'}>
                  <IconButton
                    size={'small'}
                    onClick={(e) => {
                      e.stopPropagation();
                      document.id && onDeleteDocument(document.id);
                    }}
                  >
                    {getActionIcon(ActionType.Delete, { fontSize: 'small' })}
                  </IconButton>
                </Tooltip>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <DocumentInformation document={document} association={association} />
            </AccordionDetails>
          </Accordion>
        </ListItem>
      )}
    </>
  );
};
