import {
  AssetType,
  DetailAssociationType,
  DetailStatus,
  IDetail,
  IDetailDto,
  IDetailObservation,
  IFollowUpNote,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  mapReduxDocument,
  ReduxDate,
  ReduxDocument,
  toReduxDate,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { formatISO, isDate } from 'date-fns';
import { PageableCollection } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';

export type GroupedDetailLists = {
  [id: string]: PageableCollection<ReduxDetail> & { propertyId?: string; associationType?: DetailAssociationType };
};

export type ReduxFollowUpNote = Omit<IFollowUpNote, 'createdOn'> & {
  createdOn?: ReduxDate;
};

export type ReduxDetailObservation = Omit<IDetailObservation, 'followUpNotes' | 'createdOn' | 'attachments'> & {
  followUpNotes?: ReduxFollowUpNote[];
  createdOn?: string;
  createdOnLabel?: string;
  attachments?: ReduxDocument[];
};

export type ReduxDetail = Omit<IDetailDto, 'observations' | 'dateInstalled' | 'subDetails' | 'attachments'> & {
  observations?: ReduxDetailObservation[];
  dateInstalled?: ReduxDate;
  subDetails?: ReduxDetail[];
  attachments?: ReduxDocument[];
};

export function isDetail(detail: IDetail | IDetailDto): detail is IDetail | IDetailDto {
  return 'subDetails' in detail;
}

export function mapReduxDetail(detail: IDetail | IDetailDto): ReduxDetail {
  return {
    ...detail,
    id: detail.id?.toString(),
    observations: detail.observations?.map(mapReduxDetailObservation) ?? [],
    dateInstalled: detail.dateInstalled ? toReduxDate(detail.dateInstalled) : undefined,
    attachments: detail.attachments?.map((x) => mapReduxDocument(x)),
    subDetails: [],
  };
}

export function mapReduxDetailObservation(observation: IDetailObservation): ReduxDetailObservation {
  return {
    ...observation,
    followUpNotes: observation.followUpNotes?.map(mapReduxDetailFollowUpNote) ?? [],
    createdOn: isDate(observation.createdOn!) ? formatISO(observation.createdOn!) : observation.createdOn!.toString(),
    createdOnLabel: toReduxDate(observation.createdOn!),
    attachments: observation.attachments?.map((x) => mapReduxDocument(x)),
  };
}

export function mapReduxDetailFollowUpNote(followUpNote: IFollowUpNote): ReduxFollowUpNote {
  return {
    ...followUpNote,
    createdOn: !!followUpNote.createdOn ? toReduxDate(followUpNote.createdOn) : undefined,
  };
}

export const mapDetailStatusToDisplayName = (status: DetailStatus): string => {
  switch (status) {
    case DetailStatus.BeingInstalled:
      return 'Being Installed';
    default:
      return status;
  }
};

export function mapAssetTypeFromDetailAssociationType(
  associationType: DetailAssociationType | undefined,
): AssetType | undefined {
  if (associationType === undefined) return;

  switch (associationType) {
    case DetailAssociationType.BuildingUnit:
      return AssetType.BuildingUnit;
    case DetailAssociationType.Building:
      return AssetType.Building;
    case DetailAssociationType.RentalProperty:
      return AssetType.RentalProperty;
    default:
      return undefined;
  }
}

export function mapDetailAssociationTypeFromAssetType(assetType: AssetType): DetailAssociationType {
  switch (assetType) {
    case AssetType.BuildingUnit:
      return DetailAssociationType.BuildingUnit;
    case AssetType.Building:
      return DetailAssociationType.Building;
    default:
      return DetailAssociationType.RentalProperty;
  }
}

export function mapDetailAssociationTypeFromAssetPath(
  assetPath: 'property' | 'building' | 'unit',
): DetailAssociationType {
  switch (assetPath) {
    case 'property':
      return DetailAssociationType.RentalProperty;
    case 'building':
      return DetailAssociationType.Building;
    case 'unit':
      return DetailAssociationType.BuildingUnit;
  }
  return DetailAssociationType.RentalProperty;
}
