import React, { FC } from 'react';
import { CustomTreeItemContent } from './customTreeItemContent/customTreeItemContent';
import { IAssetNode } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { BuildingTreeItem } from './BuildingTreeItem';
import { StyledTreeItem } from './StyledTreeItem';
import { IsCamProperty } from '../../../../../app/constants';
import { TreeItemProps } from '@mui/x-tree-view';
import { convertAssetTypeToAssetNodeType } from '../../../../_shared/paymentProcessing/authorizeNet/helpers';

type PropertyItemProps = Omit<TreeItemProps, 'itemId'> & {
  value: IAssetNode;
  onOpenBuilding: () => void;
  onOpenUnit: () => void;
  onOpenRoom: () => void;
};

export const PropertyTreeItem: FC<PropertyItemProps> = ({
  value,
  onOpenBuilding,
  onOpenUnit,
  onOpenRoom,
  ...props
}) => {
  if (value && value.id) {
    return (
      <StyledTreeItem
        ContentComponent={CustomTreeItemContent}
        ContentProps={
          {
            assetName: convertAssetTypeToAssetNodeType(value.assetNodeType),
            value: value,
            onOpen: onOpenBuilding,
            isSimulated: value.isSimulated,
            isArchived: value.isArchived,
            isCamProperty: IsCamProperty(value.id),
          } as any
        }
        key={value.id}
        itemId={value.id}
        label={value.name}
        {...props}
      >
        {value.children?.map((b) => (
          <BuildingTreeItem key={b.id} value={b} onOpenUnit={onOpenUnit} onOpenRoom={onOpenRoom} />
        ))}
      </StyledTreeItem>
    );
  } else {
    return <></>;
  }
};
