import { GridToolbarContainer, GridToolbarProps } from '@mui/x-data-grid-premium';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { openNewTenantDialogAction, setEditingLeaseAction } from '../leases/redux/leasesSlice';
import { useDispatch } from 'react-redux';
import { LeaseDispatchType } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import { EmailAssociationInfo, EmailType } from '../communications/redux/communicationTypes';
import {
  setDetailedEmailInfoAction,
  setOpenEmailDialogAction,
  setSelectedAssociationAction,
} from '../communications/redux/communicationSlice';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type TenantToolbarProps = {
  leaseId: string;
  leaseType: LeaseDispatchType;
  recipients: EmailAssociationInfo[];
} & GridToolbarProps;

export const TenantToolbar = ({ leaseId, leaseType, recipients }: TenantToolbarProps) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setEditingLeaseAction({ leaseId: leaseId, leaseType: leaseType }));
    dispatch(openNewTenantDialogAction());
  };

  const handleBulkEmails = () => {
    dispatch(setDetailedEmailInfoAction({ recipients: recipients }));
    dispatch(setSelectedAssociationAction({ associatedId: leaseId, associationType: AssociationType.Lease }));
    dispatch(setOpenEmailDialogAction(EmailType.SelectedLeaseOrApplicant));
  };

  return (
    <GridToolbarContainer sx={{ backgroundColor: 'lightgray', pb: '.2rem' }}>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add Tenant
      </Button>
      <Box sx={{ display: 'flex', flexGrow: 1 }}></Box>
      <Tooltip title={'Email Tenant(s)'}>
        <IconButton onClick={() => handleBulkEmails()}>
          <EmailIcon />
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  );
};
