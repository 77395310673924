import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { tryFormatDate } from '../../_shared/utils/TryFormatDate';
import { ReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Skeleton from '@mui/material/Skeleton';
import { setSelectedLeaseAction, useTenant } from '../redux/tenantSlice';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

export type LeaseSelectOption = {
  leaseId: string;
  label: string;
  termStartDate?: ReduxDate;
};

export const SelectUnitAndLease: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { leaseId } = useParams();
  const { leases } = useTenant();
  const [unitLeasePeriodOptions, setUnitLeasePeriodOptions] = useState<LeaseSelectOption[]>([]);
  const [selectedUnitLeasePeriod, setSelectedUnitLeasePeriod] = useState<string | undefined>();

  useEffect(() => {
    leases.value && createUnitLeaseSelectOptions();
  }, [leases.value]);

  useEffect(() => {
    if (unitLeasePeriodOptions.length === 0) return;
    if (leaseId) {
      setSelectedUnitLeasePeriod(leaseId);
    } else if (leases.selectedValue) {
      setSelectedUnitLeasePeriod(unitLeasePeriodOptions.find((o) => o.leaseId === leases.selectedValue?.id)?.leaseId);
      navigate(`/tenantPortal/${leases.selectedValue?.id}`);
    } else initializeSelectedLeaseToMostRecent(unitLeasePeriodOptions);
  }, [unitLeasePeriodOptions]);

  useEffect(() => {
    const selection: LeaseSelectOption | undefined = unitLeasePeriodOptions.find(
      (option) => option.leaseId === selectedUnitLeasePeriod,
    );
    if (!selection) return;
    const selectedLease = leases.value?.find((lease) => lease.id === selection.leaseId);
    dispatch(setSelectedLeaseAction(selectedLease?.id));
  }, [selectedUnitLeasePeriod]);

  const initializeSelectedLeaseToMostRecent = (leaseOptions: LeaseSelectOption[]) => {
    let mostRecentLease: LeaseSelectOption | undefined;
    leaseOptions.map((lease) => {
      !mostRecentLease ||
      new Date(lease.termStartDate as string).toISOString() >
        new Date(mostRecentLease.termStartDate as string).toISOString()
        ? (mostRecentLease = lease)
        : (mostRecentLease = mostRecentLease);
    });
    mostRecentLease && setSelectedUnitLeasePeriod(mostRecentLease.leaseId);
    navigate(`/tenantPortal/${mostRecentLease?.leaseId}`);
  };

  const createUnitLeaseSelectOptions = () => {
    const options: LeaseSelectOption[] = [];
    leases.value?.map((lease) => {
      const selectString = `${lease.propertyName} / ${lease.unitName} / ${
        lease.startDate ? tryFormatDate(lease.startDate) : ''
      } - ${lease.endDate ? tryFormatDate(lease.endDate) : ''}`;
      lease.id &&
        options.push({
          leaseId: lease.id,
          label: selectString,
          termStartDate: lease.startDate,
        });
    });
    setUnitLeasePeriodOptions(options);
  };
  if (leases.loading) return <Skeleton variant={'rectangular'} animation={'wave'} width={'100%'} height={40} />;
  return (
    <Box sx={{ maxWidth: '100%' }}>
      <FormControl variant={'standard'} fullWidth>
        <InputLabel id="unit-and-lease-period" aria-label={'unit-and-lease-period'}>
          Select Unit / Lease Period
        </InputLabel>
        <Select
          onChange={(e) => {
            setSelectedUnitLeasePeriod(e.target.value);
            navigate(`/tenantPortal/${e.target.value}`);
          }}
          aria-labelledby={'unit-and-lease-period'}
          value={selectedUnitLeasePeriod ?? ''}
        >
          {unitLeasePeriodOptions.map((o) => {
            return (
              <MenuItem key={o.leaseId} value={o.leaseId} sx={{ whiteSpace: 'normal' }}>
                {o.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
