import React, { FC } from 'react';
import { Grid, useTheme } from '@mui/material';
import { generateColors } from '../../../../_shared/utils/colorUtils';

const colors = generateColors(168);

type SelectColorProps = {
  selectedColor?: string;
  setSelectedColor: (color: string) => void;
};

export const SelectColor: FC<SelectColorProps> = ({ selectedColor, setSelectedColor }) => {
  const theme = useTheme();
  const handleColorSelect = (color: string) => {
    setSelectedColor(color);
  };

  return (
    <Grid container width={'40%'}>
      {colors.map((color) => (
        <Grid item xs={1} key={color}>
          <div
            onClick={() => handleColorSelect(color)}
            style={{
              backgroundColor: color,
              width: 30,
              height: 30,
              margin: 1,
              border: color === selectedColor ? '.2rem solid black' : 'none',
              borderRadius: theme.shape.borderRadius,
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
