import React, { FC, useEffect, useState } from 'react';
import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AddScheduledTaskDialog } from './AddScheduledTaskDialog';
import Stack from '@mui/material/Stack';
import { getAllCategoriesAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/categorySlice';
import { useDispatch } from 'react-redux';
import { getUsersForAssetAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { TaskSplitButton } from '../TaskSplitButton';
import { AddTaskType, ReduxScheduledTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import {
  deleteScheduledTaskAction,
  getScheduledTasksByAssetIdAction,
  resetSubmissionAction,
  updateScheduledTaskAction,
  useScheduledTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import { ScheduledTaskTable } from './ScheduledTaskTable';
import { getAutomationsByPropertyIdAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetParams } from '../../../../../AppRouter';
import { getAssetTypeFromPathname } from '../../../utils/getAssetTypeFromPathname';
import { ScheduledTaskTab } from '../../../tabs/TabTypeEnums';

export const ScheduledTasks: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedContext } = useAssets();
  const { selectedScheduledTask } = useScheduledTasks();
  const propertyId = selectedContext.propertyId;
  const { id, outerTab, innerTab } = useParams<AssetParams>();
  const assetId = id;
  const [openScheduledTaskModal, setOpenScheduledTaskModal] = useState(false);
  const [isSopAdd, setIsSopAdd] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const asset = getAssetTypeFromPathname();
  const assetType =
    asset === 'unit' ? AssetType.BuildingUnit : asset === 'building' ? AssetType.Building : AssetType.RentalProperty;
  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    propertyId &&
      assetId &&
      dispatch(
        getScheduledTasksByAssetIdAction({
          propertyId: propertyId,
          childAssetId: assetId,
          assetType: assetType,
          page,
          pageSize,
        }),
      );
  }, [page, pageSize, selectedScheduledTask.submitted]);

  useEffect(() => {
    selectedContext.propertyId &&
      dispatch(
        getAutomationsByPropertyIdAction({
          id: selectedContext.propertyId,
          page: undefined,
          pageSize: undefined,
          associationType: undefined,
          associatedId: undefined,
          includePropertyTasks: undefined,
        }),
      );
  }, []);

  const handleCloseAdd = () => {
    setOpenScheduledTaskModal(false);
    dispatch(resetSubmissionAction());
  };
  const handleOpenEdit = (scheduledTaskId: string) => {
    navigate(
      `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/scheduledTask/${scheduledTaskId}/${
        ScheduledTaskTab.Info
      }`,
    );
  };
  const handleCloseSopAdd = () => {
    setIsSopAdd(false);
    handleCloseAdd();
  };
  const handleOpenAddModal = (taskType: AddTaskType) => {
    setOpenScheduledTaskModal(true);
    taskType === AddTaskType.ScheduledSopTask && setIsSopAdd(true);
  };

  useEffect(() => {
    propertyId && dispatch(getAllCategoriesAction({ propertyId, includeDisabled: false }));
    assetId && dispatch(getUsersForAssetAction({ assetId, associationType: assetType }));
  }, []);

  const handleDeleteScheduledTask = (id: string) => {
    propertyId &&
      assetId &&
      dispatch(
        deleteScheduledTaskAction({
          id,
        }),
      );
  };

  const handleUpdateScheduledTask = (task: ReduxScheduledTask, taskId: string) => {
    dispatch(
      updateScheduledTaskAction({
        id: taskId,
        body: task,
      }),
    );
  };

  if (!assetId) return <></>;
  return (
    <StyledInfoBox label={'Scheduled Tasks'}>
      <Stack spacing={2}>
        <TaskSplitButton taskType={AddTaskType.NewScheduledTask} onOpenAdd={handleOpenAddModal} />
        <ScheduledTaskTable
          onOpenEdit={handleOpenEdit}
          onDeleteScheduledTask={handleDeleteScheduledTask}
          page={page}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          onUpdateScheduledTask={handleUpdateScheduledTask}
        />
        <AddScheduledTaskDialog
          open={openScheduledTaskModal}
          onClose={isSopAdd ? handleCloseSopAdd : handleCloseAdd}
          assetId={assetId}
          assetType={assetType}
          isSopAdd={isSopAdd}
          page={page}
          pageSize={pageSize}
        />
      </Stack>
    </StyledInfoBox>
  );
};
