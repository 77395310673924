import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AddTaskDialog } from './taskDialogs/AddTaskDialog';
import { GroupedTaskTable } from './taskTables/GroupedTaskTable';
import { IncludeClosedToggle } from './IncludeClosedToggle';
import { TaskListTable } from './taskTables/TaskListTable';
import {
  AssetType,
  GetGroupedTasksHandlerTasksGroupBy,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { getAllCategoriesAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/categorySlice';
import {
  getTaskGroupsAction,
  getUngroupedTasksByAssetIdAction,
  toggleIsGroupedAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { ShowChildAssetsToggle } from './ShowChildAssetsToggle';
import { getUsersForAssetAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { TaskSplitButton } from './TaskSplitButton';
import { AddTaskType } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import { useParams } from 'react-router-dom';
import { AssetParams } from '../../../../AppRouter';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';

export const AssetTasks: FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<AssetParams>();
  const assetId = id;
  const {
    selectedContext: { propertyId },
  } = useAssets();
  const [openAddTask, setOpenAddTask] = useState<AddTaskType>();
  const [showChildAssets, setShowChildAssets] = useState(false);
  const [includeClosed, setIncludeClosed] = useState(false);
  const asset = getAssetTypeFromPathname();
  const associationType =
    asset === 'unit' ? AssetType.BuildingUnit : asset === 'building' ? AssetType.Building : AssetType.RentalProperty;
  useEffect(() => {
    if (!assetId) return;
    dispatch(
      getUngroupedTasksByAssetIdAction({
        id: assetId,
        associationType: associationType,
        includeClosed: includeClosed,
        page: 0,
        pageSize: 10,
      }),
    );
    propertyId && dispatch(getAllCategoriesAction({ propertyId, includeDisabled: false }));
    dispatch(toggleIsGroupedAction({ isGrouped: false, groupedBy: undefined }));
    dispatch(getUsersForAssetAction({ assetId: assetId, associationType: associationType }));
  }, []);

  const handleOpenAddTask = (taskType: AddTaskType) => {
    setOpenAddTask(taskType);
  };

  const handleCloseAddTask = () => {
    setOpenAddTask(undefined);
  };

  const handleToggleIncludeClosed = (checked: boolean) => {
    setIncludeClosed(checked);
    !showChildAssets &&
      assetId &&
      dispatch(
        getUngroupedTasksByAssetIdAction({ id: assetId, associationType: associationType, includeClosed: checked }),
      );
  };

  const handleSwitchChildAssetToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked === true &&
      assetId &&
      dispatch(
        getTaskGroupsAction({
          assetType: associationType,
          assetId: assetId,
          groupBy: GetGroupedTasksHandlerTasksGroupBy.Asset,
          includeClosed: includeClosed,
        }),
      );
    dispatch(
      toggleIsGroupedAction({
        isGrouped: event.target.checked,
        groupedBy: event.target.checked ? GetGroupedTasksHandlerTasksGroupBy.Asset : undefined,
      }),
    );
    setShowChildAssets(event.target.checked);
  };
  if (!assetId) return <></>;
  return (
    <StyledInfoBox label={'Tasks'}>
      <Stack spacing={2}>
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
          <TaskSplitButton taskType={AddTaskType.NewTask} onOpenAdd={handleOpenAddTask} />
          <Box>
            {associationType !== AssetType.BuildingUnit && (
              <ShowChildAssetsToggle value={showChildAssets} onChange={handleSwitchChildAssetToggle} />
            )}
            <IncludeClosedToggle
              label={'Include Closed Tasks'}
              includeClosed={includeClosed}
              onToggleIncludeClosed={handleToggleIncludeClosed}
            />
          </Box>
        </Box>
        {showChildAssets ? (
          <GroupedTaskTable
            groupingType={GetGroupedTasksHandlerTasksGroupBy.Asset}
            associationType={associationType}
            assetId={assetId}
            includeClosed={includeClosed}
          />
        ) : (
          <TaskListTable
            associationType={associationType}
            parentRowId={assetId}
            includeClosed={includeClosed}
            forceColor
          />
        )}
        <AddTaskDialog
          open={openAddTask !== undefined}
          onClose={handleCloseAddTask}
          assetId={assetId}
          associationType={associationType}
          includeClosed={includeClosed}
          taskType={openAddTask}
        />
      </Stack>
    </StyledInfoBox>
  );
};
