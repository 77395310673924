import { CamTaskStatus, ITaskDto } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const getUpdatedTaskAssociationLabel = (task: ITaskDto) => {
  return task.name;
};
export const getUpdatedTaskAssociationStatus = (task: ITaskDto) => {
  if (task.status === CamTaskStatus.ConfirmedCompleted) return task.status;
  else if (typeof task.assignee === 'undefined' || !task.assignee) return 'unassigned';
  else if (typeof task.assignee !== 'undefined' && task.assignee && typeof task.assigneePriority !== 'number')
    return 'unprioritized';
  else return undefined;
};
