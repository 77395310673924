import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { TreeItemPopupMenu } from './treeItemPopupMenu';
import Box from '@mui/material/Box';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import FoundationOutlinedIcon from '@mui/icons-material/FoundationOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import { appTheme, Persimmon } from '../../../../../../AppTheme';
import { AssetTab } from '../../../../../_shared/tabs/TabTypeEnums';
import { useTabConfig } from '../../../../../_shared/tabs/useTabConfig';
import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { TreeItemContentProps, useTreeItemState } from '@mui/x-tree-view';

export const CustomTreeItemContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
  const {
    onOpen,
    assetName,
    classes,
    className,
    label,
    itemId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    isSimulated,
    isArchived,
    isCamProperty,
  } = props;
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { findDefaultTab } = useTabConfig();

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
    useTreeItemState(itemId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement>) => {
    handleSelection(event);
    if (assetName === 'room') {
      navigate(`/assets/room/${itemId}`);
    } else {
      if (!menuOpen) {
        const assetType =
          assetName === 'property'
            ? AssetType.RentalProperty
            : assetName === 'building'
              ? AssetType.Building
              : AssetType.BuildingUnit;
        navigate(`/assets/${assetName}/${itemId}/${AssetTab.Overview}/${findDefaultTab(assetType, AssetTab.Overview)}`);
      }
    }
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center' }} onClick={handleSelectionClick}>
        {isCamProperty
          ? {
              property: <FoundationOutlinedIcon sx={{ color: Persimmon[700] }} />,
              building: <BusinessIcon sx={{ color: Persimmon[700] }} />,
              unit: <HouseOutlinedIcon sx={{ color: Persimmon[700] }} />,
            }[assetName || '']
          : isSimulated
            ? {
                property: <ApartmentIcon sx={{ color: Persimmon[700] }} />,
                building: <BusinessIcon sx={{ color: Persimmon[700] }} />,
                unit: <HouseOutlinedIcon sx={{ color: Persimmon[700] }} />,
              }[assetName || '']
            : {
                property: <ApartmentIcon sx={{ color: appTheme.palette.primary.dark }} />,
                building: <BusinessIcon sx={{ color: appTheme.palette.primary.dark }} />,
                unit: <HouseOutlinedIcon sx={{ color: appTheme.palette.primary.dark }} />,
              }[assetName || '']}
        <Typography
          sx={
            isCamProperty
              ? {
                  my: '.5rem',
                  color: appTheme.palette.primary.dark,
                }
              : isSimulated
                ? {
                    my: '.5rem',
                    color: isArchived ? appTheme.palette.warning.main : Persimmon[700],
                  }
                : {
                    my: '.5rem',
                    color: isArchived ? appTheme.palette.text.disabled : appTheme.palette.text.primary,
                  }
          }
          fontStyle={isArchived ? 'italic' : undefined}
          color={isArchived ? 'darkgrey' : 'text.primary'}
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
        {selected ? (
          <TreeItemPopupMenu
            assetName={assetName}
            itemId={itemId}
            hover={hover}
            onOpen={onOpen}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            isCamProperty={isCamProperty}
            isSimulated={isSimulated}
            isArchived={isArchived}
          />
        ) : (
          <Box sx={{ width: '1rem', marginRight: '1rem' }}></Box>
        )}
      </Box>
    </div>
  );
});
