import { all, put, race, select, take, takeLatest } from 'redux-saga/effects';
import {
  addCommentToApplicationAction,
  addCommentToApplicationFailAction,
  addCommentToApplicationSuccessAction,
  cancelRemoveDocumentAction,
  createApplicationAction,
  createApplicationFailAction,
  createApplicationSuccessAction,
  createWebApplicationSuccessAction,
  failRemoveDocumentAction,
  failUploadedDocumentAction,
  getApplicationsByPropertyIdAction,
  getApplicationsByPropertyIdFailAction,
  getApplicationsByPropertyIdSuccessAction,
  getCoSignerApplicationsByParentIdAction,
  getCoSignerApplicationsByParentIdFailAction,
  getCoSignerApplicationsByParentIdSuccessAction,
  getOtherAdultApplicationsByParentIdAction,
  getOtherAdultApplicationsByParentIdFailAction,
  getOtherAdultApplicationsByParentIdSuccessAction,
  getSingleApplicationByIdAction,
  getSingleApplicationByIdFailAction,
  getSingleApplicationByIdSuccessAction,
  refreshApplicationsAction,
  removeDocumentAction,
  sendEmailAction,
  sendEmailFailAction,
  sendEmailSuccessAction,
  setApplicantTableFiltersAction,
  setApplicantTableFiltersFailAction,
  setApplicantTableFiltersSuccessAction,
  setApplicantTableSortAction,
  successRemoveDocumentAction,
  successUploadedDocumentAction,
  toggleIncludeClosedStatusesAction,
  updateApplicationAction,
  updateApplicationFailAction,
  updateApplicationStatusAction,
  updateApplicationStatusFailAction,
  updateApplicationStatusSuccessAction,
  updateApplicationSuccessAction,
  updateSubmitApplicationAction,
  updateSubmitApplicationFailAction,
  updateSubmitApplicationSuccessAction,
  uploadDocumentAction,
} from './applicationSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AddApplicationRequest_CreateCoSignerApplicationData,
  AddApplicationRequest_CreateOtherAdultApplicationData,
  AddApplicationRequest_CreatePrimaryApplicationData,
  ApplicationStatus,
  ApplicationType,
  CreateApplicationResponse,
  IBaseApplicationResponse,
  IQueryParameter,
  ListApplicationHandlerRequest,
  ListApplicationHandlerResponse,
  ListSortDirection,
  QueryExpression,
  QueryGroupOperator,
  QueryOperator,
  UpdateApplicationRequest_CoSignerApplication,
  UpdateApplicationRequest_OtherAdultApplication,
  UpdateApplicationRequest_PrimaryApplication,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import {
  cancelConfirmDialogAction,
  okConfirmDialogAction,
  showConfirmDialogAction,
  showErrorAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { RootState } from '../../../app/store';
import {
  closedStatusesQueryExpression,
  CreateApplicationData,
  IAllApplicationType,
  mapReduxApplication,
  ReduxApplication,
} from './applicationTypes';
import { Filter, PageableCollection, SortDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { convertToQueryExpression } from '../../_shared/utils/filteringUtils';
import { SelectedContext } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';

function* refreshApplications() {
  const { propertyId }: SelectedContext = yield select((r: RootState) => r.assets.selectedContext);
  const { page, pageSize }: PageableCollection<ReduxApplication> = yield select(
    (r: RootState) => r.applications.allApplications,
  );
  yield put(
    getApplicationsByPropertyIdAction({
      rentalPropertyId: propertyId,
      applicationType: ApplicationType.Primary,
      page,
      pageSize,
    }),
  );
}

function* getApplicationsByPropertyId(
  action: PayloadAction<{
    rentalPropertyId: string;
    applicationType: ApplicationType;
    page?: number;
    pageSize?: number;
    sortDirection?: SortDirection;
    sortBy?: keyof ReduxApplication;
  }>,
) {
  try {
    const filters: Filter<IAllApplicationType>[] = yield select(
      (r: RootState) => r.applications.allApplications.filters,
    );
    const page: number = yield select((r: RootState) => r.applications.allApplications.page);
    const pageSize: number = yield select((r: RootState) => r.applications.allApplications.pageSize);
    const formattedFilters: QueryExpression = convertToQueryExpression(filters);
    const includeClosedStatuses: boolean = yield select((r: RootState) => r.applications.includeClosedStatuses);
    const sortDirection: SortDirection = yield select((r: RootState) => r.applications.allApplications.sortDirection);
    const sortBy: keyof ReduxApplication = yield select((r: RootState) => r.applications.allApplications.sortBy);

    if (!includeClosedStatuses) {
      formattedFilters.subExpressions = formattedFilters.subExpressions?.concat(
        new QueryExpression(closedStatusesQueryExpression),
      );
    }

    const orderDirection: ListSortDirection = action.payload.sortDirection
      ? (action.payload.sortDirection as ListSortDirection)
      : (sortDirection as ListSortDirection);

    const response: ListApplicationHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().properties_SearchApplications,
      action.payload.rentalPropertyId,
      new ListApplicationHandlerRequest({
        query: {
          groupOperator: QueryGroupOperator.And,
          parameters: [
            {
              field: 'applicationType',
              operator: QueryOperator.Eq,
              value: action.payload.applicationType,
            },
          ],

          subExpressions: [formattedFilters],
          page: action.payload.page ?? page,
          pageSize: action.payload.pageSize ?? pageSize,
          orderDirection: orderDirection,
          orderBy: action.payload.sortBy ? [action.payload.sortBy] : sortBy ? [sortBy] : undefined,
        },
      }),
    );

    if (response?.results) {
      const applicationsList: ReduxApplication[] = response.results.map(
        (application) => mapReduxApplication(application)!,
      );

      yield put(
        getApplicationsByPropertyIdSuccessAction({
          applications: applicationsList,
          totalCount: response.totalCount ?? 0,
        }),
      );
    } else {
      yield put(
        showToastMessageAction({
          message: 'Whoops! Something went wrong',
          severity: 'error',
        }),
      );
    }
  } catch (error: any) {
    yield put(getApplicationsByPropertyIdFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem getting property applications' }));
  }
}

function* toggleIncludeClosedStatuses(
  action: PayloadAction<{
    includeClosedStatuses: boolean;
    rentalPropertyId: string;
    applicationType: ApplicationType;
  }>,
) {
  yield put(
    getApplicationsByPropertyIdAction({
      rentalPropertyId: action.payload.rentalPropertyId,
      applicationType: action.payload.applicationType,
    }),
  );
}

function* getSingleApplicationById(action: PayloadAction<string>) {
  try {
    // Due to multiple derived types from the back end we have to use this type and then manually convert
    const response: IBaseApplicationResponse = yield apiCall(
      ApiClientSingleton.getInstance().application_Get,
      action.payload,
    );
    const reduxApplication = mapReduxApplication(response as IAllApplicationType);
    if (reduxApplication) {
      yield put(getSingleApplicationByIdSuccessAction(reduxApplication));
      if (reduxApplication.applicationType === ApplicationType.Primary && reduxApplication.id) {
        yield put(
          getOtherAdultApplicationsByParentIdAction({
            propertyId: reduxApplication.rentalPropertyId!,
            parentApplicationId: action.payload,
          }),
        );
        yield put(
          getCoSignerApplicationsByParentIdAction({
            propertyId: reduxApplication.rentalPropertyId!,
            parentApplicationId: action.payload,
          }),
        );
      }
    } else {
      yield put(showToastMessageAction({ message: 'Whoops! Something went wrong :(', severity: 'error' }));
    }
  } catch (error: any) {
    if (error.status === 404) {
      yield put(showToastMessageAction({ message: 'Application not found', severity: 'error' }));
    } else {
      yield put(showErrorAction({ error, fallbackMessage: 'problem fetching application' }));
    }
    yield put(getSingleApplicationByIdFailAction());
  }
}

function* getOtherAdultApplicationsByParentId(
  action: PayloadAction<{ propertyId: string; parentApplicationId: string }>,
) {
  try {
    const response: ListApplicationHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().properties_GetAssociatedOtherAdultApplications,
      action.payload.propertyId,
      action.payload.parentApplicationId,
      undefined,
      undefined,
    );
    if (response?.results) {
      const applicationsList: ReduxApplication[] = response.results.map(
        (application) => mapReduxApplication(application)!,
      );

      yield put(
        getOtherAdultApplicationsByParentIdSuccessAction({
          parentApplicationId: action.payload.parentApplicationId,
          body: applicationsList,
        }),
      );
    } else {
      yield put(
        showToastMessageAction({
          message: 'Whoops! Something went wrong',
          severity: 'error',
        }),
      );
    }
  } catch (error: any) {
    yield put(getOtherAdultApplicationsByParentIdFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem fetching other adult applications' }));
  }
}

function* getCoSignerApplicationsByParentId(
  action: PayloadAction<{ propertyId: string; parentApplicationId: string }>,
) {
  try {
    const response: ListApplicationHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().properties_GetAssociatedCoSignerApplications,
      action.payload.propertyId,
      action.payload.parentApplicationId,
      undefined,
      undefined,
    );
    if (response?.results) {
      const applicationsList: ReduxApplication[] = response.results.map(
        (application) => mapReduxApplication(application)!,
      );
      yield put(
        getCoSignerApplicationsByParentIdSuccessAction({
          parentApplicationId: action.payload.parentApplicationId,
          body: applicationsList,
        }),
      );
    } else {
      yield put(
        showToastMessageAction({
          message: 'Whoops! Something went wrong',
          severity: 'error',
        }),
      );
    }
  } catch (error: any) {
    yield put(getCoSignerApplicationsByParentIdFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem getting co-signer applications' }));
  }
}

function* filterApplicantTable(
  action: PayloadAction<{
    rentalPropertyId: string;
    applicationType: ApplicationType;
    parameters: IQueryParameter[];
  }>,
) {
  try {
    yield put(setApplicantTableFiltersSuccessAction());
    yield put(
      getApplicationsByPropertyIdAction({
        rentalPropertyId: action.payload.rentalPropertyId,
        applicationType: action.payload.applicationType,
      }),
    );
  } catch (error: any) {
    yield put(setApplicantTableFiltersFailAction());
    yield put(showToastMessageAction({ message: error.message, severity: 'error' }));
  }
}

function* createSpecificApplication<T, Response>(
  propertyId: string,
  request: T,
  specificCall: (body: T) => Response,
  isAnonymous?: boolean,
) {
  const createApplication: CreateApplicationResponse = yield apiCall(specificCall, request);
  if (createApplication) {
    if (!isAnonymous) {
      yield put(createApplicationSuccessAction(createApplication.toJSON()));
      if (createApplication.rentalPropertyId && createApplication.applicationType === ApplicationType.Primary) {
        yield put(
          getApplicationsByPropertyIdAction({
            rentalPropertyId: createApplication.rentalPropertyId,
            applicationType: ApplicationType.Primary,
          }),
        );
      }
      if (createApplication.parentApplicationId) {
        const parentApplicationId = createApplication.parentApplicationId;
        yield put(getOtherAdultApplicationsByParentIdAction({ propertyId, parentApplicationId }));
        yield put(getCoSignerApplicationsByParentIdAction({ propertyId, parentApplicationId }));
      }
      yield put(showToastMessageAction({ message: 'Application added successfully!', severity: 'success' }));
    }
    if (isAnonymous) {
      yield put(createWebApplicationSuccessAction());
      yield put(
        showToastMessageAction({
          message: 'Thank you for your application! A property manager will contact you to follow up.',
          severity: 'success',
        }),
      );
    }
  } else {
    yield put(showToastMessageAction({ message: 'Whoops! Something went wrong :(', severity: 'error' }));
  }
}

function* createApplication(
  action: PayloadAction<{
    propertyId: string;
    applicationType: ApplicationType;
    body: CreateApplicationData;
    isWebView?: boolean;
  }>,
) {
  try {
    if (action.payload.applicationType === ApplicationType.Primary) {
      const request = AddApplicationRequest_CreatePrimaryApplicationData.fromJS({
        applicationData: action.payload.body,
      });
      if (action.payload.isWebView) {
        yield createSpecificApplication(
          action.payload.propertyId,
          request,
          ApiClientSingleton.getInstance().application_CreateFromWeb,
          true,
        );
      } else
        yield createSpecificApplication(
          action.payload.propertyId,
          request,
          ApiClientSingleton.getInstance().application_CreatePrimary,
        );
    } else if (action.payload.applicationType === ApplicationType.OtherAdult) {
      const request = AddApplicationRequest_CreateOtherAdultApplicationData.fromJS({
        applicationData: action.payload.body,
      });
      yield createSpecificApplication(
        action.payload.propertyId,
        request,
        ApiClientSingleton.getInstance().application_CreateOtherAdult,
      );
    } else if (action.payload.applicationType === ApplicationType.CoSigner) {
      const request = AddApplicationRequest_CreateCoSignerApplicationData.fromJS({
        applicationData: action.payload.body,
      });
      yield createSpecificApplication(
        action.payload.propertyId,
        request,
        ApiClientSingleton.getInstance().application_CreateCoSigner,
      );
    }
  } catch (error: any) {
    yield put(createApplicationFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem creating application' }));
  }
}

function* updateApplication(
  action: PayloadAction<{ id: string; type: ApplicationType; body: ReduxApplication; propertyId?: string }>,
) {
  try {
    if (action.payload.type === ApplicationType.Primary) {
      const request = UpdateApplicationRequest_PrimaryApplication.fromJS(action.payload.body);
      yield apiCall(ApiClientSingleton.getInstance().application_UpdatePrimary, action.payload.id, request);
    } else if (action.payload.type === ApplicationType.OtherAdult) {
      const request = UpdateApplicationRequest_OtherAdultApplication.fromJS(action.payload.body);
      yield apiCall(ApiClientSingleton.getInstance().application_UpdateOtherAdult, action.payload.id, request);
    } else if (action.payload.type === ApplicationType.CoSigner) {
      const request = UpdateApplicationRequest_CoSignerApplication.fromJS(action.payload.body);
      yield apiCall(ApiClientSingleton.getInstance().application_UpdateCoSigner, action.payload.id, request);
    }
    yield put(updateApplicationSuccessAction());
    yield put(getSingleApplicationByIdAction(action.payload.id));
    if (action.payload.propertyId) {
      yield put(
        getApplicationsByPropertyIdAction({
          rentalPropertyId: action.payload.propertyId,
          applicationType: action.payload.type,
        }),
      );
    }
    yield put(showToastMessageAction({ message: 'Application updated successfully!', severity: 'success' }));
  } catch (error: any) {
    yield put(updateApplicationFailAction());
    yield put(getSingleApplicationByIdAction(action.payload.id));
    yield put(showErrorAction({ error, fallbackMessage: 'problem updating application' }));
  }
}

function* addCommentToApplication(action: PayloadAction<{ id: string; comment: string }>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().application_AddComment, action.payload.id, action.payload.comment);
    yield put(addCommentToApplicationSuccessAction());
    yield put(getSingleApplicationByIdAction(action.payload.id));
    yield put(showToastMessageAction({ message: 'Comment posted to application.', severity: 'success' }));
  } catch (error: any) {
    yield put(addCommentToApplicationFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem adding comment to application' }));
  }
}

function* updateApplicationStatus(
  action: PayloadAction<{ id: string; applicationStatus: ApplicationStatus; rentalPropertyId: string }>,
) {
  try {
    if (action.payload.applicationStatus === ApplicationStatus.Approved) {
      yield apiCall(ApiClientSingleton.getInstance().application_Approve, action.payload.id);
      yield put(updateApplicationStatusSuccessAction());
      yield put(showToastMessageAction({ message: 'Application successfully approved', severity: 'success' }));
    } else if (action.payload.applicationStatus === ApplicationStatus.Denied) {
      yield apiCall(ApiClientSingleton.getInstance().application_Deny, action.payload.id);
      yield put(updateApplicationStatusSuccessAction());
      yield put(showToastMessageAction({ message: 'Application successfully denied', severity: 'success' }));
    } else if (action.payload.applicationStatus === ApplicationStatus.UserEditing) {
      yield apiCall(ApiClientSingleton.getInstance().application_EnableEditing, action.payload.id);
      yield put(updateApplicationStatusSuccessAction());
      yield put(showToastMessageAction({ message: 'Application is now editable', severity: 'success' }));
    } else if (action.payload.applicationStatus === ApplicationStatus.ApprovedWithCosigner) {
      yield apiCall(ApiClientSingleton.getInstance().application_ApproveWithCoSigner, action.payload.id);
      yield put(updateApplicationStatusSuccessAction());
      yield put(
        showToastMessageAction({
          message: 'Application is now approved with co-signer',
          severity: 'success',
        }),
      );
    } else if (action.payload.applicationStatus === ApplicationStatus.Withdrawn) {
      yield apiCall(ApiClientSingleton.getInstance().application_Withdraw, action.payload.id);
      yield put(updateApplicationStatusSuccessAction());
      yield put(
        showToastMessageAction({ message: 'Application has successfully been withdrawn', severity: 'success' }),
      );
    } else if (action.payload.applicationStatus === ApplicationStatus.ReadyForReview) {
      yield apiCall(ApiClientSingleton.getInstance().application_ReadyForReview, action.payload.id);
      yield put(updateApplicationStatusSuccessAction());
      yield put(showToastMessageAction({ message: 'Application is now ready for review', severity: 'success' }));
    }
    yield put(
      getApplicationsByPropertyIdAction({
        rentalPropertyId: action.payload.rentalPropertyId,
        applicationType: ApplicationType.Primary,
      }),
    );
  } catch (error: any) {
    yield put(updateApplicationStatusFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Something went wrong with application status update' }));
  }
}

function* submitApplication(action: PayloadAction<string>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().application_Submit, action.payload);
    yield put(updateSubmitApplicationSuccessAction());
  } catch (error: any) {
    yield put(updateSubmitApplicationFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem submitting application' }));
  }
}

function* uploadDocument(action: PayloadAction<{ applicationId: string; file: File }>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().application_AddDocument, action.payload.applicationId, {
      data: action.payload.file,
      fileName: action.payload.file.name,
    });
    yield put(successUploadedDocumentAction());
    yield put(showToastMessageAction({ message: 'Document uploaded successfully!', severity: 'success' }));
    yield put(getSingleApplicationByIdAction(action.payload.applicationId));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem uploading document to application' }));
    yield put(failUploadedDocumentAction());
  }
}

function* removeDocument(action: PayloadAction<{ applicationId: string; documentId: string }>) {
  try {
    yield put(
      showConfirmDialogAction({
        message: 'Are you sure you want to delete this document?',
        okText: 'Yes',
        cancelText: 'No',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(
        ApiClientSingleton.getInstance().application_RemoveDocument,
        action.payload.applicationId,
        action.payload.documentId,
      );
      yield put(showToastMessageAction({ message: 'Document removed successfully!', severity: 'success' }));
      yield put(successRemoveDocumentAction({ documentId: action.payload.documentId }));
    } else {
      yield put(cancelRemoveDocumentAction());
    }
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem removing document from application' }));
    yield put(failRemoveDocumentAction());
  }
}

function* sendEmail(action: PayloadAction<{ rentalPropertyId: string; applicationId: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().properties_SendApplication,
      action.payload.rentalPropertyId,
      action.payload.applicationId,
    );
    yield put(showToastMessageAction({ message: 'Email sent to applicant!', severity: 'success' }));
    yield put(sendEmailSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not send an email to the applicant' }));
    yield put(sendEmailFailAction());
  }
}

export function* applicationSagas() {
  yield all([
    takeLatest(getApplicationsByPropertyIdAction.type, getApplicationsByPropertyId),
    takeLatest(getSingleApplicationByIdAction.type, getSingleApplicationById),
    takeLatest(getOtherAdultApplicationsByParentIdAction.type, getOtherAdultApplicationsByParentId),
    takeLatest(getCoSignerApplicationsByParentIdAction.type, getCoSignerApplicationsByParentId),
    takeLatest(toggleIncludeClosedStatusesAction.type, toggleIncludeClosedStatuses),
    takeLatest(setApplicantTableFiltersAction.type, filterApplicantTable),
    takeLatest(setApplicantTableSortAction.type, getApplicationsByPropertyId),
    takeLatest(createApplicationAction.type, createApplication),
    takeLatest(updateApplicationAction.type, updateApplication),
    takeLatest(updateApplicationStatusAction.type, updateApplicationStatus),
    takeLatest(addCommentToApplicationAction.type, addCommentToApplication),
    takeLatest(updateSubmitApplicationAction.type, submitApplication),
    takeLatest(uploadDocumentAction.type, uploadDocument),
    takeLatest(removeDocumentAction.type, removeDocument),
    takeLatest(sendEmailAction.type, sendEmail),
    takeLatest(refreshApplicationsAction.type, refreshApplications),
  ]);
}
