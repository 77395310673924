import { Box, Stack } from '@mui/material';
import React, { FC, useState } from 'react';
import { IncludeClosedToggle } from '../../../tasks/components/IncludeClosedToggle';
import { DetailsTable } from '../detailsTables/DetailsTable';
import { AddDetailDialog, DetailsAddDialogType } from '../dialogs/AddDetailDialog';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { DetailAssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxDetail } from '../../redux/detailTypes';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import { Feature } from '../../../../global/Feature';

export type DetailsDrawerTableProps = {
  detailAssociationType: DetailAssociationType;
  associatedId: string;
  parentId?: string;
  handleViewDetail: (detail: ReduxDetail) => void;
  isSubDetails?: boolean;
};

export const DetailsDrawerTable: FC<DetailsDrawerTableProps> = ({
  detailAssociationType,
  associatedId,
  parentId,
  handleViewDetail,
  isSubDetails,
}) => {
  const [includeClosed, setIncludeClosed] = useState(false);
  const [openAddSubDetail, setOpenAddSubDetail] = useState(false);

  return (
    <StyledInfoBox label={'Components'}>
      <Stack spacing={2}>
        <Box display={'flex'} flexGrow={1} justifyContent={'space-between'}>
          <Feature flag={'Feature.Details'}>
            <LoadingButton onClick={() => setOpenAddSubDetail(true)} variant={'outlined'} startIcon={<AddIcon />}>
              Add Component
            </LoadingButton>
          </Feature>
          <Box>
            <IncludeClosedToggle
              label={'Include Closed Components'}
              includeClosed={includeClosed}
              onToggleIncludeClosed={() => setIncludeClosed(!includeClosed)}
            />
          </Box>
        </Box>
        <DetailsTable
          associationType={detailAssociationType}
          associatedId={associatedId}
          onViewDetails={handleViewDetail}
          includeClosed={includeClosed}
          parentDetailId={parentId}
          isSubDetails={isSubDetails}
        />
      </Stack>
      {openAddSubDetail && (
        <AddDetailDialog
          associationType={detailAssociationType}
          associatedId={associatedId}
          type={isSubDetails ? DetailsAddDialogType.AddSubDetail : DetailsAddDialogType.AddDetail}
          onClose={() => setOpenAddSubDetail(false)}
          open={openAddSubDetail}
          parentId={parentId}
        />
      )}
    </StyledInfoBox>
  );
};
