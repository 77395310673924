import { fork } from 'redux-saga/effects';
import { roomSagas } from '../areas/room/redux/roomSagas';
import { notificationsSagas } from '../areas/_shared/notifications/redux/notificationsSagas';
import { leasesSagas } from '../areas/_shared/leases/redux/leasesSagas';
import { applicationSagas } from '../areas/property/redux/applicationSagas';
import { searchSagas } from '../areas/_shared/search/redux/searchSagas';
import { statementSagas } from '../areas/_shared/statements/redux/statementSagas';
import { leaseTransactionSaga } from '../areas/_shared/leases/redux/leaseTransactionSaga';
import { tenantSagas } from '../areas/tenantPortal/redux/tenantSagas';
import { communicationSagas } from '../areas/_shared/communications/redux/communicationSagas';
import { expenseSaga } from '../areas/_shared/expenses/redux/expenseSaga';
import { detailsSagas } from '../areas/_shared/details/redux/detailsSagas';
import { documentSaga } from '../areas/_shared/documents/redux/documentSaga';
import { reportSaga } from '../areas/property/redux/reportSaga';
import { bankAccountsSaga } from '../areas/_shared/bankAccounts/redux/bankAccountsSagas';
import { unitTemplateSaga } from '../areas/_shared/unitTemplates/redux/unitTemplateSaga';
import { listingSaga } from '../areas/listings/redux/listingSaga';
import { routingSagas } from './redux/routingSagas';

export function* rootSaga() {
  yield fork(roomSagas);
  yield fork(notificationsSagas);
  yield fork(leasesSagas);
  yield fork(leaseTransactionSaga);
  yield fork(statementSagas);
  yield fork(applicationSagas);
  yield fork(searchSagas);
  yield fork(expenseSaga);
  yield fork(tenantSagas);
  yield fork(bankAccountsSaga);
  yield fork(communicationSagas);
  yield fork(detailsSagas);
  yield fork(documentSaga);
  yield fork(reportSaga);
  yield fork(unitTemplateSaga);
  yield fork(listingSaga);
  yield fork(routingSagas);
}
