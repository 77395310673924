import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  Filter,
  initInteractableCollection,
  initLoadable,
  initPageableCollection,
  InteractableCollection,
  Loadable,
  PageableCollection,
  SortDirection,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { RootState } from '../../../../app/store';
import {
  AssociationType,
  ICamNotificationConfigurationDto,
  IListCamNotificationSupportedRolesResponse,
  ITaskDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  mapReduxNotificationConfiguration,
  ReduxNotification,
  ReduxNotificationConfiguration,
  ReduxUserNotification,
} from './notificationTypes';
import { AssetRouteType } from '@monkeyjump-labs/cam-fe-shared/dist/types/assetTypes';
import { getUpdatedTaskAssociationLabel, getUpdatedTaskAssociationStatus } from '../../utils/signalRUtils';

export interface NotificationsState {
  globalNotifications: PageableCollection<ReduxUserNotification>; //user notifications specific to user logged in
  assetNotifications: PageableCollection<ReduxNotification>; //asset notifications for properties associated with user
  notificationConfiguration: InteractableCollection<ReduxNotificationConfiguration>; //configuration of notifications for assets
  notificationSupportedRoles: Loadable<IListCamNotificationSupportedRolesResponse>;
}

const initNotifications = () => {
  const collection: PageableCollection<ReduxNotification> = initPageableCollection<ReduxNotification>([]);
  collection.page = 0;
  collection.pageSize = 10;
  collection.sortBy = 'createdAt';
  collection.sortDirection = 'Descending';

  return collection;
};

const initialState: NotificationsState = {
  globalNotifications: initPageableCollection<ReduxUserNotification>([]),
  assetNotifications: initNotifications(),
  notificationConfiguration: initInteractableCollection<ReduxNotificationConfiguration>([]),
  notificationSupportedRoles: initLoadable<IListCamNotificationSupportedRolesResponse>(),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    newNotificationArrivedAction: (state, action: PayloadAction<ReduxUserNotification>) => {
      (state.globalNotifications.value ?? []).push(action.payload);
    },
    newActivityArrivedAction: (state, action: PayloadAction<ReduxUserNotification>) => {
      (state.assetNotifications.value ?? []).unshift(action.payload);
    },
    getNotificationsByUserAction: (state) => {
      state.globalNotifications.loading = true;
      state.globalNotifications.loaded = false;
      state.globalNotifications.pageSize = 10;
    },
    getNotificationsByUserSuccessAction: (state, action: PayloadAction<ReduxUserNotification[]>) => {
      state.globalNotifications.loaded = true;
      state.globalNotifications.loading = false;
      state.globalNotifications.value = action.payload;
    },
    getNotificationsByUserFailAction: (state) => {
      state.globalNotifications.loaded = true;
      state.globalNotifications.loading = false;
      state.globalNotifications.value = undefined;
    },
    getMoreNotificationsByUserAction: (state) => {
      state.globalNotifications.pageSize =
        state.globalNotifications.pageSize && state.globalNotifications.pageSize + 10;
    },
    setPageNotificationsByAssetAction: (
      state,
      action: PayloadAction<{
        page: number;
        assetType: AssetRouteType;
        assetId: string;
        currentUserId?: string;
      }>,
    ) => {
      state.assetNotifications.page = action.payload.page;
    },
    setPageSizeNotificationsByAssetAction: (
      state,
      action: PayloadAction<{
        pageSize: number;
        assetType: AssetRouteType;
        assetId: string;
        currentUserId?: string;
      }>,
    ) => {
      state.assetNotifications.pageSize = action.payload.pageSize;
    },
    getNotificationsByAssetIdAction: (
      state,
      action: PayloadAction<{
        assetType: AssetRouteType;
        assetId: string;
        startingNotificationId?: string;
        currentUserId?: string;
      }>,
    ) => {
      state.assetNotifications.loading = true;
      state.assetNotifications.loaded = false;
    },
    getNotificationsByAssetIdSuccessAction: (
      state,
      action: PayloadAction<{ total: number; results: ReduxNotification[]; page: number }>,
    ) => {
      state.assetNotifications.loaded = true;
      state.assetNotifications.loading = false;
      state.assetNotifications.totalCount = action.payload.total;
      state.assetNotifications.value = action.payload.results;
      state.assetNotifications.page = action.payload.page;
    },
    getNotificationsByAssetIdFailAction: (state) => {
      state.assetNotifications.loaded = true;
      state.assetNotifications.loading = false;
      state.assetNotifications.value = undefined;
    },
    setAssetNotificationFiltersAction: (
      state,
      action: PayloadAction<{
        assetType: AssetRouteType;
        assetId: string;
        startingNotificationId?: string;
        filters: Filter<ReduxNotification>[] | undefined;
      }>,
    ) => {
      state.assetNotifications.filters = action.payload.filters;
    },
    setAssetNotificationSortAction: (
      state,
      action: PayloadAction<{
        assetType: AssetRouteType;
        assetId: string;
        startingNotificationId?: string;
        sortBy: keyof ReduxNotification | undefined;
        sortDirection: SortDirection | undefined;
      }>,
    ) => {
      state.assetNotifications.sortBy = action.payload.sortBy;
      state.assetNotifications.sortDirection = action.payload.sortDirection;
    },
    clearAssetNotificationFiltersAndSortAction: (state) => {
      state.assetNotifications.filters = undefined;
      state.assetNotifications.sortBy = 'createdAt';
      state.assetNotifications.sortDirection = 'Descending';
    },
    getNotificationConfigurationByAssetIdAction: (state, action: PayloadAction<string>) => {
      state.notificationConfiguration.loading = true;
      state.notificationConfiguration.loaded = false;
    },
    getNotificationConfigurationByAssetIdSuccessAction: (
      state,
      action: PayloadAction<ICamNotificationConfigurationDto[]>,
    ) => {
      state.notificationConfiguration.loaded = true;
      state.notificationConfiguration.loading = false;
      state.notificationConfiguration.value = action.payload.map(mapReduxNotificationConfiguration);
    },
    getNotificationConfigurationByAssetIdFailAction: (state) => {
      state.notificationConfiguration.loaded = true;
      state.notificationConfiguration.loading = false;
      state.notificationConfiguration.value = undefined;
    },
    markNotificationAsReadAction: (
      state,
      action: PayloadAction<{ notificationId: string; removeFromRedux: boolean }>,
    ) => {
      state.globalNotifications.submitted = false;
      state.globalNotifications.submitting = true;
    },
    markNotificationAsReadSuccessAction: (state, action: PayloadAction<string | undefined>) => {
      state.globalNotifications.submitted = true;
      state.globalNotifications.submitting = false;
      state.globalNotifications.value = state.globalNotifications.value?.filter((n) => n.id !== action.payload);
    },
    markNotificationAsReadFailAction: (state) => {
      state.globalNotifications.submitted = false;
      state.globalNotifications.submitting = false;
    },
    updateNotificationConfigAction: (
      state,
      action: PayloadAction<{
        id: string;
        propertyId: string;
        body: ReduxNotificationConfiguration;
      }>,
    ) => {
      state.globalNotifications.submitting = true;
      state.globalNotifications.submitted = false;
    },
    updateNotificationConfigSuccessAction: (state) => {
      state.globalNotifications.submitting = false;
      state.globalNotifications.submitted = true;
    },
    updateNotificationConfigFailAction: (state) => {
      state.globalNotifications.submitting = false;
      state.globalNotifications.submitted = false;
    },
    getSupportedRolesAction: (state) => {
      state.notificationSupportedRoles.loading = true;
      state.notificationSupportedRoles.loaded = false;
    },
    getSupportedRolesSuccessAction: (state, action: PayloadAction<IListCamNotificationSupportedRolesResponse>) => {
      state.notificationSupportedRoles.loading = false;
      state.notificationSupportedRoles.loaded = true;
      state.notificationSupportedRoles.value = action.payload;
    },
    getSupportedRolesFailAction: (state) => {
      state.notificationSupportedRoles.loading = false;
      state.notificationSupportedRoles.loaded = false;
      state.notificationSupportedRoles.value = undefined;
    },
    resetGlobalNotificationSubmissionAction: (state) => {
      state.globalNotifications.submitting = false;
      state.globalNotifications.submitted = false;
    },
    updateNotificationAssociationStatusesFromTaskAction: (state, action: PayloadAction<{ task: ITaskDto }>) => {
      if (!state.assetNotifications.value) return;

      for (const notification of state.assetNotifications.value) {
        const associations = [...(notification.associations ?? [])];

        for (const association of associations) {
          if (
            association.associationType !== AssociationType.Task ||
            association.associatedId !== action.payload.task.id
          )
            continue;

          association.label = getUpdatedTaskAssociationLabel(action.payload.task);
          association.status = getUpdatedTaskAssociationStatus(action.payload.task);
        }

        const updatedNotification = { ...notification, associations: associations };

        state.assetNotifications.value = state.assetNotifications.value.map((x) => {
          if (x.id === notification.id) return updatedNotification;
          return x;
        });
      }
    },
  },
});
/* eslint-enable @typescript-eslint/no-unused-vars */

export const {
  newNotificationArrivedAction,
  newActivityArrivedAction,
  getNotificationsByUserAction,
  getNotificationsByUserFailAction,
  getNotificationsByUserSuccessAction,
  getMoreNotificationsByUserAction,
  setPageNotificationsByAssetAction,
  setPageSizeNotificationsByAssetAction,
  getNotificationsByAssetIdAction,
  getNotificationsByAssetIdFailAction,
  getNotificationsByAssetIdSuccessAction,
  setAssetNotificationFiltersAction,
  setAssetNotificationSortAction,
  clearAssetNotificationFiltersAndSortAction,
  getNotificationConfigurationByAssetIdAction,
  getNotificationConfigurationByAssetIdFailAction,
  getNotificationConfigurationByAssetIdSuccessAction,
  markNotificationAsReadAction,
  markNotificationAsReadFailAction,
  markNotificationAsReadSuccessAction,
  updateNotificationConfigAction,
  updateNotificationConfigFailAction,
  updateNotificationConfigSuccessAction,
  getSupportedRolesAction,
  getSupportedRolesFailAction,
  getSupportedRolesSuccessAction,
  resetGlobalNotificationSubmissionAction,
  updateNotificationAssociationStatusesFromTaskAction,
} = notificationsSlice.actions;

export const useNotifications = () => useSelector((state: RootState) => state.notifications);
