import React, { FC, useEffect } from 'react';
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AppRouter } from './AppRouter';
import { appTheme } from './AppTheme';
import { useAuth0 } from '@auth0/auth0-react';
import {
  clearTriggeredLogoutAction,
  getCurrentUserInfoAction,
  useUser,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { TokenSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/tokenSingleton';
import { useDispatch } from 'react-redux';
import { LicenseInfo } from '@mui/x-license';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { UploadFileDialog } from './areas/_shared/dialogs/UploadFileDialog';
import { ConfirmDialog } from './areas/global/components/confirmDialog/ConfirmDialog';
import { Logger } from './Logger';
import { GlobalSnackbar } from './areas/global/components/globalSnackbar/GlobalSnackbar';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Connector from '@monkeyjump-labs/cam-fe-shared/dist/services/signalrConnection';
import {
  IEmailDto,
  IEmailThreadDto,
  IListCamNotificationHandlerSingleResponse,
  IPaymentChipDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { newNotificationArrivedAction } from './areas/_shared/notifications/redux/notificationsSlice';
import { mapReduxUserNotifications } from './areas/_shared/notifications/redux/notificationTypes';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import {
  newEmailArrivedAction,
  newEmailThreadArrivedAction,
} from './areas/_shared/communications/redux/communicationSlice';
import { mapReduxEmail, mapReduxEmailThread } from './areas/_shared/communications/redux/communicationTypes';
import { updatePaymentChipSignalRAction } from './areas/_shared/statements/redux/statementSlice';
import { Layout } from './areas/global/layout/Layout';

process.env.REACT_APP_MUI_LICENSE_KEY && LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const App: FC = () => {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
  const ldClient = useLDClient();
  const { triggerLogout, currentUser } = useUser((u) => u);
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const [authTokenSet, setAuthTokenSet] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser.loaded && ldClient) {
      const user = {
        key: currentUser.value?.id ?? 'anonymous',
        email: currentUser.value?.email ?? 'anonymous@example.com',
        name: currentUser.value?.firstName + ' ' + currentUser.value?.lastName,
      };

      // noinspection JSIgnoredPromiseFromCall
      ldClient.identify(user);
    }
  }, [currentUser.value, currentUser.loaded, ldClient]);

  // When logged in
  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        if (token) {
          TokenSingleton.setAccessToken(token);
          setAuthTokenSet(true);
          dispatch(getCurrentUserInfoAction());

          const connector = Connector.getInstance(token, process.env.REACT_APP_HUB_ADDRESS ?? '');

          connector.onReceiveNotification((payload: IListCamNotificationHandlerSingleResponse) => {
            dispatch(newNotificationArrivedAction(mapReduxUserNotifications(payload)));
          });
          connector.onReceiveEmail((threadId: string, payload: IEmailDto) => {
            dispatch(newEmailArrivedAction({ email: mapReduxEmail(payload), threadId: threadId }));
          });
          connector.onReceiveEmailThread((payload: IEmailThreadDto) => {
            dispatch(newEmailThreadArrivedAction(mapReduxEmailThread(payload)));
          });

          const paymentChipHandler = (payload: IPaymentChipDto) => {
            dispatch(updatePaymentChipSignalRAction(payload));
          };
          connector.onReceivePaymentChip(paymentChipHandler);
          return function cleanup() {
            connector.offReceiveEmail();
            connector.offReceiveEmailThread();
            connector.offReceivePaymentChip(paymentChipHandler);
          };
        } else {
          TokenSingleton.setAccessToken('');
        }
      });
    }
  }, [isAuthenticated]);

  // When logged out
  useEffect(() => {
    if (triggerLogout) {
      dispatch(clearTriggeredLogoutAction());
      logout();
    }
  }, [triggerLogout]);

  return (
    <ThemeProvider theme={appTheme}>
      <Logger />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ConfirmDialog />
        <UploadFileDialog />
        <CssBaseline />
        <Layout isAuthTokenSet={authTokenSet}>
          <AppRouter isAuthTokenSet={authTokenSet} />
        </Layout>
        <GlobalSnackbar />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
