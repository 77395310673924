import { alpha, styled } from '@mui/material/styles';
import { TreeItem } from '@mui/x-tree-view';
import { Sapphire } from '../../../../../AppTheme';

export const StyledTreeItem = styled(TreeItem)(() => ({
  '&.MuiTreeItem-content': {
    borderRadius: 4,
    '&.Mui-selected': {
      backgroundColor: Sapphire[100],
    },
    '&:hover': {
      backgroundColor: alpha(Sapphire[100], 0.5),
    },
  },
}));
