export enum AssetTab {
  Overview = 'overview',
  Leasing = 'leasing',
  Operations = 'operations',
  Info = 'info',
  Financials = 'financials',
}

export enum OverviewTab {
  Metrics = 'metrics',
  ToDo = 'todo',
  Notifications = 'notifications',
  Communications = 'communications',
}

export enum InfoTab {
  Information = 'information',
  Configuration = 'configuration',
  Setup = 'setup',
  UserAccess = 'access',
  SopDefinitions = 'definitions',
  TaskCategories = 'categories',
  VendorManagement = 'vendors',
  UnitTemplates = 'unitTemplates',
}

export enum FinancialsTab {
  Journal = 'journal',
  Reports = 'reports',
  Billing = 'billing',
  Accounts = 'accounts',
}

export enum LeasingTab {
  Applications = 'applications',
  Leasing = 'leasing',
  Advertise = 'advertise',
  CurrentLeases = 'currentLeases',
}

export enum OperationsTab {
  ScheduledTasks = 'scheduledTasks',
  Tasks = 'tasks',
  Expenses = 'expenses',
  Income = 'income',
  Details = 'details',
}

export enum ApplicationDialogTab {
  Info = 'info',
  OtherAdults = 'otherAdults',
  CoSigner = 'cosigner',
  Personal = 'personal',
  Documents = 'documents',
  Comments = 'comments',
  Communications = 'communications',
  History = 'history',
  BackgroundCheck = 'backgroundCheck',
}

export enum LeaseDialogTab {
  General = 'general',
  Rent = 'rent',
  Tenant = 'tenant',
  Documents = 'documents',
  Comments = 'comments',
  Communications = 'communications',
  PaymentHistory = 'paymentHistory',
}

export enum TaskDialogTab {
  Info = 'info',
  Description = 'description',
  Documents = 'documents',
  Comments = 'comments',
  Communications = 'communications',
}

export enum ExpenseDialogTab {
  Info = 'info',
  Payments = 'payments',
  Invoices = 'invoices',
  PurchaseOrders = 'purchaseOrders',
  Quotes = 'quotes',
  WorkOrders = 'workOrders',
  Analysis = 'analysis',
}

export enum JobElementTab {
  Info = 'info',
  Documents = 'documents',
  Comments = 'Comments',
  Communications = 'communications',
}

export enum DetailsTab {
  Info = 'info',
  Observations = 'observations',
  Documents = 'documents',
  SubDetails = 'subDetails',
  Associations = 'associations',
}

export enum ScheduledTaskTab {
  Info = 'info',
  Description = 'description',
  Documents = 'documents',
}

export enum SopTaskTab {
  Task = 'info',
  Checklist = 'checklist',
  Documents = 'documents',
}

export enum VendorTab {
  Info = 'info',
}

export enum UnitTemplateTab {
  Info = 'info',
  Details = 'details',
}

export enum ListingTab {
  Info = 'info',
  Agent = 'agent',
  Photos = 'photos',
  WebView = 'webView',
}
