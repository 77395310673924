import { all, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  addTenantCommentToTaskAction,
  addTenantCommentToTaskFailAction,
  addTenantCommentToTaskSuccessAction,
  completeAuthorizeNetPaymentAction,
  createRecurringPaymentAction,
  createRecurringPaymentFailAction,
  createRecurringPaymentSuccessAction,
  createTaskFromTenantRequestAction,
  createTaskFromTenantRequestFailAction,
  createTaskFromTenantRequestSuccessAction,
  deleteRecurringPaymentAction,
  deleteRecurringPaymentFailAction,
  deleteRecurringPaymentSuccessAction,
  deleteTenantAccountAction,
  deleteTenantAccountFailAction,
  deleteTenantAccountSuccessAction,
  getRecurringPaymentAction,
  getRecurringPaymentFailAction,
  getRecurringPaymentSuccessAction,
  getTenantLeaseByIdAction,
  getTenantLeaseByIdFailAction,
  getTenantLeaseByIdSuccessAction,
  getUserBankAccountsAction,
  getUserBankAccountsFailAction,
  getUserBankAccountsSuccessAction,
  initializeCreditCardPaymentAction,
  initializeCreditCardPaymentFailAction,
  initializeCreditCardPaymentSuccessAction,
  initiateTenantPaymentAction,
  initiateTenantPaymentFailAction,
  initiateTenantPaymentSuccessAction,
  listTenantTasksAction,
  listTenantTasksFailAction,
  listTenantTasksSuccessAction,
  makeAccountDefaultAction,
  makeAccountDefaultFailAction,
  makeAccountDefaultSuccessAction,
  requestMoveOutDateAction,
  requestMoveOutDateFailAction,
  requestMoveOutDateSuccessAction,
  setSelectedMaintenanceRequestAction,
  updateTenantTaskAction,
  updateTenantTaskFailAction,
  updateTenantTaskSuccessAction,
  uploadTenantDocToTaskAction,
  uploadTenantDocToTaskFailAction,
  uploadTenantDocToTaskSuccessAction,
} from './tenantSlice';
import {
  mapApiComment,
  mapReduxMtAccount,
  ReduxDate,
  toStandardDate,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import {
  AddTaskFromTenantRequestHandlerRequest,
  CompleteAuthorizeNetPaymentRequest,
  GetRecurringPaymentHandlerResponse,
  GetTenantLeaseHandlerResponse,
  IAddTaskFromTenantRequestHandlerRequest,
  IInitiateTenantAchPaymentHandlerRequest,
  IModernTreasuryAccount,
  InitiateAuthorizeNetPaymentHandlerRequest,
  InitiateAuthorizeNetPaymentHandlerResponse,
  InitiateTenantAchPaymentHandlerRequest,
  ITenantTask,
  ListModernTreasuryAccountsByUserIdHandlerResponse,
  ListTenantTasksHandlerResponse,
  Optional_DateOnly,
  RequestMoveOutHandlerRequest,
  UpdateTenantTaskHandlerRequest,
  UpsertRecurringPaymentHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { mapReduxTenantLease } from './tenantTypes';
import {
  cancelConfirmDialogAction,
  okConfirmDialogAction,
  showConfirmDialogAction,
  showErrorAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { RootState } from '../../../app/store';
import { mapReduxTenantTask, ReduxTenantTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import {
  getTenantStatementByLeaseIdAction,
  paymentSubmitFailedAction,
  paymentSubmittedAction,
} from '../../_shared/statements/redux/statementSlice';

function* getTenantLeaseById(action: PayloadAction<string>) {
  try {
    const response: GetTenantLeaseHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().tenant_GetLease,
      action.payload,
    );
    yield put(getTenantLeaseByIdSuccessAction(mapReduxTenantLease(response.toJSON())));
  } catch (error) {
    yield put(getTenantLeaseByIdFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting tenant lease' }));
  }
}

function* getBankAccountsByUserId() {
  try {
    const result: ListModernTreasuryAccountsByUserIdHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().user_GetUserBankAccounts,
    );
    if (result) {
      yield put(
        getUserBankAccountsSuccessAction(
          result.toJSON().results.map((account: IModernTreasuryAccount) => mapReduxMtAccount(account)),
        ),
      );
    } else
      yield put(
        showToastMessageAction({
          message: 'something went wrong getting bank accounts',
          severity: 'error',
        }),
      );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not retrieve user bank accounts' }));
    yield put(getUserBankAccountsFailAction());
  }
}

function* initiateTenantPayment(action: PayloadAction<IInitiateTenantAchPaymentHandlerRequest>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().user_InitiateTenantPayment,
      new InitiateTenantAchPaymentHandlerRequest(action.payload),
    );
    yield put(initiateTenantPaymentSuccessAction());
    const leaseId: string | undefined = yield select((r: RootState) => r.tenant.leases.selectedValue?.id);
    const periodStart: ReduxDate | undefined = yield select(
      (r: RootState) => r.statements.selectedStatement.value?.startDate,
    );
    yield leaseId && periodStart && put(getTenantStatementByLeaseIdAction({ leaseId, periodStart }));
    yield put(showToastMessageAction({ message: 'Payment initiated successfully!' }));
    yield put(paymentSubmittedAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem initiating tenant payment' }));
    yield put(initiateTenantPaymentFailAction());
    yield put(paymentSubmitFailedAction());
  }
}

function* deleteTenantAccount(action: PayloadAction<{ acctId: string }>) {
  try {
    yield put(showConfirmDialogAction({ message: 'Are you sure you want to delete this account?' }));
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(ApiClientSingleton.getInstance().user_DeleteUserBankAccountByAccountId, action.payload.acctId);
      yield put(showToastMessageAction({ message: 'Account deleted successfully!', severity: 'success' }));
      yield put(deleteTenantAccountSuccessAction());
      yield put(getUserBankAccountsAction());
    }
  } catch (error: any) {
    yield put(deleteTenantAccountFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Could not delete account' }));
  }
}

function* makeAccountDefault(action: PayloadAction<string>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().user_SetTenantPaymentDefault, action.payload);
    yield put(showToastMessageAction({ message: 'Account set as default', severity: 'success' }));
    yield put(getUserBankAccountsAction());
    yield put(makeAccountDefaultSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not set account as default' }));
    yield put(makeAccountDefaultFailAction());
  }
}

function* getRecurringPayment() {
  try {
    const response: GetRecurringPaymentHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().user_GetUserRecurringPayment,
    );
    if (response) {
      yield put(getRecurringPaymentSuccessAction(response.toJSON()));
    } else {
      yield put(
        showToastMessageAction({ message: 'Something went wrong fetching recurring payment', severity: 'error' }),
      );
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not fetch recurring payment info' }));
    yield put(getRecurringPaymentFailAction());
  }
}

function* createRecurringPayment(action: PayloadAction<UpsertRecurringPaymentHandlerRequest>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().user_UpsertRecurringPayment, action.payload);
    yield put(createRecurringPaymentSuccessAction());
    yield put(showToastMessageAction({ message: 'Recurring payment scheduled successfully!', severity: 'success' }));
    yield put(getRecurringPaymentAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not scheduled recurring payment' }));
    yield put(createRecurringPaymentFailAction());
  }
}

function* deleteRecurringPayment(action: PayloadAction<string>) {
  try {
    yield put(showConfirmDialogAction({ message: 'Are you sure you want to remove this recurring payment?' }));
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(ApiClientSingleton.getInstance().user_DeleteRecurringPayment, action.payload);
      yield put(deleteRecurringPaymentSuccessAction());
      yield put(getRecurringPaymentAction());
      yield put(showToastMessageAction({ message: 'Recurring payment removed successfully' }));
    }
  } catch (error: any) {
    yield put(deleteRecurringPaymentFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Could not remove recurring payment' }));
  }
}

function* listTenantTasks(action: PayloadAction<{ leaseId: string; selectedTaskId?: string }>) {
  try {
    const response: ListTenantTasksHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().tenant_ListTenantTasks,
      action.payload.leaseId,
    );
    if (response) {
      const jsonTasks: ITenantTask[] = response.toJSON().results;
      const reduxTasks: ReduxTenantTask[] = jsonTasks.map((task) => mapReduxTenantTask(task));
      yield put(listTenantTasksSuccessAction(reduxTasks));
      if (action.payload.selectedTaskId) {
        yield put(setSelectedMaintenanceRequestAction(action.payload.selectedTaskId));
      }
    } else {
      yield put(
        showToastMessageAction({
          message: 'Something went wrong fetching maintenance requests',
          severity: 'error',
        }),
      );
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'could not fetch maintenance requests' }));
    yield put(listTenantTasksFailAction());
  }
}

function* createTaskFromTenantRequest(
  action: PayloadAction<{ leaseId: string; body: IAddTaskFromTenantRequestHandlerRequest }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().tenant_AddTaskFromTenantRequest,
      action.payload.leaseId,
      new AddTaskFromTenantRequestHandlerRequest(action.payload.body),
    );
    yield put(createTaskFromTenantRequestSuccessAction());
    yield put(showToastMessageAction({ message: 'Maintenance request created successfully!', severity: 'success' }));
    yield put(listTenantTasksAction({ leaseId: action.payload.leaseId }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not create maintenance request' }));
    yield put(createTaskFromTenantRequestFailAction());
  }
}

function* updateTaskByTenant(action: PayloadAction<{ leaseId: string; taskId: string; body: ReduxTenantTask }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().tenant_UpdateTaskFromTenant,
      action.payload.taskId,
      new UpdateTenantTaskHandlerRequest({
        name: action.payload.body.name,
        description: action.payload.body.description,
        taskType: action.payload.body.taskType,
        comments: action.payload.body.comments?.map((comment) => mapApiComment(comment)),
      }),
    );
    yield put(showToastMessageAction({ message: 'Maintenance request updated successfully!', severity: 'success' }));
    yield put(updateTenantTaskSuccessAction());
    yield put(listTenantTasksAction({ leaseId: action.payload.leaseId }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not update maintenance request' }));
    yield put(updateTenantTaskFailAction());
  }
}

function* uploadDocToTaskFromTenant(action: PayloadAction<{ taskId: string; leaseId: string; file: File }>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().tasks_UploadDocument, action.payload.taskId, {
      data: action.payload.file,
      fileName: action.payload.file.name,
    });
    yield put(
      showToastMessageAction({
        message: 'Document successfully uploaded to maintenance request',
        severity: 'success',
      }),
    );
    yield put(uploadTenantDocToTaskSuccessAction());
    yield put(listTenantTasksAction({ leaseId: action.payload.leaseId, selectedTaskId: action.payload.taskId }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not upload document to maintenance request' }));
    yield put(uploadTenantDocToTaskFailAction());
  }
}

function* addTenantCommentToTask(action: PayloadAction<{ taskId: string; leaseId: string; comment: string }>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().tasks_AddComment, action.payload.taskId, action.payload.comment);
    yield put(
      showToastMessageAction({ message: 'Comment successfully added to maintenance request', severity: 'success' }),
    );
    yield put(listTenantTasksAction({ leaseId: action.payload.leaseId, selectedTaskId: action.payload.taskId }));
    yield put(addTenantCommentToTaskSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not add comment to maintenance request' }));
    yield put(addTenantCommentToTaskFailAction());
  }
}

function* initializeCreditCardPayment(
  action: PayloadAction<{ leaseId: string; requestedAmount: number; description: string }>,
) {
  try {
    const response: InitiateAuthorizeNetPaymentHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().lease_InitializeCreditCardPayment,
      action.payload.leaseId,
      new InitiateAuthorizeNetPaymentHandlerRequest({
        amount: action.payload.requestedAmount,
        description: action.payload.description,
      }),
    );
    if (response) {
      yield put(initializeCreditCardPaymentSuccessAction(response.toJSON()));
    } else {
      yield put(
        showToastMessageAction({
          message: 'Something went wrong initializing payment',
          severity: 'error',
        }),
      );
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not initialize credit card payment' }));
    yield put(initializeCreditCardPaymentFailAction());
  }
}

//this function ensures that the backend gets the payment details, since the AuthorizeNet callback is not guaranteed to be successful
function* completeAuthorizeNetPayment(
  action: PayloadAction<{
    leaseId: string;
    body: {
      leaseId?: string;
      totalAmount?: number;
      merchantReferenceId?: string | undefined;
      transactionId?: string | undefined;
      dateCompleted?: string | undefined;
    };
  }>,
) {
  try {
    const body: CompleteAuthorizeNetPaymentRequest = new CompleteAuthorizeNetPaymentRequest({
      ...action.payload.body,
      dateCompleted: toStandardDate(action.payload.body.dateCompleted),
    });
    yield apiCall(ApiClientSingleton.getInstance().lease_CompleteAuthorizeNetPayment, action.payload.leaseId, body);
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not complete credit card callback' }));
  }
}

function* requestMoveOutDate(action: PayloadAction<{ leaseId: string; moveOutDate: ReduxDate | undefined }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().tenant_RequestMoveOutDate,
      action.payload.leaseId,
      new RequestMoveOutHandlerRequest({
        moveOutDate: new Optional_DateOnly({
          value: action.payload.moveOutDate,
        }),
      }),
    );
    yield put(showToastMessageAction({ message: 'Move out date requested', severity: 'success' }));
    yield put(requestMoveOutDateSuccessAction(action.payload.moveOutDate));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Could not request move out date' }));
    yield put(requestMoveOutDateFailAction());
  }
}

export function* tenantSagas() {
  yield all([
    takeEvery(getTenantLeaseByIdAction.type, getTenantLeaseById),

    takeEvery(getUserBankAccountsAction.type, getBankAccountsByUserId),
    takeEvery(initiateTenantPaymentAction.type, initiateTenantPayment),
    takeLatest(deleteTenantAccountAction.type, deleteTenantAccount),
    takeLatest(makeAccountDefaultAction.type, makeAccountDefault),
    takeLatest(getRecurringPaymentAction.type, getRecurringPayment),
    takeLatest(createRecurringPaymentAction.type, createRecurringPayment),
    takeLatest(deleteRecurringPaymentAction.type, deleteRecurringPayment),
    takeLatest(listTenantTasksAction.type, listTenantTasks),
    takeLatest(createTaskFromTenantRequestAction.type, createTaskFromTenantRequest),
    takeLatest(updateTenantTaskAction.type, updateTaskByTenant),
    takeLatest(uploadTenantDocToTaskAction.type, uploadDocToTaskFromTenant),
    takeLatest(addTenantCommentToTaskAction.type, addTenantCommentToTask),
    takeLatest(initializeCreditCardPaymentAction.type, initializeCreditCardPayment),
    takeLatest(requestMoveOutDateAction.type, requestMoveOutDate),
    takeLatest(completeAuthorizeNetPaymentAction.type, completeAuthorizeNetPayment),
  ]);
}
