import {
  ApplicationStatus,
  ICoSignerApplication,
  ICreateCoSignerApplicationData,
  ICreateOtherAdultApplicationData,
  ICreatePrimaryApplicationData,
  IOtherAdultApplication,
  IPrimaryApplication,
  IQueryExpression,
  QueryGroupOperator,
  QueryOperator,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  mapReduxComment,
  mapReduxDocument,
  mapReduxHousingHistory,
  mapReduxPerson,
  ReduxComment,
  ReduxDate,
  ReduxDocument,
  ReduxHousingHistory,
  ReduxPerson,
  toReduxDate,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export type CreateApplicationData = Omit<ICreatePrimaryApplicationData, 'requestedMoveInDate'> &
  ICreateOtherAdultApplicationData &
  ICreateCoSignerApplicationData & { requestedMoveInDate?: string };

export type ReduxApplication = Omit<
  IAllApplicationType,
  'requestedMoveInDate' | 'birthday' | 'childrenInApartment' | 'comments' | 'documents' | 'housingHistory'
> & {
  requestedMoveInDate?: ReduxDate;
  birthday?: ReduxDate;
  childrenInApartment?: ReduxPerson[];
  comments?: ReduxComment[];
  socialSecurityNo?: string;
  documents?: ReduxDocument[];
  housingHistory?: ReduxHousingHistory[];
};

export const mapReduxApplication = (application: IAllApplicationType): ReduxApplication => {
  return {
    ...application,
    requestedMoveInDate: toReduxDate(application.requestedMoveInDate),
    birthday: toReduxDate(application.birthday),
    childrenInApartment: application.childrenInApartment?.map(mapReduxPerson),
    comments: application.comments?.map(mapReduxComment),
    documents: application.documents?.map(mapReduxDocument),
    housingHistory: application.housingHistory?.map(mapReduxHousingHistory),
  };
};

export type IAllApplicationType = IPrimaryApplication & IOtherAdultApplication & ICoSignerApplication;

export type OtherAdults = {
  [id: string]: ReduxApplication[];
};

export type Cosigner = {
  [id: string]: ReduxApplication[];
};

export const closedStatusesQueryExpression: IQueryExpression = {
  groupOperator: QueryGroupOperator.And,
  parameters: [
    {
      field: 'applicationStatus',
      operator: QueryOperator.Ne,
      value: ApplicationStatus.Denied,
    },
    {
      field: 'applicationStatus',
      operator: QueryOperator.Ne,
      value: ApplicationStatus.Withdrawn,
    },
    {
      field: 'applicationStatus',
      operator: QueryOperator.Ne,
      value: ApplicationStatus.Leased,
    },
  ],
};
