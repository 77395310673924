import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  getMoreNotificationsByUserAction,
  getNotificationConfigurationByAssetIdAction,
  getNotificationConfigurationByAssetIdFailAction,
  getNotificationConfigurationByAssetIdSuccessAction,
  getNotificationsByAssetIdAction,
  getNotificationsByAssetIdFailAction,
  getNotificationsByAssetIdSuccessAction,
  getNotificationsByUserAction,
  getNotificationsByUserFailAction,
  getNotificationsByUserSuccessAction,
  getSupportedRolesAction,
  getSupportedRolesFailAction,
  getSupportedRolesSuccessAction,
  markNotificationAsReadAction,
  markNotificationAsReadFailAction,
  markNotificationAsReadSuccessAction,
  setAssetNotificationFiltersAction,
  setAssetNotificationSortAction,
  setPageNotificationsByAssetAction,
  setPageSizeNotificationsByAssetAction,
  updateNotificationConfigAction,
  updateNotificationConfigFailAction,
  updateNotificationConfigSuccessAction,
} from './notificationsSlice';
import {
  ListCamNotificationByAssetTypeHandlerResponse,
  ListCamNotificationConfigurationHandlerResponse,
  ListCamNotificationHandlerResponse,
  ListCamNotificationSupportedRolesResponse,
  Optional_Guid,
  PaginatedQueryExpression,
  QueryExpression,
  UpdateCamNotificationConfigurationHandlerRequest,
  QueryGroupOperator,
  QueryOperator,
  IQueryParameter,
  IQueryExpression,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { PayloadAction } from '@reduxjs/toolkit';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { mapReduxNotification, mapReduxUserNotifications, ReduxNotificationConfiguration } from './notificationTypes';
import { AssetRouteType } from '@monkeyjump-labs/cam-fe-shared/dist/types/assetTypes';
import { RootState } from '../../../../app/store';
import { convertToQueryExpression } from '../../utils/filteringUtils';

function* getNotificationsByUser() {
  try {
    const { pageSize } = yield select((state: RootState) => state.notifications.globalNotifications);
    const response: ListCamNotificationHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().userInfo_ListNotifications,
      0,
      pageSize,
    );
    if (response.results) {
      yield put(getNotificationsByUserSuccessAction(response.toJSON().results.map(mapReduxUserNotifications)));
    } else {
      yield put(showToastMessageAction({ message: 'could not retrieve user notifications', severity: 'error' }));
    }
  } catch (error: any) {
    yield put(getNotificationsByUserFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem getting user notifications' }));
  }
}

function* reloadAssetNotifications(
  action: PayloadAction<{ assetType: AssetRouteType; assetId: string; currentUserId?: string }>,
) {
  yield put(
    getNotificationsByAssetIdAction({
      assetType: action.payload.assetType,
      assetId: action.payload.assetId,
      currentUserId: action.payload.currentUserId,
    }),
  );
}

function* getNotificationsByAssetId(
  action: PayloadAction<{
    assetType: AssetRouteType;
    assetId: string;
    startingNotificationId?: string;
    currentUserId?: string;
  }>,
) {
  try {
    let notifications: ListCamNotificationByAssetTypeHandlerResponse | undefined = undefined;
    const { page, pageSize, sortBy, sortDirection, filters } = yield select(
      (state: RootState) => state.notifications.assetNotifications,
    );
    const formattedFilters: QueryExpression = convertToQueryExpression(filters);
    const queryParams: IQueryParameter[] = [
      {
        field: 'IsActivityLog',
        operator: QueryOperator.Eq,
        value: 'true',
      },
      ...(action.payload.currentUserId
        ? [
            {
              field: 'NotifiedUsers',
              operator: QueryOperator.Contains,
              value: action.payload.currentUserId,
            },
          ]
        : []),
    ];
    const newExpression: IQueryExpression = {
      groupOperator: QueryGroupOperator.Or,
      parameters: queryParams,
    };

    switch (action.payload.assetType) {
      case 'property':
        notifications = yield apiCall(
          ApiClientSingleton.getInstance().notifications_ListNotificationsForProperty,
          action.payload.assetId,
          action.payload.startingNotificationId,
          new PaginatedQueryExpression({
            groupOperator: QueryGroupOperator.And,
            page,
            pageSize,
            orderBy: sortBy ? [sortBy] : [],
            orderDirection: sortDirection,
            subExpressions: [formattedFilters, newExpression],
          }),
        );
        break;
      case 'building':
        notifications = yield apiCall(
          ApiClientSingleton.getInstance().notifications_ListNotificationsForBuilding,
          action.payload.assetId,
          action.payload.startingNotificationId,
          new PaginatedQueryExpression({
            page,
            pageSize,
            orderBy: sortBy ? [sortBy] : [],
            orderDirection: sortDirection,
            subExpressions: [formattedFilters],
          }),
        );
        break;
      case 'unit':
        notifications = yield apiCall(
          ApiClientSingleton.getInstance().notifications_ListNotificationsForUnit,
          action.payload.assetId,
          action.payload.startingNotificationId,
          new PaginatedQueryExpression({
            page,
            pageSize,
            orderBy: sortBy ? [sortBy] : [],
            orderDirection: sortDirection,
            subExpressions: [formattedFilters],
          }),
        );
        break;
    }
    if (notifications) {
      yield put(
        getNotificationsByAssetIdSuccessAction({
          results: notifications.toJSON().results.map(mapReduxNotification),
          total: notifications.count ?? 0,
          page: notifications.page ?? 0,
        }),
      );
    }
  } catch (error) {
    yield put(getNotificationsByAssetIdFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem getting asset notifications' }));
  }
}

function* getNotificationConfigurationByAssetId(action: PayloadAction<string>) {
  try {
    const response: ListCamNotificationConfigurationHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().notifications_Search,
      action.payload,
    );
    if (response.results) {
      yield put(getNotificationConfigurationByAssetIdSuccessAction(response.toJSON().results));
    } else {
      yield put(
        showToastMessageAction({ message: 'could not retrieve notification configurations', severity: 'error' }),
      );
    }
  } catch (error: any) {
    yield put(getNotificationConfigurationByAssetIdFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem getting asset notification configurations' }));
  }
}

function* markNotificationAsRead(action: PayloadAction<{ notificationId: string; removeFromRedux: boolean }>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().userInfo_MarkNotificationsRead, action.payload.notificationId);
    if (action.payload.removeFromRedux) {
      yield put(markNotificationAsReadSuccessAction(action.payload.notificationId));
    } else yield put(markNotificationAsReadSuccessAction(undefined));
  } catch (error: any) {
    yield put(markNotificationAsReadFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem marking notification as read' }));
  }
}

function* updateNotificationConfig(
  action: PayloadAction<{ id: string; propertyId: string; body: ReduxNotificationConfiguration }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().notifications_Update,
      action.payload.id,
      new UpdateCamNotificationConfigurationHandlerRequest({
        ...action.payload.body,
        taskConfigurationId: new Optional_Guid({ value: action.payload.body.taskConfiguration?.id }),
      }),
    );
    yield put(updateNotificationConfigSuccessAction());
    yield put(showToastMessageAction({ message: 'Configuration updated successfully!', severity: 'success' }));
    yield action.payload.propertyId && put(getNotificationConfigurationByAssetIdAction(action.payload.propertyId));
  } catch (error) {
    yield put(updateNotificationConfigFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem updating notification configuration' }));
  }
}

function* getSupportedRoles() {
  try {
    const response: ListCamNotificationSupportedRolesResponse = yield apiCall(
      ApiClientSingleton.getInstance().notifications_ListSupportedRoles,
    );
    if (response) {
      yield put(getSupportedRolesSuccessAction(response.toJSON()));
    } else {
      yield put(
        showToastMessageAction({
          message: 'Could not get notification supported roles',
          severity: 'error',
        }),
      );
    }
  } catch (error: any) {
    yield put(getSupportedRolesFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem getting supported roles' }));
  }
}

export function* notificationsSagas() {
  yield all([
    takeLatest(getNotificationsByUserAction.type, getNotificationsByUser),
    takeLatest(getMoreNotificationsByUserAction.type, getNotificationsByUser),
    takeLatest(getNotificationsByAssetIdAction.type, getNotificationsByAssetId),
    takeLatest(setAssetNotificationFiltersAction.type, getNotificationsByAssetId),
    takeLatest(setAssetNotificationSortAction.type, getNotificationsByAssetId),
    takeLatest(getNotificationConfigurationByAssetIdAction.type, getNotificationConfigurationByAssetId),
    takeLatest(markNotificationAsReadAction.type, markNotificationAsRead),
    takeLatest(updateNotificationConfigAction.type, updateNotificationConfig),
    takeLatest(getSupportedRolesAction.type, getSupportedRoles),
    takeLatest(setPageNotificationsByAssetAction.type, reloadAssetNotifications),
    takeLatest(setPageSizeNotificationsByAssetAction.type, reloadAssetNotifications),
  ]);
}
