import { IconButton, TextField, useTheme } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useCommunication } from '../redux/communicationSlice';

export type MessageThreadViewProps = {
  onSendMessage: (message: string) => void;
  messagesEndRef: React.MutableRefObject<HTMLDivElement>;
  messageBoxRef: React.MutableRefObject<HTMLDivElement>;
  onLoadMoreMessages: () => void;
  hasMoreMessages: boolean;
};

export const MessageThreadView: FC<MessageThreadViewProps> = ({
  onSendMessage,
  messagesEndRef,
  messageBoxRef,
  onLoadMoreMessages,
  hasMoreMessages,
}) => {
  const { smsMessages } = useCommunication();
  const [message, setMessage] = useState('');
  const theme = useTheme();

  useEffect(() => {
    if (messageBoxRef?.current) messageBoxRef.current.addEventListener('scroll', onLoadMoreMessages);

    return () => {
      if (messageBoxRef?.current) messageBoxRef.current.removeEventListener('scroll', onLoadMoreMessages);
    };
  }, [onLoadMoreMessages]);

  //TODO: export text thread functionality

  //TODO: navigation to lease from thread (metadata above message chain includes user info and current lease ID?)

  //TODO: divider for day breaks, timestamps on each message, sender of message (on property side) in tooltip

  const onMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = () => {
    onSendMessage(message);
    setMessage('');
  };

  return (
    <div
      style={{
        width: '100%',
        maxHeight: 500,
        overflowY: 'scroll',
        overflowX: 'hidden',
        border: '.1rem solid gray',
      }}
    >
      <Stack direction="column" sx={{ height: '100%' }}>
        <Box
          style={{
            borderRadius: '6px',
            boxSizing: 'border-box',
            padding: '2px',
            overflowY: 'auto',
            flexGrow: 1,
          }}
          ref={messageBoxRef}
        >
          {hasMoreMessages && <div style={{ textAlign: 'center' }}>Loading More Messages</div>}
          {(smsMessages?.value || []).map((sms) => (
            <div
              key={sms.id}
              style={{
                width: '100%',
                textAlign: sms.incoming ? 'left' : 'right',
              }}
            >
              <div
                style={
                  sms.incoming
                    ? {
                        marginRight: '15%',
                        marginLeft: '2px',
                        backgroundColor: theme.palette.primary.light,
                        position: 'relative',
                        borderRadius: '20px',
                        padding: '8px 15px',
                        marginTop: '5px',
                        marginBottom: '5px',
                        display: 'inline-block',
                      }
                    : {
                        textAlign: 'right',
                        color: 'white',
                        marginLeft: '15%',
                        marginRight: '2px',
                        background: theme.palette.primary.dark,
                        backgroundAttachment: 'fixed',
                        position: 'relative',
                        borderRadius: '20px',
                        padding: '8px 15px',
                        marginTop: '5px',
                        marginBottom: '5px',
                        display: 'inline-block',
                      }
                }
              >
                {sms.message}
              </div>
            </div>
          ))}
          <div style={{ float: 'left', clear: 'both' }} ref={messagesEndRef} />
        </Box>
        {
          <Box>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              autoComplete={'off'}
              onChange={onMessageChange}
              onKeyDown={onKeyDown}
              value={message}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={sendMessage} edge="end" color="primary" size="small">
                    <SendIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
        }
      </Stack>
    </div>
  );
};
