import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { tryFormatDate } from '../utils/TryFormatDate';
import { ReduxComment } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material';

export type CommentListProps = {
  comments: ReduxComment[];
  onAddComment: (comment: string) => void;
  loading: boolean;
};

export const CommentList: FC<CommentListProps> = ({ comments, onAddComment, loading }) => {
  const theme = useTheme();
  const [newComment, setNewComment] = React.useState<string>('');
  const [dirty, setDirty] = useState(false);
  const highlightedCommentId = new URLSearchParams(location.search).get('selected');
  const handleAddComment = () => {
    onAddComment(newComment);
    setNewComment('');
    setDirty(false);
  };

  return (
    // <form onSubmit={handleAddComment} autoComplete="off">
    <>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: '1rem' }}>
        <TextField
          fullWidth
          id="add-comment"
          label="Add Comment"
          value={newComment}
          onChange={(event) => {
            setNewComment(event.target.value);
            if (event.target.value === '') {
              setDirty(false);
            } else {
              setDirty(true);
            }
          }}
        />
        <LoadingButton
          sx={{ width: 200, margin: '1rem' }}
          variant={'contained'}
          disabled={!dirty}
          onClick={handleAddComment}
          loading={loading}
        >
          Add Comment
        </LoadingButton>
      </Box>
      {comments.length > 0 && (
        <>
          <Divider sx={{ marginTop: 4, marginBottom: 2 }} />
          <List sx={{ width: '100%', backgroundColor: 'background.paper' }}>
            {comments.map((comment) => {
              return (
                <React.Fragment key={comment.createdOn && comment.content + comment.createdOn}>
                  <ListItem
                    style={{
                      backgroundColor: highlightedCommentId === comment.id ? theme.palette.warning.main : 'inherit',
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '1.2rem' }}
                      primary={comment.content}
                      secondary={
                        <Typography
                          variant="body2"
                          color="text.primary"
                          sx={{ display: 'flex', justifyContent: 'right', padding: '1rem' }}
                        >
                          {comment.createdByName ? comment.createdByName + ' ' : ''}
                          {comment.createdOn ? ' — ' + tryFormatDate(comment.createdOn) : ''}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              );
            })}
          </List>
        </>
      )}
    </>
    // </form>
  );
};
