import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { parseQueryString } from './helpers';
import { currencyFormatter } from '../../utils/currencyFormatter';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { completeAuthorizeNetPaymentAction } from '../../../tenantPortal/redux/tenantSlice';
import { toReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

interface AuthorizeNetIFrameProps {
  token: string;
  fees: number;
  amount: number;
  onPaymentSuccess: (result: any) => void;
  onCancel: () => void;
  selectedLeaseId?: string;
}

export const AuthorizeNetIFrame: React.FC<AuthorizeNetIFrameProps> = ({
  token,
  fees,
  amount,
  onPaymentSuccess,
  onCancel,
  selectedLeaseId,
}) => {
  const dispatch = useDispatch();
  const [isFrameVisible, setFrameVisible] = useState<boolean>(false);

  const handleCancel = () => {
    setFrameVisible(false);
    onCancel();
  };

  const handleSuccess = (resultStr: string) => {
    const result = JSON.parse(resultStr);
    onPaymentSuccess(result);
    if (!selectedLeaseId) return;
    const body = {
      leaseId: selectedLeaseId,
      totalAmount: Number(result['totalAmount']),
      merchantReferenceId: result['refId'],
      transactionId: result['transId'],
      dateCompleted: toReduxDate(result['dateTime']),
    };
    dispatch(
      completeAuthorizeNetPaymentAction({
        leaseId: selectedLeaseId,
        body: body,
      }),
    );
  };

  const rows = [
    { description: 'Your Payment', amount: currencyFormatter.format(amount ?? 0) },
    { description: 'Technology Convenience fee', amount: currencyFormatter.format(fees ?? 0) },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  window.AuthorizeNetIFrame = window.AuthorizeNetIFrame || {};
  window.AuthorizeNetIFrame.onReceiveCommunication = (str: string) => {
    console.log(`Received string was ${str}`);
    const params = parseQueryString(str);
    switch (params['action']) {
      case 'successfulSave':
        break;
      case 'cancel':
        handleCancel();
        break;
      case 'transactResponse':
        const response = params['response'] as string;
        handleSuccess(response);
        break;
      // case 'resizeWindow':
      //   const width = params['width'] as string;
      //   const height = params['height'] as string;
      //   if (width && height) {
      //     const w = parseInt(width);
      //     const h = parseInt(height);
      //   }
      //   break;
      default:
        break;
    }
  };

  const handleFormSubmit = () => {
    setFrameVisible(true);
  };

  const authorizeNetUrl = process.env.REACT_APP_AUTHORIZENET_URL ?? 'https://test.authorize.net/payment/payment';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h4'} align={'center'}>
          Summary of Credit Card Payment
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} width={'80%'} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell align="right">Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.description}>
                  <StyledTableCell component="th" scope="row">
                    {row.description}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.amount}</StyledTableCell>
                </StyledTableRow>
              ))}
              <TableRow>
                <TableCell>
                  <Typography fontWeight={'bold'}>Total</Typography>
                </TableCell>
                <TableCell variant={'head'} align={'right'}>
                  <Typography fontWeight={'bold'}>{currencyFormatter.format((amount ?? 0) + (fees ?? 0))}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        {isFrameVisible && (
          <iframe
            id="load_payment"
            className="embed-responsive-item"
            name="load_payment"
            width="700px"
            height="500px"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        )}

        <form
          id="send_hptoken"
          action={authorizeNetUrl}
          method="post"
          target="load_payment"
          onSubmit={handleFormSubmit}
        >
          <input type="hidden" name="token" value={token} />
          {!isFrameVisible && (
            <Stack direction={'row'} spacing={2} justifyContent={'end'}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="submit" value="Submit" variant={'contained'}>
                Continue
              </Button>
            </Stack>
          )}
        </form>
      </Grid>
    </Grid>
  );
};
