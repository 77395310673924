import React, { FC, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { StripedDataGrid } from '../../datagrids/StripedDataGrid';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import { tryFormatDate } from '../../utils/TryFormatDate';
import Typography from '@mui/material/Typography';
import { ReduxEmail, ReduxEmailThread } from '../redux/communicationTypes';
import Button from '@mui/material/Button';
import { addAttachmentToAssociatedAssetAction, markEmailThreadReadAction } from '../redux/communicationSlice';
import { useDispatch } from 'react-redux';
import { EmailParticipantsCell } from './emailTable/EmailParticipantsCell';
import { IncomingCell } from './emailTable/IncomingCell';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AttachmentsCell } from './emailTable/AttachmentsCell';
import { DialogLayout } from '../../dialogs/DialogLayout';
import { ICamAssociation } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ListItem } from '@mui/material';
import List from '@mui/material/List';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { ActionCell } from '../../datagrids/ActionCell';
import { useIcons } from '../../icons/useIcons';

type EmailTableDetailPanelProps = {
  row: ReduxEmailThread;
  onReply: (emailThread: ReduxEmailThread) => void;
  inModal: boolean;
  onOpenAddTask: (id: string) => void;
};

export const EmailThreadDetailView: FC<EmailTableDetailPanelProps> = ({ row, onReply, inModal, onOpenAddTask }) => {
  const dispatch = useDispatch();
  const { getActionIcon, ActionType } = useIcons();
  const [openAddAttachmentDialog, setOpenAddAttachmentDialog] = useState(false);
  const [selectedEmailId, setSelectedEmailId] = useState<string | undefined>();
  const [selectedAttachmentId, setSelectedAttachmentId] = useState<string | undefined>();
  const handleCloseAddAttachmentDialog = () => setOpenAddAttachmentDialog(false);
  const handleOpenAddAttachmentDialog = (attachmentId?: string, emailId?: string) => {
    if (row.associations?.length === 0) {
      dispatch(
        showToastMessageAction({
          message: 'This email thread does not currently have any associations',
          severity: 'warning',
        }),
      );
    } else {
      setSelectedAttachmentId(attachmentId);
      setSelectedEmailId(emailId);
      setOpenAddAttachmentDialog(true);
    }
  };
  useEffect(() => {
    const unreadEmails = row.emails?.find((x) => x.isRead === false);
    if (unreadEmails) {
      row.id && dispatch(markEmailThreadReadAction(row.id));
    }
  }, [row]);

  const handleAddAttachmentToAssociation = (association: ICamAssociation) => {
    if (!row.id || !selectedEmailId || !selectedAttachmentId) {
      dispatch(
        showToastMessageAction({
          message: 'Problem adding attachment to associated asset',
          severity: 'error',
        }),
      );
    } else {
      dispatch(
        addAttachmentToAssociatedAssetAction({
          threadId: row.id,
          emailId: selectedEmailId,
          attachmentId: selectedAttachmentId,
          association: association,
        }),
      );
      handleCloseAddAttachmentDialog();
    }
  };

  function* createActions(params: GridRowParams) {
    if (!params.row.id) return;
    yield (
      <ActionCell
        icon={getActionIcon(ActionType.CreateTask)}
        key={'createTask'}
        label={'Create Task From Email'}
        onClick={() => row.id && onOpenAddTask(row.id)}
      />
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'incoming',
      headerName: '',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => {
        return <IncomingCell {...params} />;
      },
    },
    {
      field: 'participants',
      headerName: 'Participants',
      flex: 0.5,
      type: 'string',
      renderCell: (params: GridRenderCellParams<ReduxEmail>) => {
        return <EmailParticipantsCell handleClick={() => {}} isThread={false} {...params} />;
      },
    },
    {
      field: 'body',
      headerName: 'Message',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ReduxEmail, string>) => {
        const paragraphs = params.value
          ?.split('\n\n')
          .map(
            (paragraph) => paragraph.split('\n').join(' '), // Join lines that are likely wrapped, within a paragraph
          )
          .join('\n\n'); // Rejoin paragraphs with double new lines
        return (
          <Box sx={{ overflow: 'hidden', height: '100%' }}>
            <Typography
              variant="body2"
              sx={{
                whiteSpace: 'pre-line',
              }}
            >
              {paragraphs}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'dateTimeSent',
      headerName: 'Date',
      flex: 0.25,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <Box sx={{ height: '100%', verticalAlign: 'text-top' }}>{tryFormatDate(params.value)}</Box>
      ),
    },
    {
      field: 'attachments',
      renderHeader: () => {
        return <AttachFileIcon />;
      },
      align: 'center',
      headerAlign: 'center',
      flex: 0.25,
      renderCell: (params) => {
        return (
          <AttachmentsCell
            associations={row.associations ?? []}
            {...params}
            threadId={row.id}
            onOpenAddAttachmentDialog={handleOpenAddAttachmentDialog}
          />
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      align: 'center',
      headerAlign: 'center',
      flex: 0.25,
      getActions: (params: GridRowParams) => Array.from(createActions(params)),
    },
  ];
  return (
    <Stack sx={{ py: 2, height: 0.75, boxSizing: 'border-box', backgroundColor: 'lightgray' }} direction="column">
      <Paper sx={{ flex: 1, mx: 'auto', width: '80%', minHeight: '80%', p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 0.75 }}>
          <StripedDataGrid
            disableRowGrouping
            rows={row.emails ?? []}
            columns={columns}
            autoHeight
            hideFooter
            getRowClassName={(params) => (params.row.incoming ? 'incoming' : 'outgoing')}
            getRowHeight={() => 'auto'}
            sx={{
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
            }}
          />
          {!inModal && (
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
              <Button variant={'contained'} onClick={() => onReply(row)}>
                Reply
              </Button>
            </Box>
          )}
        </Stack>
      </Paper>
      <DialogLayout
        title={'Add Attachment to Association'}
        onClose={handleCloseAddAttachmentDialog}
        open={openAddAttachmentDialog}
      >
        <List>
          {row.associations?.map((a) => (
            <ListItem key={a.associatedId} sx={{ width: '100%' }}>
              <IconButton onClick={() => handleAddAttachmentToAssociation(a)}>
                <ListItemIcon sx={{ mr: '-2rem' }}>
                  <Tooltip title={'Add to email thread Association'}>
                    <AddIcon />
                  </Tooltip>
                </ListItemIcon>
              </IconButton>
              <Typography>{a.label}</Typography>
            </ListItem>
          ))}
        </List>
      </DialogLayout>
    </Stack>
  );
};
