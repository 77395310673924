import {
  Filter,
  initInteractable,
  initLoadable,
  initPageableCollection,
  Interactable,
  Loadable,
  PageableCollection,
  SortDirection,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import {
  AssetType,
  AssociationChildType,
  AssociationType,
  DetailAssociationType,
  IAddDetailHandlerRequest,
  IAddSubDetailHandlerRequest,
  ICamAssociation,
  IDetailDto,
  IDetailObservation,
  IGroupedExpenseItem,
  ITaskDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {
  GroupedDetailLists,
  mapReduxDetail,
  mapReduxDetailObservation,
  ReduxDetail,
  ReduxFollowUpNote,
} from './detailTypes';
import { ReduxExpense } from '../../expenses/redux/expenseData';
import { areAssociationsEqual } from '../../utils/areAssociationsEqual';
import { getUpdatedTaskAssociationLabel, getUpdatedTaskAssociationStatus } from '../../utils/signalRUtils';

export interface DetailsState {
  details: PageableCollection<ReduxDetail>;
  assetGroups: Loadable<IGroupedExpenseItem[]>;
  groupedByAssetLists: GroupedDetailLists;
  assetGroupingInfo: {
    isGrouped: boolean;
    expandedRowIds: string[];
  };
  groupedByParentDetailLists: GroupedDetailLists;
  selectedDetail: Interactable<ReduxDetail>;
  selectedSubDetails: PageableCollection<ReduxDetail>;
}

const initialState: DetailsState = {
  details: initPageableCollection<ReduxDetail>([]),
  assetGroups: initLoadable<IGroupedExpenseItem[]>(),
  groupedByAssetLists: {},
  assetGroupingInfo: {
    isGrouped: false,
    expandedRowIds: [],
  },
  groupedByParentDetailLists: {},
  selectedDetail: initInteractable<ReduxDetail>(),
  selectedSubDetails: initPageableCollection<ReduxDetail>([]),
};

const getUpdatedDetails = (details: ReduxDetail[], task: ITaskDto) => {
  const updatedDetails: ReduxDetail[] = [];
  for (const detail of details) {
    const associations = [...(detail.associations ?? [])];

    for (const association of associations ?? []) {
      if (association.associationType !== AssociationType.Task || association.associatedId !== task.id) continue;

      association.label = getUpdatedTaskAssociationLabel(task);
      association.status = getUpdatedTaskAssociationStatus(task);
    }

    const updatedDetail = { ...detail, associations: associations };

    updatedDetails.push(updatedDetail);
  }

  return updatedDetails;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const detailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    getDetailsAction: (
      state,
      action: PayloadAction<{
        associationType: DetailAssociationType;
        associatedId: string;
        page: number;
        pageSize: number;
        sortDirection: SortDirection | undefined;
        sortBy: keyof ReduxDetail | undefined;
        filters: Filter<ReduxDetail>[] | undefined;
        parentId?: string;
        includeClosed: boolean;
      }>,
    ) => {
      if (!!action.payload.parentId) {
        state.selectedSubDetails.loading = true;
        state.selectedSubDetails.loaded = false;
      } else {
        state.details.loading = true;
        state.details.loaded = false;
      }
    },
    getDetailsSuccessAction: (
      state,
      action: PayloadAction<{
        details: ReduxDetail[];
        page: number;
        pageSize: number;
        totalCount: number;
        isSubDetails: boolean;
      }>,
    ) => {
      if (action.payload.isSubDetails) {
        state.selectedSubDetails.loading = false;
        state.selectedSubDetails.loaded = true;

        state.selectedSubDetails.value = action.payload.details;
        state.selectedSubDetails.page = action.payload.page;
        state.selectedSubDetails.pageSize = action.payload.pageSize;
        state.selectedSubDetails.totalCount = action.payload.totalCount;
      } else {
        state.details.loading = false;
        state.details.loaded = true;

        state.details.value = action.payload.details;
        state.details.page = action.payload.page;
        state.details.pageSize = action.payload.pageSize;
        state.details.totalCount = action.payload.totalCount;
      }
    },
    getDetailsFailedAction: (state, action: PayloadAction<{ isSubDetails: boolean }>) => {
      if (action.payload.isSubDetails) {
        state.selectedSubDetails.loading = false;
        state.selectedSubDetails.loaded = false;
      } else {
        state.details.loading = false;
        state.details.loaded = false;
      }
    },
    getDetailGroupsAction: (
      state,
      action: PayloadAction<{ assetType: AssetType; assetId: string; includeClosed: boolean }>,
    ) => {
      state.assetGroups.loading = true;
      state.assetGroups.loaded = false;
    },
    getDetailGroupsSuccessAction: (state, action: PayloadAction<IGroupedExpenseItem[]>) => {
      state.assetGroups.loaded = true;
      state.assetGroups.loading = false;
      state.assetGroups.value = action.payload;
    },
    getDetailGroupsFailAction: (state) => {
      state.assetGroups.loading = false;
      state.assetGroups.loaded = false;
    },
    getDetailGroupByAssetIdAction: (
      state,
      action: PayloadAction<{
        associatedId: string;
        associationType: DetailAssociationType;
        includeClosed: boolean;
        page: number;
        pageSize: number;
        sortDirection: SortDirection | undefined;
        sortBy: keyof ReduxDetail | undefined;
        filters: Filter<ReduxDetail>[] | undefined;
      }>,
    ) => {
      if (!state.groupedByAssetLists[action.payload.associatedId]) {
        state.groupedByAssetLists[action.payload.associatedId] = initPageableCollection<ReduxExpense>();
      }
      state.groupedByAssetLists[action.payload.associatedId].loading = true;
      state.groupedByAssetLists[action.payload.associatedId].loaded = false;
      state.groupedByAssetLists[action.payload.associatedId].page = action.payload.page;
      state.groupedByAssetLists[action.payload.associatedId].pageSize = action.payload.pageSize;
      state.groupedByAssetLists[action.payload.associatedId].associationType = action.payload.associationType;
      state.groupedByAssetLists[action.payload.associatedId].filters = action.payload.filters;
      state.groupedByAssetLists[action.payload.associatedId].sortBy = action.payload.sortBy;
      state.groupedByAssetLists[action.payload.associatedId].sortDirection = action.payload.sortDirection;
    },
    getDetailGroupByAssetIdSuccessAction: (
      state,
      action: PayloadAction<{ id: string; body: { details: ReduxDetail[]; totalCount: number } }>,
    ) => {
      if (!state.groupedByAssetLists[action.payload.id]) {
        state.groupedByAssetLists[action.payload.id] = initPageableCollection<ReduxExpense>();
      }
      state.groupedByAssetLists[action.payload.id].loading = false;
      state.groupedByAssetLists[action.payload.id].loaded = true;
      state.groupedByAssetLists = {
        ...state.groupedByAssetLists,
        [action.payload.id]: {
          ...state.groupedByAssetLists[action.payload.id],
          value: action.payload.body.details,
          totalCount: action.payload.body.totalCount,
        },
      };
    },
    getDetailGroupByAssetIdFailAction: (state, action: PayloadAction<{ id: string }>) => {
      if (!state.groupedByAssetLists[action.payload.id]) {
        state.groupedByAssetLists[action.payload.id] = initPageableCollection<ReduxExpense>();
      }
      state.groupedByAssetLists[action.payload.id].loading = false;
      state.groupedByAssetLists[action.payload.id].loaded = false;
      state.groupedByAssetLists[action.payload.id].value = undefined;
    },
    toggleIsGroupedAction: (state, action: PayloadAction<boolean>) => {
      state.assetGroupingInfo.isGrouped = action.payload;
    },
    getDetailGroupByParentIdAction: (
      state,
      action: PayloadAction<{
        associatedId: string;
        associationType: DetailAssociationType;
        parentId: string;
        includeClosed: boolean;
        page: number;
        pageSize: number;
        sortDirection: SortDirection | undefined;
        sortBy: keyof ReduxDetail | undefined;
        filters: Filter<ReduxDetail>[] | undefined;
      }>,
    ) => {
      if (!state.groupedByParentDetailLists[action.payload.parentId]) {
        state.groupedByParentDetailLists[action.payload.parentId] = initPageableCollection<ReduxExpense>();
      }
      state.groupedByParentDetailLists[action.payload.parentId].loading = true;
      state.groupedByParentDetailLists[action.payload.parentId].loaded = false;
      state.groupedByParentDetailLists[action.payload.parentId].page = action.payload.page;
      state.groupedByParentDetailLists[action.payload.parentId].pageSize = action.payload.pageSize;
      state.groupedByParentDetailLists[action.payload.parentId].associationType = action.payload.associationType;
      state.groupedByParentDetailLists[action.payload.parentId].filters = action.payload.filters;
      state.groupedByParentDetailLists[action.payload.parentId].sortBy = action.payload.sortBy;
      state.groupedByParentDetailLists[action.payload.parentId].sortDirection = action.payload.sortDirection;
    },
    getDetailGroupByParentIdSuccessAction: (
      state,
      action: PayloadAction<{ id: string; body: { details: ReduxDetail[]; totalCount: number } }>,
    ) => {
      if (!state.groupedByParentDetailLists[action.payload.id]) {
        state.groupedByParentDetailLists[action.payload.id] = initPageableCollection<ReduxExpense>();
      }
      state.groupedByParentDetailLists[action.payload.id].loading = false;
      state.groupedByParentDetailLists[action.payload.id].loaded = true;
      state.groupedByParentDetailLists = {
        ...state.groupedByParentDetailLists,
        [action.payload.id]: {
          ...state.groupedByParentDetailLists[action.payload.id],
          value: action.payload.body.details,
          totalCount: action.payload.body.totalCount,
        },
      };
    },
    getDetailGroupByParentIdFailAction: (state, action: PayloadAction<{ id: string }>) => {
      if (!state.groupedByParentDetailLists[action.payload.id]) {
        state.groupedByParentDetailLists[action.payload.id] = initPageableCollection<ReduxExpense>();
      }
      state.groupedByParentDetailLists[action.payload.id].loading = false;
      state.groupedByParentDetailLists[action.payload.id].loaded = false;
      state.groupedByParentDetailLists[action.payload.id].value = undefined;
    },
    addDetailAction: (
      state,
      action: PayloadAction<{
        associationType: DetailAssociationType;
        associatedId: string;
        body: IAddDetailHandlerRequest;
      }>,
    ) => {
      state.details.submitting = true;
      state.details.submitted = false;
    },
    addDetailSuccessAction: (state, action: PayloadAction<{ newDetail: ReduxDetail }>) => {
      state.details.submitting = false;
      state.details.submitted = true;

      state.details.value = [...(state.details.value ?? []), action.payload.newDetail];
    },
    addDetailFailedAction: (state) => {
      state.details.submitting = false;
      state.details.submitted = false;
    },
    updateDetailInfoAction: (state, action: PayloadAction<{ body: ReduxDetail }>) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    updateDetailInfoSuccessAction: (state, action: PayloadAction<{ updatedDetail: ReduxDetail }>) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;

      const indexOfExistingDetail = state.details.value?.findIndex((d) => d.id === action.payload.updatedDetail.id);
      if (indexOfExistingDetail !== undefined && indexOfExistingDetail !== -1 && state.details.value) {
        state.details.value[indexOfExistingDetail] = action.payload.updatedDetail;
      }
      if (state.selectedDetail.value !== undefined) {
        state.selectedDetail.value = action.payload.updatedDetail;
      }

      const indexOfExistingSubDetail = state.selectedSubDetails.value?.findIndex(
        (d) => d.id === action.payload.updatedDetail.id,
      );
      if (indexOfExistingSubDetail !== undefined && indexOfExistingSubDetail !== -1 && state.selectedSubDetails.value) {
        state.selectedSubDetails.value[indexOfExistingSubDetail] = action.payload.updatedDetail;
      }

      Object.keys(state.groupedByAssetLists).forEach((key) => {
        const group = state.groupedByAssetLists[key];
        group.value?.forEach((detail, index) => {
          if (detail.id === action.payload.updatedDetail.id && group.value && group.value[index]) {
            group.value[index] = action.payload.updatedDetail;
          }
        });
      });

      Object.keys(state.groupedByParentDetailLists).forEach((key) => {
        const group = state.groupedByParentDetailLists[key];
        group.value?.forEach((detail, index) => {
          if (detail.id === action.payload.updatedDetail.id && group.value && group.value[index]) {
            group.value[index] = action.payload.updatedDetail;
          }
        });
      });
    },
    updateDetailInfoFailedAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    deleteDetailAction: (state, action: PayloadAction<{ detailId: string }>) => {
      state.details.submitting = true;
      state.details.submitted = false;
    },
    deleteDetailSuccessAction: (state, action: PayloadAction<{ detailId: string }>) => {
      state.details.submitting = false;
      state.details.submitted = true;

      const indexOfExistingDetail = state.details.value?.findIndex((d) => d.id === action.payload.detailId);
      if (indexOfExistingDetail !== undefined && indexOfExistingDetail !== -1 && state.details.value) {
        state.details.value.splice(indexOfExistingDetail, 1);
      }

      const indexOfExistingSubDetail = state.selectedSubDetails.value?.findIndex(
        (d) => d.id === action.payload.detailId,
      );
      if (indexOfExistingSubDetail !== undefined && indexOfExistingSubDetail !== -1 && state.selectedSubDetails.value) {
        state.selectedSubDetails.value.splice(indexOfExistingSubDetail, 1);
      }
      // Remove from groupedByAssetLists
      Object.keys(state.groupedByAssetLists).forEach((key) => {
        const group = state.groupedByAssetLists[key];
        if (group.value) {
          state.groupedByAssetLists[key].value = group.value.filter((detail) => detail.id !== action.payload.detailId);
        }
      });

      // Remove from groupedByParentDetailLists
      Object.keys(state.groupedByParentDetailLists).forEach((key) => {
        const group = state.groupedByParentDetailLists[key];
        if (group.value) {
          state.groupedByParentDetailLists[key].value = group.value.filter(
            (detail) => detail.id !== action.payload.detailId,
          );
        }
      });
    },
    deleteDetailFailedAction: (state) => {
      state.details.submitting = false;
      state.details.submitted = false;

      state.selectedDetail.submitted = false;
      state.selectedDetail.submitting = false;
    },
    resetDetailSubmissionAction: (state) => {
      state.details.submitting = false;
      state.details.submitted = false;
      state.details.loading = false;
      state.details.loaded = false;

      state.selectedDetail.submitted = false;
      state.selectedDetail.submitting = false;
      state.selectedDetail.loading = false;
      state.selectedDetail.loaded = false;

      state.selectedSubDetails.submitted = false;
      state.selectedSubDetails.submitting = false;
      state.selectedSubDetails.loading = false;
      state.selectedSubDetails.loaded = false;
    },
    addFollowUpNoteAction: (
      state,
      action: PayloadAction<{
        detailId: string;
        observationId: number;
        content: string;
      }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    addFollowUpNoteSuccessAction: (
      state,
      action: PayloadAction<{
        detailId: string;
        observationId: number;
        createdNote: ReduxFollowUpNote;
      }>,
    ) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
      const observation = state.selectedDetail.value?.observations?.find((o) => o.id === action.payload.observationId);
      if (observation) {
        observation.followUpNotes = [action.payload.createdNote, ...(observation.followUpNotes ?? [])];
      }
    },
    addFollowUpNoteFailedAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    addDetailSpecificationAction: (
      state,
      action: PayloadAction<{
        detailId: string;
        key: string;
        value: string;
      }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    addDetailSpecificationSuccessAction: (
      state,
      action: PayloadAction<{ detailId: string; key: string; value: string }>,
    ) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
      const updatedDetail =
        state.details.value?.find((d) => d.id === action.payload.detailId) ??
        state.selectedSubDetails.value?.find((d) => d.id === action.payload.detailId);
      updatedDetail?.specifications?.push({ key: action.payload.key, value: action.payload.value });
      if (state.selectedDetail.value?.id === action.payload.detailId)
        state.selectedDetail.value?.specifications?.push({
          key: action.payload.key,
          value: action.payload.value,
        });
    },
    addDetailSpecificationFailedAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    deleteDetailSpecificationAction: (state, action: PayloadAction<{ detailId: string; key: string }>) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    deleteDetailSpecificationSuccessAction: (state, action: PayloadAction<{ detailId: string; key: string }>) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
      const updatedDetail = state.details.value?.find((d) => d.id === action.payload.detailId);
      updatedDetail?.specifications?.splice(
        updatedDetail?.specifications?.findIndex((s) => s.key === action.payload.key),
        1,
      );
      state.selectedDetail.value?.specifications?.splice(
        state.selectedDetail.value.specifications.findIndex((s) => s.key === action.payload.key),
        1,
      );
    },
    deleteDetailSpecificationFailedAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    initializeDetailObservationAction: (
      state,
      action: PayloadAction<{
        detailId: string;
        description: string;
      }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    initializeDetailObservationSuccessAction: (
      state,
      action: PayloadAction<{ detailId: string; createdObservation: IDetailObservation }>,
    ) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
      const updatedDetail = state.details.value?.find((d) => d.id === action.payload.detailId);
      updatedDetail?.observations?.unshift(mapReduxDetailObservation(action.payload.createdObservation));
      state.selectedDetail.value?.observations?.unshift(mapReduxDetailObservation(action.payload.createdObservation));
    },
    initializeDetailObservationFailedAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    updateDetailObservationAction: (
      state,
      action: PayloadAction<{ detailId: string; observationId: number; description: string }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    updateDetailObservationSuccessAction: (
      state,
      action: PayloadAction<{ observationId: number; description: string }>,
    ) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
      const updatedObservations = state.selectedDetail.value?.observations?.map((x) => {
        if (x.id === action.payload.observationId) {
          return {
            ...x,
            description: action.payload.description,
          };
        }
        return x;
      });
      if (updatedObservations) {
        state.selectedDetail.value = { ...state.selectedDetail.value, observations: updatedObservations };
      }
    },
    updateDetailObservationFailAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    removeDocFromDetailObservationAction: (
      state,
      action: PayloadAction<{ detailId: string; observationId: number; docId: string }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    removeDocFromDetailObservationSuccessAction: (
      state,
      action: PayloadAction<{ observationId: number; docId: string }>,
    ) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
      const updatedObservations = state.selectedDetail.value?.observations?.map((observation) => {
        if (observation.id === action.payload.observationId) {
          return {
            ...observation,
            attachments: observation.attachments?.filter((attachment) => attachment.id !== action.payload.docId),
          };
        }
        return observation;
      });
      if (state.selectedDetail.value && updatedObservations) {
        state.selectedDetail.value.observations = updatedObservations;
      }
    },

    removeDocFromDetailObservationFailAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    deleteDetailObservationAction: (
      state,
      action: PayloadAction<{
        detailId: string;
        observationId: number;
      }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    deleteDetailObservationSuccessAction: (
      state,
      action: PayloadAction<{ detailId: string; observationId: number }>,
    ) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
      const updatedDetail = state.details.value?.find((d) => d.id === action.payload.detailId);
      updatedDetail?.observations?.splice(
        updatedDetail?.observations?.findIndex((o) => o.id === action.payload.observationId),
        1,
      );
      state.selectedDetail.value?.observations?.splice(
        state.selectedDetail.value?.observations?.findIndex((o) => o.id === action.payload.observationId),
        1,
      );
    },
    deleteDetailObservationFailedAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    getDetailByIdAction: (state, action: PayloadAction<{ detailId: string }>) => {
      state.selectedDetail.loading = true;
      state.selectedDetail.loaded = false;
    },
    getDetailByIdSuccessAction: (state, action: PayloadAction<ReduxDetail>) => {
      state.selectedDetail.loaded = true;
      state.selectedDetail.loading = false;
      state.selectedDetail.value = action.payload;
    },
    getDetailByIdFailAction: (state) => {
      state.selectedDetail.loaded = false;
      state.selectedDetail.loading = false;
    },
    unsetSelectedDetailAction: (state) => {
      state.selectedDetail.value = undefined;
    },
    addSubDetailAction: (
      state,
      action: PayloadAction<{
        detailId: string;
        body: IAddSubDetailHandlerRequest;
      }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    addSubDetailSuccessAction: (state, action: PayloadAction<IDetailDto>) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;

      state.selectedSubDetails.value = [...(state.selectedSubDetails.value ?? []), mapReduxDetail(action.payload)];

      // add to groupedByParentDetailLists
      Object.keys(state.groupedByParentDetailLists).forEach((key) => {
        if (key === action.payload.parentId) {
          const group = state.groupedByParentDetailLists[key];
          if (group.value) {
            group.value.push(mapReduxDetail(action.payload));
          }
        }
      });
    },
    addSubDetailFailAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    addDocumentToDetailAction: (
      state,
      action: PayloadAction<{
        detailId: string;
        file: File;
      }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    addDocumentToDetailSuccessAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
    },
    addDocumentToDetailFailAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    addDocumentToDetailObservationAction: (
      state,
      action: PayloadAction<{
        detailId: string;
        observationId: number;
        file: File;
      }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    addDocumentToDetailObservationSuccessAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
    },
    addDocumentToDetailObservationFailAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    addAssociationToDetailAction: (
      state,
      action: PayloadAction<{
        detailId: string;
        associateWithId: string;
        associateWithType: AssociationType;
        childNumber?: string;
        childAssociationType?: AssociationChildType;
      }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    addAssociationToDetailSuccessAction: (
      state,
      action: PayloadAction<{ detailId: string; newAssociation: ICamAssociation }>,
    ) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
      const indexOfExistingSubDetail = state.selectedSubDetails.value?.findIndex(
        (s) => s.id === action.payload.detailId,
      );
      if (indexOfExistingSubDetail !== undefined && indexOfExistingSubDetail !== -1 && state.selectedSubDetails.value) {
        const updatedDetail = state.selectedSubDetails.value[indexOfExistingSubDetail];
        if (updatedDetail) {
          updatedDetail.associations?.push(action.payload.newAssociation);
        }
      }
      const indexOfExistingDetail = state.details.value?.findIndex((d) => d.id === action.payload.detailId);
      if (indexOfExistingDetail !== undefined && indexOfExistingDetail !== -1 && state.details.value) {
        const updatedDetail = state.details.value[indexOfExistingDetail];
        if (updatedDetail) {
          updatedDetail.associations?.push(action.payload.newAssociation);
        }
      }
      if (state.selectedDetail.value !== undefined) {
        state.selectedDetail.value.associations?.push(action.payload.newAssociation);
      }
      Object.keys(state.groupedByAssetLists).forEach((key) => {
        const group = state.groupedByAssetLists[key];
        group.value?.forEach((detail) => {
          if (detail.id === action.payload.detailId) {
            detail.associations = detail.associations
              ? [...detail.associations, action.payload.newAssociation]
              : [action.payload.newAssociation];
          }
        });
      });
      Object.keys(state.groupedByParentDetailLists).forEach((key) => {
        const group = state.groupedByParentDetailLists[key];
        group.value?.forEach((detail) => {
          if (detail.id === action.payload.detailId) {
            detail.associations = detail.associations
              ? [...detail.associations, action.payload.newAssociation]
              : [action.payload.newAssociation];
          }
        });
      });
    },
    addAssociationToDetailFailAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    deleteAssociationFromDetailAction: (
      state,
      action: PayloadAction<{
        detailId: string;
        association: ICamAssociation;
      }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    deleteAssociationFromDetailSuccessAction: (
      state,
      action: PayloadAction<{
        detailId: string;
        associatedWithId: string;
        childType?: AssociationChildType;
        childNumber?: string;
      }>,
    ) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
      if (state.details.value) {
        const indexOfExistingDetail = state.details.value.findIndex((d) => d.id === action.payload.detailId);
        const detail = state.details.value[indexOfExistingDetail];
        if (detail) {
          const indexOfRemovedAssociation = detail.associations?.findIndex((a) =>
            areAssociationsEqual(a, {
              associationChildType: action.payload.childType,
              associationChildNumber: action.payload.childNumber,
              associatedId: action.payload.associatedWithId,
            }),
          );
          if (indexOfRemovedAssociation !== undefined && indexOfRemovedAssociation !== -1) {
            detail.associations?.splice(indexOfRemovedAssociation, 1);
          }
        }
        if (indexOfExistingDetail !== undefined && indexOfExistingDetail !== -1) {
          state.details.value[indexOfExistingDetail] = detail;
        }
      }
      if (state.selectedSubDetails.value) {
        const indexOfExistingSubDetail = state.selectedSubDetails.value.findIndex(
          (d) => d.id === action.payload.detailId,
        );
        if (indexOfExistingSubDetail !== -1) {
          const subDetail = state.selectedSubDetails.value[indexOfExistingSubDetail];
          const indexOfRemovedAssociation = subDetail.associations?.findIndex((a) =>
            areAssociationsEqual(a, {
              associationChildType: action.payload.childType,
              associationChildNumber: action.payload.childNumber,
              associatedId: action.payload.associatedWithId,
            }),
          );
          if (indexOfRemovedAssociation !== undefined && indexOfRemovedAssociation !== -1) {
            subDetail.associations?.splice(indexOfRemovedAssociation, 1);
          }
        }
      }
      if (state.selectedDetail.value && state.selectedDetail.value.id === action.payload.detailId) {
        const indexOfRemovedAssociation = state.selectedDetail.value.associations?.findIndex((a) =>
          areAssociationsEqual(a, {
            associationChildType: action.payload.childType,
            associationChildNumber: action.payload.childNumber,
            associatedId: action.payload.associatedWithId,
          }),
        );
        if (indexOfRemovedAssociation !== undefined && indexOfRemovedAssociation !== -1) {
          state.selectedDetail.value.associations?.splice(indexOfRemovedAssociation, 1);
        }
      }
      Object.keys(state.groupedByAssetLists).forEach((key) => {
        state.groupedByAssetLists[key].value?.forEach((detail) => {
          if (detail.id === action.payload.detailId) {
            const indexOfRemovedAssociation = detail.associations?.findIndex((a) =>
              areAssociationsEqual(a, {
                associationChildType: action.payload.childType,
                associationChildNumber: action.payload.childNumber,
                associatedId: action.payload.associatedWithId,
              }),
            );
            if (indexOfRemovedAssociation !== undefined && indexOfRemovedAssociation !== -1) {
              detail.associations?.splice(indexOfRemovedAssociation, 1);
            }
          }
        });
      });
      Object.keys(state.groupedByParentDetailLists).forEach((key) => {
        state.groupedByParentDetailLists[key].value?.forEach((detail) => {
          if (detail.id === action.payload.detailId) {
            const indexOfRemovedAssociation = detail.associations?.findIndex((a) =>
              areAssociationsEqual(a, {
                associationChildType: action.payload.childType,
                associationChildNumber: action.payload.childNumber,
                associatedId: action.payload.associatedWithId,
              }),
            );
            if (indexOfRemovedAssociation !== undefined && indexOfRemovedAssociation !== -1) {
              detail.associations?.splice(indexOfRemovedAssociation, 1);
            }
          }
        });
      });
    },
    deleteAssociationFromDetailFailAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    updateDetailStatusesFromTaskAction: (state, action: PayloadAction<{ task: ITaskDto }>) => {
      if (state.details.value) {
        state.details.value = getUpdatedDetails(state.details.value, action.payload.task);
      }

      if (state.selectedSubDetails.value) {
        state.selectedSubDetails.value = getUpdatedDetails(state.selectedSubDetails.value, action.payload.task);
      }

      Object.keys(state.groupedByAssetLists).forEach((key) => {
        if (state.groupedByAssetLists[key]?.value)
          state.groupedByAssetLists[key].value = getUpdatedDetails(
            state.groupedByAssetLists[key].value!,
            action.payload.task,
          );
      });

      Object.keys(state.groupedByParentDetailLists).forEach((key) => {
        if (state.groupedByParentDetailLists[key]?.value)
          state.groupedByParentDetailLists[key].value = getUpdatedDetails(
            state.groupedByParentDetailLists[key].value!,
            action.payload.task,
          );
      });
    },
    updateSubDetailAction: (
      state,
      action: PayloadAction<{
        body: ReduxDetail;
      }>,
    ) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    updateSubDetailSuccessAction: (state, action: PayloadAction<{ updatedDetail: ReduxDetail }>) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
      const index = state.selectedSubDetails.value?.findIndex((d) => d.id === action.payload.updatedDetail.id);

      if (state.selectedSubDetails.value && typeof index === 'number' && index !== -1) {
        state.selectedSubDetails.value[index] = action.payload.updatedDetail;
      }
    },
    updateSubDetailFailAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    reorderDetailObservationsAction: (state, action: PayloadAction<{ detailId: string; observationIds: number[] }>) => {
      state.selectedDetail.submitting = true;
      state.selectedDetail.submitted = false;
    },
    reorderDetailObservationsSuccessAction: (
      state,
      action: PayloadAction<{ detailId: string; observationIds: number[] }>,
    ) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = true;
      const updatedObservations = state.selectedDetail.value?.observations?.sort((a, b) => {
        return action.payload.observationIds.indexOf(a.id!) - action.payload.observationIds.indexOf(b.id!);
      });
      if (updatedObservations) {
        state.selectedDetail.value = { ...state.selectedDetail.value, observations: updatedObservations };
      }
    },
    reorderDetailObservationsFailAction: (state) => {
      state.selectedDetail.submitting = false;
      state.selectedDetail.submitted = false;
    },
    newDetailArrivedAction: (state, action: PayloadAction<ReduxDetail>) => {
      const indexOfExistingDetail = state.details.value?.findIndex((d) => d.id === action.payload.id);
      if (indexOfExistingDetail !== undefined && indexOfExistingDetail !== -1 && state.details.value) {
        state.details.value[indexOfExistingDetail] = action.payload;
      }
      if (state.selectedDetail.value !== undefined) {
        state.selectedDetail.value = action.payload;
      }

      const indexOfExistingSubDetail = state.selectedSubDetails.value?.findIndex((d) => d.id === action.payload.id);
      if (indexOfExistingSubDetail !== undefined && indexOfExistingSubDetail !== -1 && state.selectedSubDetails.value) {
        state.selectedSubDetails.value[indexOfExistingSubDetail] = action.payload;
      }

      Object.keys(state.groupedByAssetLists).forEach((key) => {
        const group = state.groupedByAssetLists[key];
        group.value?.forEach((detail, index) => {
          if (detail.id === action.payload.id && group.value && group.value[index]) {
            group.value[index] = action.payload;
          }
        });
      });

      Object.keys(state.groupedByParentDetailLists).forEach((key) => {
        const group = state.groupedByParentDetailLists[key];
        group.value?.forEach((detail, index) => {
          if (detail.id === action.payload.id && group.value && group.value[index]) {
            group.value[index] = action.payload;
          }
        });
      });
    },
  },
});

export const {
  getDetailsAction,
  getDetailsSuccessAction,
  getDetailsFailedAction,
  getDetailGroupByAssetIdFailAction,
  getDetailGroupByAssetIdSuccessAction,
  getDetailGroupByAssetIdAction,
  getDetailGroupsAction,
  getDetailGroupsFailAction,
  getDetailGroupsSuccessAction,
  toggleIsGroupedAction,
  getDetailGroupByParentIdSuccessAction,
  getDetailGroupByParentIdAction,
  getDetailGroupByParentIdFailAction,
  addDetailAction,
  addDetailSuccessAction,
  addDetailFailedAction,
  updateDetailInfoAction,
  updateDetailInfoSuccessAction,
  updateDetailInfoFailedAction,
  deleteDetailAction,
  deleteDetailSuccessAction,
  deleteDetailFailedAction,
  resetDetailSubmissionAction,
  addFollowUpNoteAction,
  addFollowUpNoteSuccessAction,
  addFollowUpNoteFailedAction,
  addDetailSpecificationAction,
  addDetailSpecificationSuccessAction,
  addDetailSpecificationFailedAction,
  deleteDetailSpecificationAction,
  deleteDetailSpecificationSuccessAction,
  deleteDetailSpecificationFailedAction,
  initializeDetailObservationAction,
  initializeDetailObservationSuccessAction,
  initializeDetailObservationFailedAction,
  deleteDetailObservationAction,
  deleteDetailObservationSuccessAction,
  deleteDetailObservationFailedAction,
  updateDetailObservationAction,
  removeDocFromDetailObservationFailAction,
  removeDocFromDetailObservationAction,
  removeDocFromDetailObservationSuccessAction,
  updateDetailObservationFailAction,
  updateDetailObservationSuccessAction,
  getDetailByIdSuccessAction,
  getDetailByIdFailAction,
  getDetailByIdAction,
  unsetSelectedDetailAction,
  addSubDetailSuccessAction,
  addSubDetailFailAction,
  addSubDetailAction,
  addDocumentToDetailAction,
  addDocumentToDetailFailAction,
  addDocumentToDetailObservationAction,
  addDocumentToDetailObservationFailAction,
  addDocumentToDetailObservationSuccessAction,
  addDocumentToDetailSuccessAction,
  addAssociationToDetailAction,
  addAssociationToDetailSuccessAction,
  addAssociationToDetailFailAction,
  deleteAssociationFromDetailSuccessAction,
  deleteAssociationFromDetailFailAction,
  deleteAssociationFromDetailAction,
  updateDetailStatusesFromTaskAction,
  updateSubDetailAction,
  updateSubDetailSuccessAction,
  updateSubDetailFailAction,
  newDetailArrivedAction,
  reorderDetailObservationsAction,
  reorderDetailObservationsFailAction,
  reorderDetailObservationsSuccessAction,
} = detailsSlice.actions;

export const useDetails = () => useSelector((state: RootState) => state.details);
