import React, { FC } from 'react';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
} from '@mui/x-data-grid-premium';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { EmailType } from '../communications/redux/communicationTypes';

type ToolbarWithEmailProps = {
  emailType: EmailType;
  hideAction: boolean;
  hideFilters?: boolean;
  hideExport?: boolean;
  action: JSX.Element;
  title: string;
};

export const ToolbarWithAction: FC<ToolbarWithEmailProps & GridToolbarProps> = ({
  hideAction,
  printOptions,
  csvOptions,
  action,
  title,
  hideExport,
  hideFilters,
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      {!hideFilters && <GridToolbarFilterButton />}
      <GridToolbarDensitySelector />
      {!hideExport && <GridToolbarExport printOptions={printOptions} csvOptions={csvOptions} />}
      {!hideAction && (
        <>
          <Box sx={{ display: 'flex', flexGrow: 1 }}></Box>
          <Tooltip title={title}>
            <span>{action}</span>
          </Tooltip>
        </>
      )}
    </GridToolbarContainer>
  );
};
