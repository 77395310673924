import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useProperty } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { getVacantUnitsAction, useUnit } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/unitSlice';
import { GridColDef } from '@mui/x-data-grid-premium';
import { StripedDataGrid } from '../../../../_shared/datagrids/StripedDataGrid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { StyledInfoBox } from '../../../../_shared/styledComponents/StyledInfoBox';
import { unitStatusMap } from '../../../../unit/components/unitTabs/unitInfoTabs/UnitStatusSelect';
import {
  IListVacantUnitHandlerSingleResponse,
  UnitStatusDto,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AssetTab, OperationsTab } from '../../../../_shared/tabs/TabTypeEnums';
import Tooltip from '@mui/material/Tooltip';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { format } from 'date-fns';

export const VacantUnitsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedProperty } = useProperty();
  const { vacantUnits } = useUnit();

  useEffect(() => {
    if (!selectedProperty.value?.id) return;
    dispatch(getVacantUnitsAction({ propertyId: selectedProperty.value?.id }));
  }, [selectedProperty.value?.id]);

  const columns: GridColDef<IListVacantUnitHandlerSingleResponse>[] = [
    {
      field: 'name',
      type: 'string',
      headerName: 'Unit Name',
      flex: 2,
      display: 'flex',
      renderCell: (params) => {
        const assetPath =
          (params.row.buildingName ? ` ${params.row.buildingName}` : '') +
          (params.row.unit?.name ? ` / ${params.row.unit?.name}` : '');
        const link = `/assets/unit/${params.row.unit?.id}/${AssetTab.Operations}/${OperationsTab.Tasks}`;
        return (
          <Link sx={{ cursor: 'pointer' }} color="inherit" onClick={() => navigate(link)}>
            <Tooltip title={assetPath ?? ''}>
              <Typography variant="body2">{params.row.buildingName}</Typography>
            </Tooltip>
            <Tooltip title={assetPath ?? ''}>
              <Typography variant="body2">{params.row.unit?.name}</Typography>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      field: 'status',
      type: 'string',
      headerName: 'Unit Status',
      flex: 1.5,
      renderCell: (params) => {
        const statusLabel = unitStatusMap.get(params.row.unit?.status ?? UnitStatusDto.Unavailable);
        if (statusLabel) {
          return <>{statusLabel}</>;
        } else return <></>;
      },
    },
    {
      field: 'leasingStatus',
      type: 'string',
      headerName: 'Leasing Status',
      flex: 1.5,
      renderCell: (params) => {
        if (params.row.unit?.preLeasedOn) {
          return <>Pre-Leased: {format(toStandardDate(toReduxDate(params.row.unit?.preLeasedOn)), 'MMMM dd, yyyy')}</>;
        } else return <></>;
      },
    },
    {
      field: 'price',
      type: 'string',
      headerName: 'Price',
      flex: 1,
      renderCell: (params) => {
        if (params.row.unit?.info?.rentPrice) {
          return <>${params.row.unit.info.rentPrice}</>;
        } else return <></>;
      },
    },
    {
      field: 'bedrooms',
      type: 'string',
      headerName: 'Bedrooms',
      flex: 1,
      renderCell: (params) => {
        if (params.row.unit?.info?.bedrooms) {
          return <>{params.row.unit.info.bedrooms}</>;
        } else return <></>;
      },
    },
    {
      field: 'bathrooms',
      type: 'string',
      headerName: 'Bathrooms',
      flex: 1,
      renderCell: (params) => {
        if (params.row.unit?.info?.bathrooms) {
          return <>{params.row.unit.info.bathrooms}</>;
        } else return <></>;
      },
    },
    {
      field: 'daysVacant',
      type: 'number',
      headerName: 'Days Vacant',
      flex: 1,
    },
  ];

  return (
    <StyledInfoBox label={'Vacancies'}>
      <StripedDataGrid
        disableRowGrouping
        columns={columns}
        autoHeight
        sx={{ minHeight: 500 }}
        rows={vacantUnits.value?.results ?? []}
        loading={vacantUnits.loading}
        getRowClassName={(params) => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
        }}
        rowCount={vacantUnits.value?.results?.length ?? 0}
        pageSizeOptions={[10, 25, 50, 100]}
        getRowId={(params) => params.unit.id}
      ></StripedDataGrid>
    </StyledInfoBox>
  );
};
