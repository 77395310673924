import React, { FC, useEffect, useState } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { EmailAssociationInfo, EmailType, ReplyRecipientType } from '../../redux/communicationTypes';
import { AutocompleteInputChangeReason } from '@mui/material/Autocomplete/Autocomplete';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';
import { ListItem, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

type EmailRecipientsAutocompleteProps = {
  recipients: EmailAssociationInfo[];
  emailRecipientType?: EmailType;
  onUpdateRecipients: (value: string[], isCc?: boolean) => void;
  isCc: boolean;
} & AutocompleteProps<any, any, any, any>;

export const EmailAddressAutocomplete: FC<EmailRecipientsAutocompleteProps> = ({
  renderInput,
  options,
  recipients,
  emailRecipientType,
  onUpdateRecipients,
  isCc,
  ...props
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [emailValue, setEmailValue] = useState<string[]>([]);
  const [recipientInput, setRecipientInput] = useState('');
  const emailSeparators = [',', ';', ' '];
  const activeAddedColor = theme.palette.warning.light;
  const defaultColor = theme.palette.grey[500];
  const removedColor = theme.palette.error.light;

  useEffect(() => {
    const emailStrings = recipients.map((r) => r.label);
    setEmailValue(emailStrings);
  }, [recipients]);

  const updateRecipientInput = (input: string, inputChangeReason: AutocompleteInputChangeReason) => {
    if (emailRecipientType === EmailType.Bulk) {
      dispatch(
        showToastMessageAction({
          message: 'Adding additional email disabled when sending bulk messages',
          severity: 'warning',
        }),
      );
    } else {
      setRecipientInput(input);
      const hasSeparator = emailSeparators.some((separator) => {
        return input.includes(separator);
      });
      const isInputChange = inputChangeReason === 'input';

      if (!hasSeparator || !isInputChange) return;

      setRecipientInput('');
      let updatedValue = input;
      emailSeparators.forEach((separator) => {
        updatedValue = updatedValue.replace(separator, ',');
      });
      const formattedEmails = updatedValue.split(',').filter((email) => email !== '');
      const currentRecipientEmails = recipients.map((x) => x.label);
      onUpdateRecipients([...formattedEmails, ...currentRecipientEmails], isCc);
    }
  };

  return (
    <Autocomplete
      {...props}
      renderInput={renderInput}
      options={options}
      getOptionLabel={(option) => option.label}
      multiple
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          if (option?.label && option?.replyType) {
            const { key, ...restOfTagProps } = getTagProps({ index });
            const backgroundColor =
              option.replyType === ReplyRecipientType.AddedActive
                ? activeAddedColor
                : option.replyType === ReplyRecipientType.Removed
                  ? removedColor
                  : defaultColor;
            const title =
              option.replyType === ReplyRecipientType.AddedActive
                ? 'Added Recipient'
                : option.replyType === ReplyRecipientType.Removed
                  ? 'Previously Removed'
                  : 'Original Recipient';
            return (
              <Tooltip key={key} title={title}>
                <Chip sx={{ backgroundColor: backgroundColor }} label={option.label} {...restOfTagProps} />
              </Tooltip>
            );
          } else {
            const { key, ...restOfTagProps } = getTagProps({ index });
            return <Chip key={key} label={option?.label ?? ''} {...restOfTagProps} />;
          }
        })
      }
      renderOption={(props, option) =>
        option?.replyType === ReplyRecipientType.Removed ? (
          <Tooltip key={option?.label} title={'Previously removed recipient'}>
            <ListItem
              {...props}
              sx={{
                backgroundColor: `${removedColor} !important`,
                '&:hover': {
                  backgroundColor: `${removedColor} !important`,
                },
                borderRadius: theme.shape.borderRadius,
                my: 1,
              }}
            >
              {option?.label}
            </ListItem>
          </Tooltip>
        ) : (
          <ListItem
            {...props}
            sx={{
              borderRadius: theme.shape.borderRadius,
              my: 1,
            }}
            key={option?.label}
          >
            {option?.label}
          </ListItem>
        )
      }
      value={emailValue.map((email) => recipients.find((recipient) => recipient.label === email))}
      inputValue={recipientInput}
      onInputChange={(event, value, reason) => {
        updateRecipientInput(value, reason);
      }}
      onChange={(event, values) => {
        const selectedEmails = values.map((value) => (typeof value === 'string' ? value : value.label));
        setEmailValue(selectedEmails);
        onUpdateRecipients(selectedEmails, isCc);
      }}
      onBlur={() => {
        if (recipientInput !== '') {
          onUpdateRecipients([...emailValue, recipientInput], isCc);
          setRecipientInput('');
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && recipientInput !== '') {
          onUpdateRecipients([...emailValue, recipientInput], isCc);
          setRecipientInput('');
        }
      }}
    />
  );
};
