import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { setNewTaskFromAssociationValuesAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import List from '@mui/material/List';
import { AssociationListItem } from '../../../datagrids/AssociationListItem';
import { deleteAssociationFromDetailAction, useDetails } from '../../redux/detailsSlice';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { mapAssetTypeFromDetailAssociationType, ReduxDetail } from '../../redux/detailTypes';
import { useAssociations } from '../../../datagrids/useAssociations';
import { setAssociationSearchParentAction } from '../../../search/redux/searchSlice';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { useIcons } from '../../../icons/useIcons';

type AssociatedTasksListProps = {
  editingDetail: ReduxDetail;
};

export const AssociationTabList: FC<AssociatedTasksListProps> = ({ editingDetail }) => {
  const dispatch = useDispatch();
  const { getActionIcon, ActionType } = useIcons();
  const { details } = useDetails();
  const { handleListItemClick } = useAssociations();

  return (
    <StyledInfoBox label={'Associations'}>
      <Stack spacing={1}>
        <Stack direction={'row'} spacing={1}>
          <LoadingButton
            variant={'outlined'}
            loading={details.submitting}
            onClick={() => {
              editingDetail.id &&
                dispatch(
                  setNewTaskFromAssociationValuesAction({
                    id: editingDetail.id,
                    valueAssociationType: AssociationType.Detail,
                    valueAssociatedId: editingDetail?.associatedId,
                    valueAssetType: mapAssetTypeFromDetailAssociationType(editingDetail?.associationType),
                    name: editingDetail.name,
                    description: '',
                  }),
                );
            }}
            startIcon={getActionIcon(ActionType.CreateTask)}
          >
            Create Task
          </LoadingButton>
          <LoadingButton
            variant={'outlined'}
            loading={details.submitting}
            onClick={() =>
              editingDetail.id &&
              dispatch(
                setAssociationSearchParentAction({
                  id: editingDetail.id,
                  type: AssociationType.Detail,
                }),
              )
            }
            startIcon={<AddLinkIcon />}
          >
            Add Association
          </LoadingButton>
        </Stack>
        <List sx={{ width: '100%', height: '90%', backgroundColor: 'background.paper' }}>
          {editingDetail.associations?.map((a) => (
            <AssociationListItem
              key={a.associatedId}
              a={a}
              onListItemSelect={() => handleListItemClick(a, false)}
              onRemoveAssociation={() =>
                editingDetail.id &&
                dispatch(
                  deleteAssociationFromDetailAction({
                    detailId: editingDetail.id,
                    association: a,
                  }),
                )
              }
            />
          ))}
        </List>
      </Stack>
    </StyledInfoBox>
  );
};
