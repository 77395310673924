import React, { FC } from 'react';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Card from '@mui/material/Card';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { ExpandMore } from './ExpandMore';

type TenantPortalCardProps = {
  title: string;
  subtitle: string;
  children?: ReactJSXElement | ReactJSXElement[];
  focus?: boolean;
};

export const ExpandableCard: FC<TenantPortalCardProps> = ({ title, subtitle, children, focus }) => {
  const [expanded, setExpanded] = React.useState(focus ?? false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card variant={'elevation'} sx={{ maxWidth: '100%', minHeight: '8rem', height: '100%' }}>
      <CardHeader
        title={title}
        subheader={subtitle ?? ''}
        action={
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more" />
        }
      />
      <CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ marginTop: '-2rem' }}>
          {children}
        </Collapse>
      </CardContent>
    </Card>
  );
};
