import React, { FC } from 'react';
import { ITaskCategory } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AuthorizedClaims } from '../../../../../auth/AuthorizedClaims';
import { useDispatch } from 'react-redux';
import { toggleEnableCategoryAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/categorySlice';

type CategoryTableProps = {
  categories: ITaskCategory[];
};

export const CategoryTable: FC<CategoryTableProps> = ({ categories }) => {
  const dispatch = useDispatch();

  return (
    <TableContainer sx={{ pt: 0 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
            <AuthorizedClaims allowedClaims={['Admin', 'BillingOwner', 'PropertyManager']}>
              <TableCell sx={{ fontWeight: 'bold' }}>Enabled</TableCell>
            </AuthorizedClaims>
            {/* Remove delete for now */}
            {/*<AuthorizedClaims allowedClaims={['Admin', 'BillingOwner', 'PropertyManager']}>*/}
            {/*  <TableCell></TableCell>*/}
            {/*</AuthorizedClaims>*/}
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((category) => (
            <TableRow key={category.id}>
              <TableCell>{category.name}</TableCell>
              <AuthorizedClaims allowedClaims={['Admin', 'BillingOwner', 'PropertyManager']}>
                <TableCell>
                  <Switch
                    checked={category.isEnabled}
                    onChange={() => {
                      category.id &&
                        category.propertyId &&
                        dispatch(
                          toggleEnableCategoryAction({
                            id: category.id,
                            propertyId: category.propertyId,
                            body: {
                              ...category,
                              isEnabled: !category.isEnabled,
                            },
                          }),
                        );
                    }}
                  />
                </TableCell>
              </AuthorizedClaims>
              {/* Remove delete for now */}
              {/*<AuthorizedClaims allowedClaims={['Admin', 'BillingOwner', 'PropertyManager']}>*/}
              {/*  <TableCell width="20px">*/}
              {/*    <IconButton*/}
              {/*      color="secondary"*/}
              {/*      aria-label="delete"*/}
              {/*      component="label"*/}
              {/*      onClick={() => {*/}
              {/*        category.id &&*/}
              {/*          dispatch(deleteCategoryAction({ categoryId: category.id, propertyId: propertyId }));*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      <Delete />*/}
              {/*    </IconButton>*/}
              {/*  </TableCell>*/}
              {/*</AuthorizedClaims>*/}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
