import React, { FC, useEffect, useState } from 'react';
import { AssetType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  AssetTab,
  FinancialsTab,
  InfoTab,
  LeasingTab,
  OperationsTab,
  OverviewTab,
} from '../../_shared/tabs/TabTypeEnums';
import TabPanel from '@mui/lab/TabPanel';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetParams } from '../../../AppRouter';
import { clearApplicantTableFiltersAndSortAction } from '../../property/redux/applicationSlice';
import { clearLeaseFiltersAndSortsAction } from '../../_shared/leases/redux/leasesSlice';
import { useDispatch } from 'react-redux';
import { clearAssetNotificationFiltersAndSortAction } from '../../_shared/notifications/redux/notificationsSlice';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import TabContext from '@mui/lab/TabContext';
import { useTabConfig } from '../../_shared/tabs/useTabConfig';
import { getAssetTypeFromPathname } from '../../_shared/utils/getAssetTypeFromPathname';
import { showInnerTabLabelsAction, useGlobal } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

type InnerTabLayoutProps = {
  assetType: AssetType;
  tab: AssetTab;
};

type SupportedTabs = LeasingTab | OperationsTab | InfoTab | OverviewTab | FinancialsTab;

export const InnerTabLayout: FC<InnerTabLayoutProps> = ({ assetType, tab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, outerTab, innerTab } = useParams<AssetParams>();
  const { showInnerTabLabels } = useGlobal();
  const { findDefaultTab, findCurrentTabConfigs, iconConfigs, getTabLabel } = useTabConfig();
  const currentConfigs = findCurrentTabConfigs(tab);
  const currentTabs = currentConfigs[assetType];
  const defaultTab = findDefaultTab(assetType, tab);
  const [selectedInnerTab, setSelectedInnerTab] = useState<SupportedTabs>(innerTab ?? defaultTab);

  useEffect(() => {
    setSelectedInnerTab(innerTab ?? defaultTab);
  }, [outerTab, innerTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: SupportedTabs) => {
    navigate(`/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${newValue}`);
    if (innerTab === LeasingTab.Applications) {
      dispatch(clearApplicantTableFiltersAndSortAction());
    }
    if (innerTab === LeasingTab.CurrentLeases || innerTab === LeasingTab.Leasing) {
      dispatch(clearLeaseFiltersAndSortsAction());
    }
    if (innerTab === OverviewTab.Notifications) {
      dispatch(clearAssetNotificationFiltersAndSortAction());
    }
  };

  return (
    <TabPanel value={tab}>
      <Grid container>
        <Box sx={{ flexGrow: 1, bgColor: 'background.paper', display: 'flex', height: '100%' }}>
          <TabContext value={selectedInnerTab}>
            <Grid
              item
              xs={showInnerTabLabels ? 2 : 1}
              style={{
                overflow: 'hidden',
                transition: 'flex-basis 0.3s ease-in-out',
              }}
            >
              <Box>
                <Tabs
                  value={selectedInnerTab}
                  onChange={(event, newValue) => handleChange(event, newValue)}
                  orientation="vertical"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label={`${tab}-inner-tabs`}
                >
                  {currentTabs.map((t) => {
                    const icon = iconConfigs[t];
                    return showInnerTabLabels ? (
                      <Tab
                        key={t}
                        label={icon.label}
                        value={t}
                        id={`inner-tabs-${icon.label}`}
                        aria-controls={`inner-tabpanel-${icon.label}`}
                        aria-selected={
                          iconConfigs[selectedInnerTab] !== undefined &&
                          iconConfigs[selectedInnerTab].label !== undefined &&
                          iconConfigs[selectedInnerTab].label === icon.label
                        }
                        aria-label={icon.label}
                        sx={{ minHeight: '4rem' }}
                      />
                    ) : (
                      <Tab
                        key={t}
                        icon={<Tooltip title={icon.label}>{icon.icon}</Tooltip>}
                        value={t}
                        id={`inner-tabs-${icon.label}`}
                        aria-controls={`inner-tabpanel-${icon.label}`}
                        aria-selected={
                          iconConfigs[selectedInnerTab] !== undefined &&
                          iconConfigs[selectedInnerTab].label !== undefined &&
                          iconConfigs[selectedInnerTab].label === icon.label
                        }
                        aria-label={icon.label}
                        sx={{ minHeight: '4rem' }}
                      />
                    );
                  })}
                </Tabs>
                <Divider style={{ height: '.2rem', backgroundColor: 'lightgray' }} aria-label={'content-divider'} />
                <Box display={'flex'} flexGrow={1} justifyContent={'center'}>
                  <IconButton
                    onClick={() => dispatch(showInnerTabLabelsAction(!showInnerTabLabels))}
                    aria-label={showInnerTabLabels ? 'collapse-tab-menu' : 'expand-tab-menu'}
                  >
                    {showInnerTabLabels ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={showInnerTabLabels ? 10 : 11}
              style={{
                overflow: 'hidden',
                transition: 'flex-basis 0.3s ease-in-out',
              }}
            >
              <TabPanel
                key={selectedInnerTab}
                value={selectedInnerTab}
                id={`inner-tabpanel-${selectedInnerTab}`}
                aria-labelledby={`inner-tabs-${getTabLabel(selectedInnerTab)}`}
              >
                {selectedInnerTab && iconConfigs[selectedInnerTab] && iconConfigs[selectedInnerTab].component
                  ? iconConfigs[selectedInnerTab].component
                  : undefined}
              </TabPanel>
            </Grid>
          </TabContext>
        </Box>
      </Grid>
    </TabPanel>
  );
};
