import { ICamAssociation } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const areAssociationsEqual = (association1: ICamAssociation, association2: ICamAssociation): boolean => {
  // Check if associatedIds are exactly the same
  const areAssociatedIdsEqual = association1.associatedId === association2.associatedId;

  // Check if associationChildType and associationChildNumber are either exactly the same
  // or both are either null or undefined
  const areAssociationChildTypesEqual =
    association1.associationChildType === association2.associationChildType ||
    (association1.associationChildType == null && association2.associationChildType == null);

  const areAssociationChildNumbersEqual =
    association1.associationChildNumber === association2.associationChildNumber ||
    (association1.associationChildNumber == null && association2.associationChildNumber == null);

  return areAssociatedIdsEqual && areAssociationChildTypesEqual && areAssociationChildNumbersEqual;
};
