import React, { FC, useEffect, useState } from 'react';
import { StripedDataGrid } from '../datagrids/StripedDataGrid';
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
  MuiEvent,
} from '@mui/x-data-grid-premium';

import { TenantToolbar } from './TenantToolbar';
import { LeaseDispatchType, ReduxTenant } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { setBackUrlAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { removeTenantAccessAction, useLeases } from '../leases/redux/leasesSlice';
import { getAssetTypeFromPathname } from '../utils/getAssetTypeFromPathname';
import { PrimaryTenantCell, PrimaryTenantEditCell } from './PrimaryTenantCell';
import Tooltip from '@mui/material/Tooltip';
import {
  AssociationType,
  GetTenantInvitationStatusesHandlerInvitationStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { EmptyGuid } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { EmailAssociationInfo } from '../communications/redux/communicationTypes';
import { ApplicationDialogTab } from '../tabs/TabTypeEnums';
import { ActionCell } from '../datagrids/ActionCell';
import { useIcons } from '../icons/useIcons';

export type TenantTableProps = {
  leaseId: string;
  leaseType: LeaseDispatchType;
  value: ReduxTenant[];
  onUpdateTenants: (tenants: ReduxTenant[]) => void;
  onOpenInviteTenant: (tenant: ReduxTenant | undefined) => void;
  onCancelInvite: (tenantId: string) => void;
  assetId: string;
};

type TableTenants = ReduxTenant & { id?: string };

export const TenantTable: FC<TenantTableProps> = ({
  leaseId,
  leaseType,
  value,
  onUpdateTenants,
  onOpenInviteTenant,
  onCancelInvite,
  assetId,
}) => {
  const dispatch = useDispatch();
  const { getActionIcon, ActionType } = useIcons();
  const navigate = useNavigate();
  const { tenantInvites } = useLeases();
  const { id, innerTab, outerTab } = useParams();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [tableTenants, setTableTenants] = useState<TableTenants[]>([]);
  const tenantEmails = value?.map((tenant) => tenant.contactInfo?.email)?.filter((email) => email) as string[];
  const recipients: EmailAssociationInfo[] =
    tenantEmails.length > 0
      ? tenantEmails.map((e) => {
          return {
            emailAddresses: [e],
            label: e,
            associationType: AssociationType.Lease,
            associatedId: leaseId,
          };
        })
      : [];

  useEffect(() => {
    setTableTenants(value);
  }, [value, tenantInvites]);

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (params: GridRowParams<ReduxTenant>) => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
  };

  const handleCellKeyDown = (params: GridCellParams, event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'Return') {
      event.preventDefault();
      handleStopEdit(params.id)();
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (value.length === 1) {
      dispatch(showToastMessageAction({ message: "You can't remove the only tenant", severity: 'error' }));
      return;
    } else {
      const updatedTenants: TableTenants[] = tableTenants.filter((tenant) => tenant.id !== id);
      onUpdateTenants(updatedTenants);
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleStopEdit = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const saveEditRow = (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
    if (!leaseId) return oldRow;
    if (newRow.firstName === '' || newRow.lastName === '') {
      dispatch(
        showToastMessageAction({
          message: 'First and last name must be entered before saving',
          severity: 'warning',
        }),
      );
      return oldRow;
    } else {
      if (newRow.email) {
        const updatedTenants = tableTenants.map((tenant) => {
          if (newRow.id === tenant.id) {
            return { ...newRow, contactInfo: { ...newRow.contactInfo, email: newRow.email } };
          } else return tenant;
        });
        onUpdateTenants(updatedTenants);
        return { ...newRow, contactInfo: { ...newRow.contactInfo, email: newRow.email } };
      } else {
        const updatedTenants = tableTenants.map((tenant) => {
          if (newRow.id === tenant.id) {
            return newRow;
          } else return tenant;
        });
        onUpdateTenants(updatedTenants);
        return newRow;
      }
    }
  };

  const handleViewApplication = (tenantId: GridRowId, applicationId: string) => {
    const isInEditMode = rowModesModel[tenantId]?.mode === GridRowModes.Edit;
    if (isInEditMode) return;
    if (!id) {
      dispatch(showToastMessageAction({ message: 'Cannot find property for application', severity: 'error' }));
      return;
    }
    dispatch(setBackUrlAction(location.pathname));
    const assetType = getAssetTypeFromPathname();
    navigate(
      `/assets/${assetType}/${id}/${outerTab}/${innerTab}/application/${applicationId}/${ApplicationDialogTab.Info}`,
    );
  };

  const handleTenantInviteStatus = (
    id: string,
  ): { status: GetTenantInvitationStatusesHandlerInvitationStatus | undefined; invitationId?: string } => {
    if (!tenantInvites.value) return { status: undefined };
    const match = tenantInvites.value.find((i) => i.tenantId === id);
    if (!match || !match.invitationStatus) return { status: undefined };
    return { status: match.invitationStatus, invitationId: match.invitationId };
  };

  const handleRemoveTenantAccessAction = (tenantId: string) => {
    dispatch(
      removeTenantAccessAction({
        tenantId: tenantId,
        assetId: assetId,
        leaseId: leaseId,
        leaseType: leaseType,
      }),
    );
  };

  function* createActions(params: GridRowParams<ReduxTenant>) {
    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
    const isTenantInvitedOrAccepted = params.row.id && handleTenantInviteStatus(params.row.id);
    if (isInEditMode) {
      yield (
        <ActionCell
          icon={getActionIcon(ActionType.Save)}
          key="saveAction"
          label={'Save'}
          onClick={handleStopEdit(params.id)}
          color="primary"
          showInMenu={undefined}
        />
      ),
        yield (
          <ActionCell
            icon={getActionIcon(ActionType.Cancel)}
            key="cancelAction"
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(params.id)}
            color="inherit"
            showInMenu={undefined}
          />
        );
    } else {
      if (
        isTenantInvitedOrAccepted &&
        isTenantInvitedOrAccepted?.status === GetTenantInvitationStatusesHandlerInvitationStatus.Invited
      ) {
        yield (
          <ActionCell
            icon={getActionIcon(ActionType.Cancel)}
            label="Cancel Invite"
            key={'cancelInvite'}
            onClick={() => params.row.id && onCancelInvite(params.row.id)}
            showInMenu={undefined}
            color="inherit"
            disabled={!isTenantInvitedOrAccepted?.invitationId}
            disabledLabel={'Cannot cancel invite'}
          />
        );
      }
      if (
        isTenantInvitedOrAccepted &&
        isTenantInvitedOrAccepted?.status === GetTenantInvitationStatusesHandlerInvitationStatus.Accepted
      ) {
        yield (
          <ActionCell
            key={'removeAccess'}
            icon={getActionIcon(ActionType.Block)}
            label="Remove Access"
            onClick={() => params.row.id && handleRemoveTenantAccessAction(params.row.id)}
            showInMenu={undefined}
            color="inherit"
          />
        );
      }
      if (!isTenantInvitedOrAccepted || !isTenantInvitedOrAccepted.status) {
        yield (
          <ActionCell
            key={'inviteTenant'}
            icon={getActionIcon(ActionType.Invite)}
            label="Invite Tenant"
            onClick={() => onOpenInviteTenant(params.row)}
            showInMenu={undefined}
            color="inherit"
          />
        );
      }
      if (tenantInvites.loading) {
        yield (
          <Tooltip key={'loadingAction'} title={'Loading Tenant Invitations'}>
            <GridActionsCellItem
              icon={<CircularProgress size={1} />}
              label="Loading"
              showInMenu={undefined}
              color="inherit"
            />
          </Tooltip>
        );
      }
      if (params.row.applicationId && params.row.applicationId !== EmptyGuid) {
        yield (
          <Tooltip key="viewApplication" title={'View Application'}>
            <GridActionsCellItem
              icon={getActionIcon(ActionType.GoTo)}
              label="View Application"
              className="textPrimary"
              onClick={() => handleViewApplication(params.id, params.row.applicationId!)}
              color="inherit"
              showInMenu={undefined}
            />
          </Tooltip>
        );
      }
      yield (
        <Tooltip key="deleteAction" title={'Remove Tenant From Lease'}>
          <GridActionsCellItem
            icon={getActionIcon(ActionType.Delete)}
            label="Delete"
            onClick={handleDeleteClick(params.id)}
            color="inherit"
            showInMenu={undefined}
          />
        </Tooltip>
      );
    }
  }

  const columns: GridColDef<ReduxTenant>[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 0.75,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        const isTenantInvitedOrAccepted = params.row.id && handleTenantInviteStatus(params.row.id);
        if (
          isTenantInvitedOrAccepted &&
          isTenantInvitedOrAccepted.status === GetTenantInvitationStatusesHandlerInvitationStatus.Invited
        ) {
          return (
            <Tooltip title={'Invite Pending'}>
              <Typography variant={'body2'} fontStyle={'italic'}>
                {params.value}
              </Typography>
            </Tooltip>
          );
        } else return <>{params.value}</>;
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 0.75,
      editable: true,
      renderCell: (params: GridRenderCellParams) => {
        const isTenantInvitedOrAccepted = params.row.id && handleTenantInviteStatus(params.row.id);
        if (
          isTenantInvitedOrAccepted &&
          isTenantInvitedOrAccepted.status === GetTenantInvitationStatusesHandlerInvitationStatus.Invited
        ) {
          return (
            <Typography variant={'body2'} fontStyle={'italic'}>
              {params.value}
            </Typography>
          );
        } else return <>{params.value}</>;
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: true,
      valueGetter: (value, row) => row.contactInfo?.email ?? '',
    },
    {
      field: 'isMainTenant',
      headerName: 'Primary Tenant',
      align: 'center',
      editable: true,
      renderCell: PrimaryTenantCell,
      renderEditCell: PrimaryTenantEditCell,
      flex: 0.5,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      flex: 1,
      getActions: (params: GridRowParams) => Array.from(createActions(params)),
    },
  ];

  return (
    <StripedDataGrid
      disableRowGrouping
      density="compact"
      columns={columns}
      isCellEditable={(params: GridCellParams<ReduxTenant>): boolean => {
        const isTenantInvitedOrAccepted = params.row.id && handleTenantInviteStatus(params.row.id);
        if (
          params.field === 'email' &&
          ((isTenantInvitedOrAccepted &&
            isTenantInvitedOrAccepted.status === GetTenantInvitationStatusesHandlerInvitationStatus.Invited) ||
            (isTenantInvitedOrAccepted &&
              isTenantInvitedOrAccepted.status === GetTenantInvitationStatusesHandlerInvitationStatus.Accepted))
        ) {
          return false;
        } else return true;
      }}
      rows={tableTenants}
      autoHeight
      editMode="row"
      rowModesModel={rowModesModel}
      onCellKeyDown={handleCellKeyDown}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={saveEditRow}
      onRowClick={(params) => {
        handleEditClick(params);
      }}
      slots={{ toolbar: TenantToolbar }}
      slotProps={{
        toolbar: { leaseId, leaseType, recipients },
      }}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      sx={{ flex: 1 }}
      hideFooter
    />
  );
};
