import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { all, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  addAssociationToDetailAction,
  addAssociationToDetailFailAction,
  addAssociationToDetailSuccessAction,
  addDetailAction,
  addDetailFailedAction,
  addDetailSpecificationAction,
  addDetailSpecificationFailedAction,
  addDetailSpecificationSuccessAction,
  addDetailSuccessAction,
  addDocumentToDetailAction,
  addDocumentToDetailFailAction,
  addDocumentToDetailObservationAction,
  addDocumentToDetailObservationFailAction,
  addDocumentToDetailObservationSuccessAction,
  addDocumentToDetailSuccessAction,
  addFollowUpNoteAction,
  addFollowUpNoteFailedAction,
  addFollowUpNoteSuccessAction,
  addSubDetailAction,
  addSubDetailFailAction,
  addSubDetailSuccessAction,
  deleteAssociationFromDetailAction,
  deleteAssociationFromDetailFailAction,
  deleteAssociationFromDetailSuccessAction,
  deleteDetailAction,
  deleteDetailFailedAction,
  deleteDetailObservationAction,
  deleteDetailObservationFailedAction,
  deleteDetailObservationSuccessAction,
  deleteDetailSpecificationAction,
  deleteDetailSpecificationFailedAction,
  deleteDetailSpecificationSuccessAction,
  deleteDetailSuccessAction,
  getDetailByIdAction,
  getDetailByIdFailAction,
  getDetailByIdSuccessAction,
  getDetailGroupByAssetIdAction,
  getDetailGroupByAssetIdFailAction,
  getDetailGroupByAssetIdSuccessAction,
  getDetailGroupByParentIdAction,
  getDetailGroupByParentIdFailAction,
  getDetailGroupByParentIdSuccessAction,
  getDetailGroupsAction,
  getDetailGroupsFailAction,
  getDetailGroupsSuccessAction,
  getDetailsAction,
  getDetailsFailedAction,
  getDetailsSuccessAction,
  initializeDetailObservationAction,
  initializeDetailObservationFailedAction,
  initializeDetailObservationSuccessAction,
  removeDocFromDetailObservationAction,
  removeDocFromDetailObservationFailAction,
  removeDocFromDetailObservationSuccessAction,
  reorderDetailObservationsAction,
  reorderDetailObservationsFailAction,
  reorderDetailObservationsSuccessAction,
  resetDetailSubmissionAction,
  updateDetailInfoAction,
  updateDetailInfoFailedAction,
  updateDetailInfoSuccessAction,
  updateDetailObservationAction,
  updateDetailObservationFailAction,
  updateDetailObservationSuccessAction,
  updateSubDetailAction,
  updateSubDetailFailAction,
  updateSubDetailSuccessAction,
} from './detailsSlice';
import {
  AddAssociationToDetailHandlerRequest,
  AddAssociationToDetailHandlerResponse,
  AddDetailHandlerRequest,
  AddDetailHandlerResponse,
  AddDetailSpecificationHandlerRequest,
  AddDetailSpecificationHandlerResponse,
  AddFollowUpNoteHandlerRequest,
  AddFollowUpNoteHandlerResponse,
  AddSubDetailHandlerRequest,
  AssetType,
  AssociationChildType,
  AssociationType,
  CamAssociation,
  Detail,
  DetailAssociationType,
  DetailDto,
  GetGroupedDetailsHandlerDetailsGroupBy,
  GetGroupedDetailsHandlerResponse,
  IAddDetailHandlerRequest,
  IAddSubDetailHandlerRequest,
  ICamAssociation,
  InitializeDetailObservationHandlerRequest,
  InitializeDetailObservationHandlerResponse,
  ListDetailsHandlerResponse,
  ListSortDirection,
  Optional_DateOnly,
  PaginatedQueryExpression,
  QueryExpression,
  ReorderDetailObservationsHandlerRequest,
  UpdateDetailHandlerRequest,
  UpdateDetailObservationHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  cancelConfirmDialogAction,
  okConfirmDialogAction,
  showConfirmDialogAction,
  showErrorAction,
  showToastMessageAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { mapReduxDetail, mapReduxDetailFollowUpNote, ReduxDetail } from './detailTypes';
import { Filter, SortDirection } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { convertToQueryExpression } from '../../utils/filteringUtils';
import {
  resetAssociationSubmissionAction,
  setAssociationSubmittingSuccessAction,
} from '../../search/redux/searchSlice';

function* getDetails(
  action: PayloadAction<{
    associationType: DetailAssociationType;
    associatedId: string;
    page: number;
    pageSize: number;
    sortDirection: SortDirection | undefined;
    sortBy: keyof ReduxDetail | undefined;
    filters: Filter<ReduxDetail>[] | undefined;
    parentId?: string;
    includeClosed: boolean;
  }>,
) {
  try {
    const formattedFilters: QueryExpression | undefined =
      action.payload.filters && convertToQueryExpression(action.payload.filters);
    const body = PaginatedQueryExpression.fromJS({
      ...formattedFilters,
      page: action.payload.page,
      pageSize: action.payload.pageSize,
      orderBy: action.payload.sortBy ? [action.payload.sortBy] : undefined,
      orderDirection: action.payload.sortDirection as ListSortDirection,
    });
    const response: ListDetailsHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().details_SearchDetails,
      action.payload.associationType,
      action.payload.associatedId,
      action.payload.parentId,
      action.payload.includeClosed,
      body,
    );
    const formattedData = response.toJSON();
    const data = {
      details: formattedData.details ?? [],
      totalCount: formattedData.totalCount ?? 0,
      page: action.payload.page,
      pageSize: action.payload.pageSize,
      isSubDetails: !!action.payload.parentId,
    };
    yield put(getDetailsSuccessAction(data));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem loading details' }));
    yield put(getDetailsFailedAction({ isSubDetails: !!action.payload.parentId }));
  }
}

function* getDetailGroups(action: PayloadAction<{ assetType: AssetType; assetId: string; includeClosed: boolean }>) {
  try {
    console.log('action.payload: ', action.payload);
    const response: GetGroupedDetailsHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().details_GetGroupedDetails,
      action.payload.assetType,
      action.payload.assetId,
      GetGroupedDetailsHandlerDetailsGroupBy.Asset,
      action.payload.includeClosed,
      undefined,
    );
    if (response) {
      yield put(getDetailGroupsSuccessAction(response.toJSON().results));
    }
  } catch (error: any) {
    yield put(getDetailGroupsFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting detail groups' }));
  }
}

function* getDetailGroupByAssetId(
  action: PayloadAction<{
    associationType: DetailAssociationType;
    associatedId: string;
    page: number;
    pageSize: number;
    sortDirection: SortDirection | undefined;
    sortBy: keyof ReduxDetail | undefined;
    filters: Filter<ReduxDetail>[] | undefined;
    includeClosed: boolean;
  }>,
) {
  try {
    const formattedFilters: QueryExpression | undefined =
      action.payload.filters && convertToQueryExpression(action.payload.filters);
    const body = PaginatedQueryExpression.fromJS({
      ...formattedFilters,
      page: action.payload.page,
      pageSize: action.payload.pageSize,
      orderBy: action.payload.sortBy ? [action.payload.sortBy] : undefined,
      orderDirection: action.payload.sortDirection as ListSortDirection,
    });
    const response: ListDetailsHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().details_SearchDetails,
      action.payload.associationType,
      action.payload.associatedId,
      undefined,
      action.payload.includeClosed,
      body,
    );
    if (response) {
      const formattedData = response.toJSON();
      formattedData.details = formattedData.details.map((x: Detail) => mapReduxDetail(x));
      yield put(
        getDetailGroupByAssetIdSuccessAction({
          id: action.payload.associatedId,
          body: formattedData,
        }),
      );
    }
  } catch (error: any) {
    yield put(getDetailGroupByAssetIdFailAction({ id: action.payload.associatedId }));
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting details grouped by asset' }));
  }
}

function* getDetailGroupByParentId(
  action: PayloadAction<{
    associationType: DetailAssociationType;
    associatedId: string;
    parentId: string;
    page: number;
    pageSize: number;
    sortDirection: SortDirection | undefined;
    sortBy: keyof ReduxDetail | undefined;
    filters: Filter<ReduxDetail>[] | undefined;
    includeClosed: boolean;
  }>,
) {
  try {
    const formattedFilters: QueryExpression | undefined =
      action.payload.filters && convertToQueryExpression(action.payload.filters);
    const body = PaginatedQueryExpression.fromJS({
      ...formattedFilters,
      page: action.payload.page,
      pageSize: action.payload.pageSize,
      orderBy: action.payload.sortBy ? [action.payload.sortBy] : undefined,
      orderDirection: action.payload.sortDirection as ListSortDirection,
    });
    const response: ListDetailsHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().details_SearchDetails,
      action.payload.associationType,
      action.payload.associatedId,
      action.payload.parentId,
      action.payload.includeClosed,
      body,
    );
    if (response) {
      const formattedData = response.toJSON();
      formattedData.details = formattedData.details.map((x: Detail) => mapReduxDetail(x));
      yield put(
        getDetailGroupByParentIdSuccessAction({
          id: action.payload.parentId,
          body: formattedData,
        }),
      );
    }
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting details grouped by parent id' }));
    yield put(getDetailGroupByParentIdFailAction({ id: action.payload.parentId }));
  }
}

function* addDetail(
  action: PayloadAction<{
    associationType: DetailAssociationType;
    associatedId: string;
    body: IAddDetailHandlerRequest;
  }>,
) {
  try {
    const body = AddDetailHandlerRequest.fromJS({
      ...action.payload.body,
      specifications: action.payload.body.specifications ?? [],
    });

    const response: AddDetailHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().details_AddDetail,
      action.payload.associationType,
      action.payload.associatedId,
      body,
    );
    yield put(showToastMessageAction({ message: 'Detail added successfully!', severity: 'success' }));
    yield put(addDetailSuccessAction(response.toJSON()));
  } catch {
    yield put(showToastMessageAction({ message: 'Failed to add detail', severity: 'error' }));
    yield put(addDetailFailedAction());
  }
}

function* updateSubDetail(action: PayloadAction<{ body: ReduxDetail }>) {
  try {
    const body = UpdateDetailHandlerRequest.fromJS({
      ...action.payload.body,
      dateInstalled: new Optional_DateOnly({
        value: action.payload.body.dateInstalled,
      }),
    });

    yield apiCall(ApiClientSingleton.getInstance().details_UpdateDetailInfo, action.payload.body.id ?? '', body);
    yield put(updateSubDetailSuccessAction({ updatedDetail: action.payload.body }));
    yield put(showToastMessageAction({ message: 'Detail updated successfully', severity: 'success' }));
  } catch {
    yield put(showToastMessageAction({ message: 'Failed to update detail', severity: 'error' }));
    yield put(updateSubDetailFailAction());
  }
}

function* updateDetail(action: PayloadAction<{ body: ReduxDetail }>) {
  try {
    const body = UpdateDetailHandlerRequest.fromJS({
      ...action.payload.body,
      dateInstalled: new Optional_DateOnly({
        value: action.payload.body.dateInstalled,
      }),
    });

    yield apiCall(ApiClientSingleton.getInstance().details_UpdateDetailInfo, action.payload.body.id ?? '', body);

    yield put(updateDetailInfoSuccessAction({ updatedDetail: action.payload.body }));
    yield put(showToastMessageAction({ message: 'Detail updated successfully', severity: 'success' }));
  } catch {
    yield put(showToastMessageAction({ message: 'Failed to update detail', severity: 'error' }));
    yield put(updateDetailInfoFailedAction());
  }
}

function* deleteDetail(action: PayloadAction<{ detailId: string }>) {
  try {
    yield put(
      showConfirmDialogAction({
        title: 'Are you sure?',
        message: 'This will delete the detail.',
        okText: 'Yes',
        cancelText: 'No',
      }),
    );
    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });
    if (yes) {
      yield apiCall(ApiClientSingleton.getInstance().details_DeleteDetail, action.payload.detailId);

      yield put(deleteDetailSuccessAction({ detailId: action.payload.detailId }));
      yield put(showToastMessageAction({ message: 'Detail deleted successfully', severity: 'success' }));
    } else {
      yield put(resetDetailSubmissionAction());
    }
  } catch {
    yield put(showToastMessageAction({ message: 'Failed to delete detail', severity: 'error' }));
    yield put(deleteDetailFailedAction());
  }
}

function* addFollowUpNote(
  action: PayloadAction<{
    detailId: string;
    observationId: number;
    content: string;
  }>,
) {
  try {
    const body = AddFollowUpNoteHandlerRequest.fromJS({
      content: action.payload.content,
    });

    const response: AddFollowUpNoteHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().details_AddFollowUpNote,
      action.payload.detailId,
      action.payload.observationId,
      body,
    );
    yield put(showToastMessageAction({ message: 'Follow-up note added successfully', severity: 'success' }));
    yield put(
      addFollowUpNoteSuccessAction({
        detailId: action.payload.detailId,
        observationId: action.payload.observationId,
        createdNote: mapReduxDetailFollowUpNote(response.toJSON().createdFollowUpNote),
      }),
    );
  } catch {
    yield put(showToastMessageAction({ message: 'Failed to add follow up note', severity: 'error' }));
    yield put(addFollowUpNoteFailedAction());
  }
}

function* addDetailSpecification(
  action: PayloadAction<{
    detailId: string;
    key: string;
    value: string;
  }>,
) {
  try {
    const requestBody: AddDetailSpecificationHandlerRequest = AddDetailSpecificationHandlerRequest.fromJS(
      action.payload,
    );

    const response: AddDetailSpecificationHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().details_AddDetailSpecification,
      action.payload.detailId,
      requestBody,
    );
    yield put(showToastMessageAction({ message: 'Detail spec added successfully', severity: 'success' }));
    yield put(
      addDetailSpecificationSuccessAction({
        detailId: action.payload.detailId,
        ...response.toJSON().createdDetailSpecification,
      }),
    );
  } catch {
    yield put(showToastMessageAction({ message: 'Failed to add detail specification', severity: 'error' }));
    yield put(addDetailSpecificationFailedAction());
  }
}

function* deleteDetailSpecification(
  action: PayloadAction<{
    detailId: string;
    key: string;
  }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().details_DeleteDetailSpecification,
      action.payload.detailId,
      action.payload.key,
    );
    yield put(showToastMessageAction({ message: 'Detail spec deleted successfully', severity: 'success' }));
    yield put(deleteDetailSpecificationSuccessAction({ detailId: action.payload.detailId, key: action.payload.key }));
  } catch {
    yield put(showToastMessageAction({ message: 'Failed to delete detail specification', severity: 'error' }));
    yield put(deleteDetailSpecificationFailedAction());
  }
}

function* initializeDetailObservation(
  action: PayloadAction<{
    detailId: string;
    description: string;
  }>,
) {
  try {
    const body = InitializeDetailObservationHandlerRequest.fromJS({
      description: action.payload.description,
    });

    const response: InitializeDetailObservationHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().details_InitializeDetailObservation,
      action.payload.detailId,
      body,
    );
    yield put(showToastMessageAction({ message: 'Detail observation added successfully', severity: 'success' }));
    yield put(
      initializeDetailObservationSuccessAction({
        detailId: action.payload.detailId,
        createdObservation: response.toJSON().createdDetailObservation!,
      }),
    );
  } catch {
    yield put(showToastMessageAction({ message: 'Failed to initialize detail observation', severity: 'error' }));
    yield put(initializeDetailObservationFailedAction());
  }
}

function* updateObservation(action: PayloadAction<{ detailId: string; observationId: number; description: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().details_UpdateDetailObservation,
      action.payload.detailId,
      action.payload.observationId,
      new UpdateDetailObservationHandlerRequest({ description: action.payload.description }),
    );
    yield put(
      updateDetailObservationSuccessAction({
        observationId: action.payload.observationId,
        description: action.payload.description,
      }),
    );
    yield put(showToastMessageAction({ message: 'Observation updated successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating observation' }));
    yield put(updateDetailObservationFailAction());
  }
}

function* removeDocFromObservation(action: PayloadAction<{ detailId: string; observationId: number; docId: string }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().details_RemoveAttachmentFromDetailObservation,
      action.payload.detailId,
      action.payload.observationId,
      action.payload.docId,
    );
    yield put(
      removeDocFromDetailObservationSuccessAction({
        observationId: action.payload.observationId,
        docId: action.payload.docId,
      }),
    );
    yield put(
      showToastMessageAction({ message: 'Document removed from observation successfully', severity: 'success' }),
    );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem removing document from observation' }));
    yield put(removeDocFromDetailObservationFailAction());
  }
}

function* deleteDetailObservation(action: PayloadAction<{ detailId: string; observationId: number }>) {
  try {
    yield put(
      showConfirmDialogAction({
        title: 'Are you sure?',
        message: 'This will delete the observation.',
        okText: 'Yes',
        cancelText: 'No',
      }),
    );

    const { yes } = yield race({ yes: take(okConfirmDialogAction.type), no: take(cancelConfirmDialogAction.type) });

    if (yes) {
      yield apiCall(
        ApiClientSingleton.getInstance().details_DeleteDetailObservation,
        action.payload.detailId,
        action.payload.observationId,
      );
      yield put(
        showToastMessageAction({
          message: 'Detail observation deleted successfully',
          severity: 'success',
        }),
      );
      yield put(
        deleteDetailObservationSuccessAction({
          detailId: action.payload.detailId,
          observationId: action.payload.observationId,
        }),
      );
    } else {
      yield put(resetDetailSubmissionAction());
    }
  } catch {
    yield put(showToastMessageAction({ message: 'Failed to delete detail observation', severity: 'error' }));
    yield put(deleteDetailObservationFailedAction());
  }
}

function* getDetailById(action: PayloadAction<{ detailId: string }>) {
  try {
    const response: DetailDto = yield apiCall(
      ApiClientSingleton.getInstance().details_GetDetail,
      action.payload.detailId,
    );
    if (response) {
      const detail = mapReduxDetail(response.toJSON());
      yield put(getDetailByIdSuccessAction(detail));
    }
  } catch (error: any) {
    yield put(getDetailByIdFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem fetching detail by id' }));
  }
}

function* addSubDetail(
  action: PayloadAction<{
    detailId: string;
    body: IAddSubDetailHandlerRequest;
  }>,
) {
  try {
    const body = AddSubDetailHandlerRequest.fromJS({
      ...action.payload.body,
      specifications: action.payload.body.specifications ?? [],
    });
    const response: DetailDto = yield apiCall(
      ApiClientSingleton.getInstance().details_AddSubDetail,
      action.payload.detailId,
      body,
    );
    yield put(addSubDetailSuccessAction(response.toJSON()));
    yield put(showToastMessageAction({ message: 'Component added successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(addSubDetailFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding component' }));
  }
}

function* addDocumentToDetail(
  action: PayloadAction<{
    detailId: string;
    file: File;
  }>,
) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().details_AddAttachmentToDetail, action.payload.detailId, {
      fileName: action.payload.file.name,
      data: action.payload.file,
    });
    yield put(addDocumentToDetailSuccessAction());
    yield put(showToastMessageAction({ message: 'Document uploaded to detail successfully', severity: 'success' }));
    yield put(
      getDetailByIdAction({
        detailId: action.payload.detailId,
      }),
    );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem uploading document to detail' }));
    yield put(addDocumentToDetailFailAction());
  }
}

function* addDocumentToDetailObservation(
  action: PayloadAction<{
    detailId: string;
    observationId: number;
    file: File;
  }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().details_AddAttachmentToDetailObservation,
      action.payload.detailId,
      action.payload.observationId,
      { fileName: action.payload.file.name, data: action.payload.file },
    );
    yield put(addDocumentToDetailObservationSuccessAction());
    yield put(
      showToastMessageAction({
        message: 'Document uploaded to detail observation successfully',
        severity: 'success',
      }),
    );
    yield put(
      getDetailByIdAction({
        detailId: action.payload.detailId,
      }),
    );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem uploading document to detail observation' }));
    yield put(addDocumentToDetailObservationFailAction());
  }
}

function* addAssociationToDetail(
  action: PayloadAction<{
    detailId: string;
    associateWithId: string;
    associateWithType: AssociationType;
    childNumber?: string;
    childAssociationType?: AssociationChildType;
  }>,
) {
  try {
    const body = AddAssociationToDetailHandlerRequest.fromJS({
      associateWithId: action.payload.associateWithId,
      associateWithType: action.payload.associateWithType,
      associationChildType: action.payload.childAssociationType,
      associationChildNumber: action.payload.childNumber,
    });
    const response: AddAssociationToDetailHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().details_AddAssociationToDetail,
      action.payload.detailId,
      body,
    );
    yield put(
      addAssociationToDetailSuccessAction({
        detailId: action.payload.detailId,
        newAssociation: response.toJSON().newAssociation,
      }),
    );
    yield put(setAssociationSubmittingSuccessAction());
    yield put(showToastMessageAction({ message: 'Association added to detail successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(resetAssociationSubmissionAction());
    yield put(addAssociationToDetailFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem adding association to detail' }));
  }
}

function* deleteAssociationFromDetail(
  action: PayloadAction<{
    detailId: string;
    association: ICamAssociation;
  }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().details_RemoveAssociationFromDetail,
      action.payload.detailId,
      new CamAssociation(action.payload.association),
    );
    yield put(
      deleteAssociationFromDetailSuccessAction({
        detailId: action.payload.detailId,
        childType: action.payload.association.associationChildType,
        associatedWithId: action.payload.association.associatedId!,
        childNumber: action.payload.association.associationChildNumber,
      }),
    );
    yield put(showToastMessageAction({ message: 'Association removed successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem removing association from detail' }));
    yield put(deleteAssociationFromDetailFailAction());
  }
}

function* reorderDetailObservations(action: PayloadAction<{ detailId: string; observationIds: number[] }>) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().details_ReorderDetailObservations,
      action.payload.detailId,
      new ReorderDetailObservationsHandlerRequest({
        observationIds: action.payload.observationIds,
      }),
    );
    yield put(
      showToastMessageAction({
        message: 'Observations reordered successfully',
        severity: 'success',
      }),
    );
    yield put(
      reorderDetailObservationsSuccessAction({
        detailId: action.payload.detailId,
        observationIds: action.payload.observationIds,
      }),
    );
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem reordering observations' }));
    yield put(reorderDetailObservationsFailAction());
  }
}

export function* detailsSagas() {
  yield all([
    takeEvery(getDetailsAction.type, getDetails),
    takeLatest(getDetailGroupsAction.type, getDetailGroups),
    takeEvery(getDetailGroupByAssetIdAction.type, getDetailGroupByAssetId),
    takeEvery(getDetailGroupByParentIdAction.type, getDetailGroupByParentId),
    takeLatest(addDetailAction.type, addDetail),
    takeLatest(addSubDetailAction.type, addSubDetail),
    takeLatest(updateDetailInfoAction.type, updateDetail),
    takeLatest(deleteDetailAction.type, deleteDetail),
    takeLatest(addFollowUpNoteAction.type, addFollowUpNote),
    takeLatest(addDetailSpecificationAction.type, addDetailSpecification),
    takeLatest(deleteDetailSpecificationAction.type, deleteDetailSpecification),
    takeLatest(initializeDetailObservationAction.type, initializeDetailObservation),
    takeLatest(updateDetailObservationAction.type, updateObservation),
    takeLatest(removeDocFromDetailObservationAction.type, removeDocFromObservation),
    takeLatest(deleteDetailObservationAction.type, deleteDetailObservation),
    takeLatest(getDetailByIdAction.type, getDetailById),
    takeLatest(addDocumentToDetailAction.type, addDocumentToDetail),
    takeLatest(addDocumentToDetailObservationAction.type, addDocumentToDetailObservation),
    takeLatest(addAssociationToDetailAction.type, addAssociationToDetail),
    takeLatest(deleteAssociationFromDetailAction.type, deleteAssociationFromDetail),
    takeLatest(updateSubDetailAction.type, updateSubDetail),
    takeLatest(reorderDetailObservationsAction.type, reorderDetailObservations),
  ]);
}
