import React, { FC, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Add from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { AddCategoryDialog } from './AddCategoryDialog';
import { getAllCategoriesAction, useCategories } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/categorySlice';
import Box from '@mui/material/Box';
import { CategoryTable } from './CategoryTable';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';

export const Categories: FC = () => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const { allCategories } = useCategories();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  useEffect(() => {
    selectedContext.propertyId &&
      dispatch(getAllCategoriesAction({ propertyId: selectedContext.propertyId, includeDisabled: true }));
  }, [selectedContext.propertyId]);

  const handleClose = () => {
    setAddDialogOpen(false);
  };

  const openAddDialog = () => {
    setAddDialogOpen(true);
  };

  return (
    <StyledInfoBox label={'Task Categories'}>
      <Grid container direction="column" sx={{ width: '100%' }}>
        <Grid item container>
          <Button variant="outlined" startIcon={<Add />} onClick={openAddDialog}>
            Add Category
          </Button>
        </Grid>
        <Grid item>
          {allCategories.loading ? (
            <Box width="100%" textAlign="center" paddingTop="24px">
              <CircularProgress />
            </Box>
          ) : (
            selectedContext.propertyId && <CategoryTable categories={allCategories.value ?? []} />
          )}
        </Grid>
        {selectedContext.propertyId && (
          <AddCategoryDialog propertyId={selectedContext.propertyId} open={addDialogOpen} onClose={handleClose} />
        )}
      </Grid>
    </StyledInfoBox>
  );
};
