import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { GridFilterModel, GridRowId, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid-premium';
import { CancelPreLeaseDialog } from '../leaseDialogs/CancelPreLeaseDialog';
import { LeaseDispatchType, mapReduxLease, ReduxLease } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { AssociationType, ILeaseDto } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import {
  getAllLeasesByAssetIdAction,
  getDraftLeasesForPropertyIdAction,
  getRenewalLeasesForPropertyIdAction,
  quickEditLeaseAction,
  resetLeaseStatusSubmissionAction,
  setLeaseTableFiltersAction,
  setLeaseTableSortAction,
  toggleLeaseIncludeClosedStatusesAction,
  updateLeaseFromSignalRAction,
  useLeases,
} from '../../redux/leasesSlice';
import { LeasePaymentDialog, PaymentTitle } from '../../../dialogs/paymentDialog/LeasePaymentDialog';

import { getGlCodesAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { LeasesDataGrid } from './LeasesDataGrid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { resetLeaseTransactionSubmissionAction } from '../../redux/leaseTransactionSlice';
import { convertToQueryOperator, FilterOperator } from '../../../utils/filteringUtils';
import { EmailAssociationInfo } from '../../../communications/redux/communicationTypes';
import { setDetailedEmailInfoAction } from '../../../communications/redux/communicationSlice';
import Connector from '@monkeyjump-labs/cam-fe-shared/dist/services/signalrConnection';
import { CloseLeaseWizard } from '../leaseDialogs/CloseLeaseWizard';

type Props = {
  leasesType: LeaseDispatchType;
  propertyId: string;
  assetId: string;
};

export const LeasesTable = ({ propertyId, leasesType, assetId }: Props) => {
  const dispatch = useDispatch();
  const { allLeases, renewalLeases, draftLeases } = useLeases();
  const { includeClosedStatuses } = useLeases();
  const [acceptPaymentForLeaseId, setAcceptPaymentForLeaseId] = useState<string | undefined>(undefined);
  const [closeLeaseId, setCloseLeaseId] = useState<string | undefined>(undefined);

  const openPaymentDialog = (paymentId: string) => {
    dispatch(resetLeaseTransactionSubmissionAction());
    setAcceptPaymentForLeaseId(paymentId);
  };
  const openCloseLeaseDialog = (leaseId: string) => {
    dispatch(resetLeaseTransactionSubmissionAction());
    dispatch(resetLeaseStatusSubmissionAction());
    setCloseLeaseId(leaseId);
  };
  const closePaymentDialog = () => {
    setAcceptPaymentForLeaseId(undefined);
    setPaymentDialogTitle(undefined);
  };
  const [openLeaseQuickAction, setOpenLeaseQuickAction] = useState(false);
  const [editRow, setEditRow] = useState<ReduxLease | undefined>();
  const [paymentDialogTitle, setPaymentDialogTitle] = useState<PaymentTitle | undefined>();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [rows, setRows] = useState<ReduxLease[]>([]);
  const [selectedRows, setSelectedRows] = useState<ReduxLease[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    switch (leasesType) {
      case 'all':
        setRows(allLeases.value ?? []);
        setRowCount(allLeases.totalCount ?? 0);
        setLoading(allLeases.loading);
        break;
      case 'renewal':
        setRows(renewalLeases.value ?? []);
        setRowCount(renewalLeases.totalCount ?? 0);
        setLoading(renewalLeases.loading);
        break;
      case 'draft':
        setRows(draftLeases.value ?? []);
        setRowCount(draftLeases.totalCount ?? 0);
        setLoading(draftLeases.loading);
    }
    const connector = Connector.getExistingInstance();
    const updateLease = (updatedLease: ILeaseDto) => {
      dispatch(updateLeaseFromSignalRAction(mapReduxLease(updatedLease)));
    };

    connector.onReceiveUpdatedLease(updateLease);

    return () => {
      connector.offReceiveUpdatedLease(updateLease);
    };
  }, [allLeases, renewalLeases, draftLeases]);

  useEffect(() => {
    switch (leasesType) {
      case 'all':
        dispatch(
          getAllLeasesByAssetIdAction({
            propertyId: propertyId,
            assetId: assetId,
            page: page,
            pageSize: pageSize,
          }),
        );
        break;
      case 'draft':
        dispatch(getDraftLeasesForPropertyIdAction({ propertyId: assetId, page: page, pageSize: pageSize }));
        break;
      case 'renewal':
        dispatch(getRenewalLeasesForPropertyIdAction({ propertyId: assetId, page: page, pageSize: pageSize }));
        break;
    }
  }, [page, pageSize]);

  useEffect(() => {
    const selectedTenantEmailObjects: EmailAssociationInfo[] = selectedRows
      .flatMap((lease) => {
        const emails =
          (lease.leaseTerms
            ?.flatMap((leaseTerm) => leaseTerm.tenantGroup?.tenants.flatMap((tenant) => tenant.contactInfo?.email))
            .filter((email) => email) as string[]) ?? [];
        return {
          emailAddresses: emails,
          label: lease.unitName ?? 'Lease',
          associatedId: lease.id,
          associationType: AssociationType.Lease,
        };
      })
      .filter((email) => email?.emailAddresses?.length > 0);
    dispatch(setDetailedEmailInfoAction({ recipients: selectedTenantEmailObjects }));
    setSelectionModel(selectedRows?.map((row) => row.id!) ?? []);
  }, [selectedRows]);

  const handleSelectionChange = (model: GridRowSelectionModel) => {
    setSelectedRows(model.map((row) => rows.find((r) => r.id === row)!));
  };

  const handleSortModelChange = (model: GridSortModel) => {
    //for now, only sort on keyof ReduxLease enabled (moveOutDate, unitName)
    if (model.length === 0) {
      dispatch(
        setLeaseTableSortAction({
          propertyId: propertyId,
          assetId: assetId,
          leaseType: leasesType,
          sortBy: undefined,
          sortDirection: undefined,
        }),
      );
    } else {
      dispatch(
        setLeaseTableSortAction({
          propertyId: propertyId,
          assetId: assetId,
          leaseType: leasesType,
          sortBy: model[0].field as keyof ReduxLease,
          sortDirection: model[0].sort === 'asc' ? 'Ascending' : 'Descending',
        }),
      );
    }
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    if (model.items.length === 0) {
      // this clears the filters when they are removed
      dispatch(
        setLeaseTableFiltersAction({
          propertyId: propertyId,
          assetId: assetId,
          leaseType: leasesType,
          filters: undefined,
        }),
      );
    } else {
      const parameterHasValues = model.items.length > 0 && model.items.every((parameter) => parameter.value);
      if (parameterHasValues) {
        const tenantNameFilter = model.items.filter((i) => i.field.match('tenantName'));
        const startDateFilter = model.items.filter((i) => i.field.match('startDate'));
        const endDateFilter = model.items.filter((i) => i.field.match('endDate'));
        const filters = model.items
          .filter((i) => !i.field.match('tenantName'))
          .filter((i) => !i.field.match('startDate'))
          .filter((i) => !i.field.match('endDate'))
          .map((items) => {
            return {
              name: items.field as keyof ReduxLease,
              operator: items.operator as FilterOperator,
              value: items.value,
            };
          });
        dispatch(
          setLeaseTableFiltersAction({
            propertyId: propertyId,
            assetId: assetId,
            leaseType: leasesType,
            filters: filters,
            tenantName: tenantNameFilter.length > 0 ? tenantNameFilter[0].value : undefined,
            startDateFilter:
              startDateFilter.length > 0
                ? {
                    value: startDateFilter[0].value,
                    operator: convertToQueryOperator(startDateFilter[0].operator as FilterOperator),
                  }
                : undefined,
            endDateFilter:
              endDateFilter.length > 0
                ? {
                    value: endDateFilter[0].value,
                    operator: convertToQueryOperator(endDateFilter[0].operator as FilterOperator),
                  }
                : undefined,
          }),
        );
      }
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handleEditLeaseClose = () => {
    setOpenLeaseQuickAction(false);
  };

  const handleRenewLease = (id: GridRowId) => {
    const row = rows.find((row) => row.id === id);
    row && dispatch(quickEditLeaseAction({ lease: row, editType: 'renewal' }));
  };

  const handleCancelPreLease = (id: GridRowId) => {
    const row = rows.find((row) => row.id === id);
    setEditRow(row);
    dispatch(resetLeaseStatusSubmissionAction());
    setOpenLeaseQuickAction(true);
  };

  const handlePaymentOptions = (paymentId: string, propertyId: string, title: PaymentTitle) => {
    openPaymentDialog(paymentId);
    setPaymentDialogTitle(title);
    dispatch(getGlCodesAction({ propertyId: propertyId }));
  };

  const handleCloseLeaseDialog = (id: string) => {
    openCloseLeaseDialog(id);
  };

  const handleSwitchToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      toggleLeaseIncludeClosedStatusesAction({
        propertyId: propertyId,
        includeClosedStatuses: event.target.checked,
        assetId: assetId,
      }),
    );
  };

  return (
    <Stack spacing={2}>
      {leasesType === 'all' && (
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
          <FormGroup>
            <FormControlLabel
              control={<Switch size="small" checked={includeClosedStatuses} onChange={handleSwitchToggle} />}
              label="Include Closed/Cancelled Leases"
            />
          </FormGroup>
        </Box>
      )}
      <LeasesDataGrid
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        assetId={assetId}
        leasesType={leasesType}
        onRenewLease={handleRenewLease}
        handlePaymentOptions={handlePaymentOptions}
        onCancelPreLease={handleCancelPreLease}
        onCloseLeaseDialog={handleCloseLeaseDialog}
        selectionModel={selectionModel}
        onSelectionChange={handleSelectionChange}
        page={page}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onFilterModelChange={handleFilterModelChange}
        onSortModelChange={handleSortModelChange}
      />
      {paymentDialogTitle && (
        <LeasePaymentDialog
          assetId={assetId}
          leasesType={leasesType}
          title={paymentDialogTitle}
          open={typeof acceptPaymentForLeaseId !== 'undefined'}
          onClose={closePaymentDialog}
          leaseId={acceptPaymentForLeaseId!}
        />
      )}{' '}
      {closeLeaseId && (
        //<CloseLeaseDialog
        //onClose={closeCloseLeaseDialog}
        //leaseId={closeLeaseId}
        //open={typeof closeLeaseId !== 'undefined'}
        //assetId={assetId}
        //leaseType={leasesType}
        ///>
        <CloseLeaseWizard
          onClose={() => {
            setCloseLeaseId(undefined);
          }}
          leaseId={closeLeaseId}
          isOpen={!!closeLeaseId}
          leasesType={leasesType}
          assetId={assetId}
        />
      )}
      {editRow && (
        <CancelPreLeaseDialog
          open={openLeaseQuickAction}
          onClose={handleEditLeaseClose}
          row={editRow}
          leaseType={leasesType}
          assetId={assetId}
        />
      )}
    </Stack>
  );
};
