import React, { FC, useEffect, useState } from 'react';
import {
  ExpenseElementType,
  purchaseOrderStatusMap,
  ReduxChangeOrder,
  ReduxPurchaseOrder,
} from '../../redux/expenseData';
import {
  AccountType,
  AssetType,
  IContactPerson,
  ISlimAccountRef,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { setPurchaseOrderSubmittingValueAction } from '../../redux/expenseSlice';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { VendorAutocomplete } from '../VendorAutocomplete';
import { ExpenseStatusSelect } from '../ExpenseStatusSelect';
import { AccountAutocomplete } from '../../../autocompletes/AccountAutocomplete';
import { NumberValueTextField } from '../../../texfields/NumberValueTextField';
import { VendorContactAutocomplete } from '../VendorContactAutocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import { ItemizedPurchaseOrderItemTable } from '../expenseElementTables/ItemizedPurchaseOrderItemTable';
import { sum } from 'd3';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { toReduxDate, toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { AssociateElementMultipleAutocomplete } from '../AssociateElementMultipleAutocomplete';
import { UserAutocomplete } from '../../../autocompletes/UserAutocomplete';

type PurchaseOrderFormProps = {
  value: ReduxPurchaseOrder;
  onChangePurchaseOrder: <T extends keyof ReduxPurchaseOrder>(field: T, value: ReduxPurchaseOrder[T]) => void;
  onChangeVendor: (value: IVendor | undefined) => void;
  propertyId: string;
  expenseAccount?: ISlimAccountRef;
  onChangeExpenseAccount: (account: ISlimAccountRef) => void;
  editingMode?: boolean;
  onAddNewVendor: (name: string) => void;
  onAssociateQuote: (associations?: string[]) => void;
  onChangeVendorContact?: (value: IContactPerson | undefined) => void;
  onWidenModal?: () => void;
  onPOLineItemAssociation?: (lineItemNumber: string) => void;
  gridApiRef?: React.MutableRefObject<GridApiPremium>;
};

export const PurchaseOrderForm: FC<PurchaseOrderFormProps> = ({
  value,
  onAddNewVendor,
  onChangeVendor,
  propertyId,
  onChangePurchaseOrder,
  onChangeExpenseAccount,
  editingMode,
  expenseAccount,
  onAssociateQuote,
  onChangeVendorContact,
  onWidenModal,
  onPOLineItemAssociation,
  gridApiRef,
}) => {
  const dispatch = useDispatch();
  const handleEditingModeAddNewVendor = (name: string) => {
    dispatch(setPurchaseOrderSubmittingValueAction({ ...value, vendor: { name: name } }));
    onAddNewVendor(name);
  };

  const getTotal = (order: ReduxPurchaseOrder | ReduxChangeOrder) => {
    if (order.isItemized && order.items) {
      const total = Number(
        (order.items.reduce((acc, curr) => acc + (curr.amount ?? 0) * (curr.quantity ?? 1), 0) ?? 0).toFixed(2),
      );
      return total;
    }
    return order.amount ?? 0;
  };

  const [totalValue, setTotalValue] = useState(getTotal(value) ?? 0);

  useEffect(() => {
    setTotalValue(getTotal(value));
  }, [value]);

  const getTotalWithChangeOrders = () => {
    if (!value.changeOrders || value.changeOrders.length === 0) return 0;

    return Number(getTotal(value)) + sum(value.changeOrders.map((x) => getTotal(x)));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={editingMode ? 6 : 12}>
        <TextField
          fullWidth
          required
          label="Name"
          value={value.name}
          variant={'outlined'}
          onChange={(e) => onChangePurchaseOrder('name', e.target.value)}
        />
      </Grid>
      {editingMode && (
        <Grid item xs={editingMode ? 6 : 12}>
          <ExpenseStatusSelect
            value={value.purchaseOrderStatus}
            onChange={(e) => onChangePurchaseOrder('purchaseOrderStatus', e.target.value as any)}
            statusMap={purchaseOrderStatusMap}
            type={ExpenseElementType.PurchaseOrder}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <AssociateElementMultipleAutocomplete onChangeAssociations={onAssociateQuote} value={value.associatedQuotes} />
      </Grid>
      <Grid item xs={editingMode ? 6 : 12}>
        <VendorAutocomplete
          propertyId={propertyId}
          onChange={onChangeVendor}
          renderInput={(params) => <TextField margin="dense" variant="outlined" label="Vendor" {...params} />}
          onAddNewVendor={editingMode ? handleEditingModeAddNewVendor : onAddNewVendor}
          value={value.vendor?.name}
        />
      </Grid>
      {value.vendor && (
        <Grid item xs={editingMode ? 6 : 12}>
          <VendorContactAutocomplete
            vendor={value.vendor}
            onChange={onChangeVendorContact}
            renderInput={(params) => <TextField margin="dense" variant="outlined" label="Vendor Contact" {...params} />}
            value={value.vendorContact ?? null}
          />
        </Grid>
      )}
      <Grid item xs={editingMode ? 6 : 12}>
        <DatePicker
          label={'Start Date'}
          onChange={(date: Date | null) => {
            if (date) onChangePurchaseOrder('startDate', toReduxDate(date));
            else onChangePurchaseOrder('startDate', '');
          }}
          slotProps={{ textField: { fullWidth: true, required: true }, field: { clearable: true } }}
          value={value.startDate ? toStandardDate(value.startDate) : null}
        />
      </Grid>
      {editingMode && (
        <Grid item xs={6}>
          <UserAutocomplete
            value={value.approvedBy}
            assetId={propertyId}
            assetType={AssetType.RentalProperty}
            onChange={(value?: string) => {
              onChangePurchaseOrder('approvedBy', value);
            }}
            renderInput={(params) => <TextField margin="dense" variant="standard" label="Approved By" {...params} />}
          />
        </Grid>
      )}
      <Grid item xs={editingMode ? 6 : 12}>
        <AccountAutocomplete
          value={expenseAccount}
          propertyId={propertyId}
          onChange={onChangeExpenseAccount}
          accountType={AccountType.Expenses}
          renderInput={(params) => <TextField margin="dense" variant="standard" label="Expense Account" {...params} />}
        />
      </Grid>
      <Grid item xs={editingMode ? 6 : 12}>
        <NumberValueTextField
          fullWidth
          required
          allowNegativeValues
          valueUnits={'dollars'}
          label={value.isItemized ? 'Total' : 'Amount'}
          disabled={value.isItemized}
          value={totalValue ?? 0}
          type="number"
          onChange={(e) => onChangePurchaseOrder('amount', e.target.value as any)}
        />
      </Grid>
      {editingMode && value.changeOrders && value.changeOrders.length > 0 ? (
        <Grid item xs={6}>
          <NumberValueTextField
            fullWidth
            required
            valueUnits={'dollars'}
            label={'Total with Change Orders'}
            disabled
            value={getTotalWithChangeOrders()}
            type="number"
          />
        </Grid>
      ) : (
        <></>
      )}
      {!editingMode && (
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={value.isItemized ?? false}
                  onChange={(e) => {
                    onChangePurchaseOrder('isItemized', e.target.checked);
                    onWidenModal && onWidenModal();
                  }}
                />
              }
              label="Itemized"
            />
          </FormGroup>
        </Grid>
      )}
      {value.isItemized && (
        <Box sx={{ pt: '1rem', pl: '1rem', width: '100%' }}>
          <ItemizedPurchaseOrderItemTable
            value={value.items ?? []}
            onSave={(updated) => onChangePurchaseOrder('items', updated)}
            loading={false}
            isEditable
            onPOLineItemAssociation={onPOLineItemAssociation}
            gridApiRef={gridApiRef}
          />
        </Box>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Description"
          value={value.description}
          variant={'outlined'}
          multiline
          rows={3}
          onChange={(e) => onChangePurchaseOrder('description', e.target.value)}
        />
      </Grid>
    </Grid>
  );
};
