import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import Stack from '@mui/material/Stack';
import { MessageThreadView } from './MessageThreadView';
import { SmsMessages } from './SmsMessages';
import {
  listSmsForThreadAction,
  listSmsThreadsAction,
  newSmsArrivedAction,
  sendSmsToTenantAction,
  setSelectedSmsThread,
  useCommunication,
} from '../redux/communicationSlice';
import { AssociationType, ISms } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import Connector from '@monkeyjump-labs/cam-fe-shared/dist/services/signalrConnection';
import { mapReduxSms } from '../redux/communicationTypes';

export type SmsViewProps = {
  associationType?: AssociationType;
  associationId?: string;
};

export const SmsView: FC<SmsViewProps> = ({ associationType, associationId }) => {
  const dispatch = useDispatch();
  const { smsThreads, smsMessages } = useCommunication();
  const [selectedSmsThreadId, setSelectedSmsThreadId] = useState<string>();
  const messagesEndRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const messageBoxRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [currentPageLoaded, setCurrentPageLoaded] = useState(0);
  const previousScrollHeight = useRef(0);
  const { selectedContext } = useAssets();
  const propertyId = selectedContext.propertyId;
  useEffect(() => {
    if (propertyId) {
      if (associationType && associationId)
        dispatch(
          listSmsThreadsAction({
            propertyId,
            associationType: associationType,
            associationId: associationId,
          }),
        );
      else dispatch(listSmsThreadsAction({ propertyId }));
    }
  }, [associationId, associationType, propertyId, selectedSmsThreadId]);

  useEffect(() => {
    messageBoxRef.current.scrollTop += messageBoxRef.current.scrollHeight - previousScrollHeight.current;
  }, [smsMessages]);

  useEffect(() => {
    const connector = Connector.getExistingInstance();
    connector.onReceiveSms((payload: ISms) => {
      dispatch(newSmsArrivedAction({ sms: mapReduxSms(payload) }));
    });

    return function cleanup() {
      connector.offReceiveSms();
    };
  }, []);

  const handleMessageThreadSelection = (phoneNumber: string) => {
    setSelectedSmsThreadId(phoneNumber);

    propertyId &&
      dispatch(
        listSmsForThreadAction({
          propertyId: propertyId,
          phoneNumber: phoneNumber,
          page: 0,
          pageSize: 10,
          appendToExistingSms: false,
        }),
      ) &&
      dispatch(setSelectedSmsThread(phoneNumber));
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setCurrentPageLoaded(0);
    previousScrollHeight.current = 0;
  };

  const SendMessage = (message: string) => {
    if (message === '') return;
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
    propertyId &&
      dispatch(
        sendSmsToTenantAction({
          propertyId: propertyId,
          message: message ?? '',
          phoneNumber: selectedSmsThreadId ?? '',
        }),
      );
  };

  const onLoadMoreMessages = async () => {
    const { scrollTop } = messageBoxRef.current;

    const isScrolledToTop = scrollTop === 0;
    const hasAllMessagesLoaded = smsMessages?.value?.length === smsMessages?.totalCount;
    if (isScrolledToTop && !hasAllMessagesLoaded) {
      propertyId &&
        dispatch(
          listSmsForThreadAction({
            propertyId: propertyId,
            phoneNumber: selectedSmsThreadId ?? '',
            page: currentPageLoaded + 1,
            pageSize: 10,
            appendToExistingSms: true,
          }),
        );
      setCurrentPageLoaded(currentPageLoaded + 1);
      previousScrollHeight.current = messageBoxRef.current.scrollHeight;
    }
  };

  return (
    <StyledInfoBox label={'SMS Messages'}>
      <Stack direction={'row'} sx={{ height: 500 }}>
        <SmsMessages
          setSelectedSmsThread={handleMessageThreadSelection}
          smsThreads={smsThreads?.value ?? []}
          currentSelectedThreadId={selectedSmsThreadId}
        />
        <MessageThreadView
          messagesEndRef={messagesEndRef}
          onSendMessage={SendMessage}
          onLoadMoreMessages={onLoadMoreMessages}
          messageBoxRef={messageBoxRef}
          hasMoreMessages={smsMessages?.value !== undefined && smsMessages.value.length !== smsMessages?.totalCount}
        />
      </Stack>
    </StyledInfoBox>
  );
};
