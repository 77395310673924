import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ReduxEmail, ReduxEmailThread } from '../../redux/communicationTypes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { isEmailThread } from './EmailParticipantsCell';

type IncomingCellProps = GridRenderCellParams<ReduxEmailThread | ReduxEmail>;

export const IncomingCell: FC<IncomingCellProps> = ({ ...params }) => {
  const theme = useTheme();
  const isThread = isEmailThread(params.row);
  const email = isEmailThread(params.row) ? params.row.emails[0] : params.row;
  return (
    <Box sx={isThread ? [styles.incomingCell, styles.isEmailThread] : styles.incomingCell}>
      {email.incoming ? (
        <Tooltip title={'Received'}>
          <ArrowBackIcon sx={{ color: theme.palette.error.main }} />
        </Tooltip>
      ) : (
        <Tooltip title={'Sent'}>
          <ArrowForwardIcon sx={{ color: theme.palette.primary.dark }} />
        </Tooltip>
      )}
    </Box>
  );
};

const styles = {
  incomingCell: {
    height: '100%',
  },
  isEmailThread: {
    display: 'flex',
    alignItems: 'center',
  },
};
