import React, { FC, useEffect, useState } from 'react';
import { ExpandableCard } from '../../../_shared/styledComponents/ExpandableCard';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useTenant } from '../../redux/tenantSlice';
import { tryFormatDate } from '../../../_shared/utils/TryFormatDate';
import { leaseStatusMap, ReduxLease, ReduxLeaseTerm } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import Typography from '@mui/material/Typography';
import { NumericFormat } from 'react-number-format';
import Stack from '@mui/material/Stack';
import { LeaseDetailedStatus } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Skeleton from '@mui/material/Skeleton';

type LeaseCardProps = {
  onOpenLeaseDetails: () => void;
  onOpenMoveOut: () => void;
  selectedLease?: ReduxLease;
  selectedLeaseStatus?: LeaseDetailedStatus;
  focus: boolean;
};

export const LeaseCard: FC<LeaseCardProps> = ({
  onOpenLeaseDetails,
  onOpenMoveOut,
  selectedLease,
  selectedLeaseStatus,
  focus,
}) => {
  const { leases } = useTenant();
  const [monthlyCharges, setMonthlyCharges] = useState<number | undefined>();
  const [selectedLeaseTerm, setSelectedLeaseTerm] = useState<ReduxLeaseTerm | undefined>(
    selectedLease?.leaseTerms && selectedLease?.leaseTerms[0] ? selectedLease?.leaseTerms[0] : undefined,
  );

  useEffect(() => {
    selectedLease?.leaseTerms && selectedLease?.leaseTerms[0] && setSelectedLeaseTerm(selectedLease?.leaseTerms[0]);
  }, [selectedLease]);

  useEffect(() => {
    findSelectedLeaseMonthlyCharges();
  }, [selectedLeaseTerm]);

  const findSelectedLeaseMonthlyCharges = () => {
    let charges: number | undefined = selectedLeaseTerm?.rent?.rentAmount;
    if (charges && selectedLeaseTerm?.rent?.otherFees) {
      selectedLeaseTerm?.rent?.otherFees?.forEach((fee) => (charges! += fee.fee ?? 0));
    }
    setMonthlyCharges(charges);
  };
  if (leases.loading) return <Skeleton variant={'rectangular'} animation={'wave'} width={'100%'} height={250} />;
  return (
    <ExpandableCard
      title={'Lease'}
      subtitle={selectedLeaseStatus ? `Status: ${leaseStatusMap.get(selectedLeaseStatus)}` : ''}
      focus={focus}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        {!selectedLease ? (
          <Typography>No Lease Selected</Typography>
        ) : (
          <Stack spacing={2}>
            <Typography>
              Monthly Rent + Other Fees:{' '}
              <NumericFormat
                value={monthlyCharges?.toString() ?? ''}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
            {leases.selectedValue?.moveOutDate && (
              <Typography>Move-Out Date: {tryFormatDate(leases.selectedValue.moveOutDate)}</Typography>
            )}
          </Stack>
        )}
      </CardContent>
      {selectedLease ? (
        <CardActions sx={{ justifyContent: 'right' }}>
          <Button onClick={onOpenMoveOut} variant={'outlined'}>
            {leases.selectedValue?.moveOutDate ? 'Update Move-Out' : 'Request Move-Out'}
          </Button>
          <Button variant={'contained'} onClick={onOpenLeaseDetails}>
            See Details
          </Button>
        </CardActions>
      ) : (
        <></>
      )}
    </ExpandableCard>
  );
};
