import React, { FC, useCallback, useEffect } from 'react';
import { numericFilters, stringFilters } from '../../../utils/filteringUtils';
import { StripedDataGrid } from '../../../datagrids/StripedDataGrid';
import Box from '@mui/material/Box';
import { TaskListTable } from './TaskListTable';
import {
  AssetType,
  GetGroupedTasksHandlerTasksGroupBy,
  GroupedTaskItem,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { GridRowId } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import {
  getTaskGroupsAction,
  setExpandedRowIdsAction,
  unsetGroupedTaskListAction,
  useTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import {
  DataGridPremiumProps,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  useGridApiRef,
} from '@mui/x-data-grid-premium';

type TaskGroupsTableProps = {
  groupingType: GetGroupedTasksHandlerTasksGroupBy;
  associationType: AssetType;
  assetId: string;
  includeClosed: boolean;
};

export const GroupedTaskTable: FC<TaskGroupsTableProps> = ({
  groupingType,
  associationType,
  assetId,
  includeClosed,
}) => {
  const dispatch = useDispatch();
  const { groupings, groupingInfo } = useTasks();
  const apiRef = useGridApiRef();

  const getDetailPanelContent: DataGridPremiumProps['getDetailPanelContent'] = useCallback(
    ({ row }: GridRowParams<GroupedTaskItem>) => (
      <TaskListTable
        parentRowId={row.itemId!}
        associationType={row.associationType ?? associationType}
        groupingType={groupingType}
        propertyId={assetId}
        includeClosed={includeClosed}
      />
    ),
    [groupingType, associationType, assetId, includeClosed],
  );

  const getDetailPanelHeight = useCallback<NonNullable<DataGridPremiumProps['getDetailPanelHeight']>>(
    () => 'auto' as const,
    [],
  );

  useEffect(() => {
    groupingType !== GetGroupedTasksHandlerTasksGroupBy.Asset &&
      dispatch(
        getTaskGroupsAction({
          assetType: associationType,
          assetId: assetId,
          groupBy: groupingType,
          includeClosed: includeClosed,
        }),
      );
    //TODO: get to bottom of this. current error says for example:  Cannot read properties of undefined (reading '0182f538-acb9-47f8-b9f9-f3d83d5ca113')
    if (groupingInfo.expandedRowIds) {
      // console.log(groupingInfo.expandedRowIds); //this is as expected
      // const id = groupingInfo?.expandedRowIds[0];
      // const row = groupings.value?.find((x) => x.itemId === id); //this is as expected
      // console.log(row);
      // groupingInfo.expandedRowIds.map((id) => apiRef.current.toggleDetailPanel(id));
    }
  }, [groupingType]);

  const handleExpandedRowIdsChange = (ids: GridRowId[]) => {
    if (ids.length === 0) dispatch(setExpandedRowIdsAction([]));
    else if (ids.length > 0 && ids.length < groupingInfo.expandedRowIds.length) {
      const unsetId = groupingInfo.expandedRowIds.find((id) => !ids.includes(id));
      unsetId && dispatch(unsetGroupedTaskListAction(unsetId));
    } else dispatch(setExpandedRowIdsAction(ids.map((id) => id.toString())));
  };

  const columns: GridColDef[] = [
    {
      field: 'itemName',
      type: 'string',
      renderHeader: () =>
        groupingType === GetGroupedTasksHandlerTasksGroupBy.Asset ? (
          <>Asset</>
        ) : groupingType === GetGroupedTasksHandlerTasksGroupBy.Category ? (
          <>Category</>
        ) : (
          <>Assignee</>
        ),
      flex: 1.25,
      filterOperators: stringFilters,
      renderCell: (params: GridRenderCellParams) => {
        return <strong>{params.value}</strong>;
      },
    },
    {
      field: 'totalCount',
      type: 'number',
      headerName: 'Count',
      flex: 1.25,
      filterOperators: numericFilters,
      renderHeader: () => {
        if (includeClosed && groupingType !== GetGroupedTasksHandlerTasksGroupBy.Asset) {
          return <>Total Closed Tasks</>;
        } else if (includeClosed) {
          return <>Total Tasks</>;
        } else return <>Total Open Tasks</>;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (includeClosed && groupingType !== GetGroupedTasksHandlerTasksGroupBy.Asset) {
          return (
            <>
              {params.row.totalCount - params.row.openCount} / {params.row.totalCount}
            </>
          );
        } else if (includeClosed) {
          return <>{params.row.totalCount}</>;
        } else return <>{params.row.openCount}</>;
      },
    },
  ];

  return (
    <Box
      sx={{
        width: 1,
        '& .super-app-theme--inactive': {
          color: 'lightgray',
        },
      }}
    >
      <StripedDataGrid
        disableRowGrouping
        apiRef={apiRef}
        sx={{ minHeight: 500 }}
        autoHeight
        rows={groupings.value ?? []}
        loading={groupings.loading}
        columns={columns}
        getDetailPanelContent={getDetailPanelContent}
        getRowId={(row) => row.itemId}
        getRowClassName={(params) => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
        }}
        getDetailPanelHeight={getDetailPanelHeight}
        onDetailPanelExpandedRowIdsChange={handleExpandedRowIdsChange}
        hideFooter
      ></StripedDataGrid>
    </Box>
  );
};
